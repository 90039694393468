import { useContext, useMemo } from 'react'
import {
  routesCompanyManager,
  routesDefault,
  routesHeadquarterManager,
  routesMaster,
  routesRegionManager,
  routesRegionMarketing,
  routesRegionOperational,
  routesSupplier,
  routesTIAssai,
} from '../main/routes-role'
import { MeContext } from '../contexts/me-context/me-context'

export const useRouteRole = () => {
  const { role } = useContext(MeContext)

  const routes = useMemo(() => {
    if (role?.role === 'MASTER') {
      return routesMaster()
    } else if (role?.role === 'MANAGER_HEADQUARTER') {
      return routesHeadquarterManager()
    } else if (role?.role === 'TI_ASSAI') {
      return routesTIAssai()
    } else if (role?.role === 'MANAGER_COMPANY') {
      return routesCompanyManager()
    } else if (role?.role === 'MANAGER_SUPPLIER') {
      return routesSupplier()
    } else if (role?.role === 'MANAGER_REGION') {
      return routesRegionManager()
    } else if (role?.role === 'MARKETING_REGION') {
      return routesRegionMarketing()
    } else if (role?.role === 'OPERATIONAL_REGION') {
      return routesRegionOperational()
    } else {
      return routesDefault()
    }
  }, [role])

  return { routes }
}
