import React, { useEffect } from 'react'
import { PageIndexContainer } from '../../../containers/pages/page-index-container/page-index-container'
import { useGetAllApi } from 'rk-react-component'
import { TableMedia } from '../../../containers/tables/table-media/table-media'
import { FormFilterMedia } from '../../../containers/form-filter/form-filter-media/form-filter-media'
import { api } from '../../../services/api'

export const MediaIndexPage = () => {
  const { dataList, loading, fetchDataList, pagination } = useGetAllApi<any, any>({
    url: '/medias',
    api,
    initialRequest: false,
  })

  useEffect(() => {
    fetchDataList({ per_page: 10, page: 1 })
  }, [])

  return (
    <PageIndexContainer
      url={'/medias'}
      title={'Mídias'}
      btnTitle={'Nova Mídia'}
      FormFilterComponent={() => <FormFilterMedia onFinish={(data) => fetchDataList(data)} />}
      TableComponent={() => (
        <TableMedia dataSource={dataList} loading={loading} loadData={fetchDataList} pagination={pagination} />
      )}
    />
  )
}
