import React from 'react'
import { useParams } from 'react-router-dom'
import { PageFormContainer } from '../../../containers/pages/page-form-container/page-form-container'
import { GetOneApiReturn, useGetOneApi } from 'rk-react-component'
import { api } from '../../../services/api'
import { FormSupplier } from '../../../containers/forms/form-supplier/form-supplier'
import { SupplierModel } from '../../../@types/supplier'

export const ManagerSupplierFormPage = () => {
  const { id } = useParams()
  const { data, loading }: GetOneApiReturn<SupplierModel> = useGetOneApi({
    url: '/suppliers',
    id,
    api,
  })
  return (
    <PageFormContainer
      title={'Fornecedor'}
      FormComponent={() => <FormSupplier initialValues={data} onFinish={() => {}} />}
      loading={loading}
    />
  )
}
