import React, { useContext, useEffect, useState } from 'react'
import type { ChatMessageModel, ChatRoomModel, UseChatHookReturn } from 'rk-react-component'
import { useGetAllApi } from 'rk-react-component'
import { type AxiosInstance } from 'axios'
import moment from 'moment'
import { MeContext } from '../../../contexts/me-context/me-context'

interface UseChatHookProps {
  api: AxiosInstance
  socket?: any
  userId: number
}

export const useChatHook = ({ api, socket, userId }: UseChatHookProps): UseChatHookReturn => {
  const { setUnreadMessage } = useContext(MeContext)
  const [selectedChatRoom, setSelectedChatRoom] = useState<ChatRoomModel | undefined>()
  const [loadingSendMessage, setLoadingMessage] = useState(false)
  const [page, setPage] = React.useState(1)
  const [pageMessages, setPageMessages] = React.useState(1)

  const {
    setDataList: setChatRoom,
    dataList: chatRooms,
    fetchDataList: fetchChatRooms,
    loading: loadingChatRooms,
  } = useGetAllApi<ChatRoomModel, ChatRoomModel>({ api, url: '/chat-rooms', initialRequest: false })

  const {
    setDataList: setRoomMessages,
    dataList: chatRoomMessages,
    fetchDataList: fetchChatRoomMessages,
    loading: loadingChatRoomMessages,
    pagination: paginationMessages,
  } = useGetAllApi<ChatMessageModel, ChatMessageModel>({ api, url: '/chat-rooms-messages', initialRequest: false })

  useEffect(() => {
    fetchChatRooms({})
  }, [])

  const fetchChatRoomsNextPage = (): void => {
    api.get('/chat-rooms', { params: { page: page + 1 } }).then(({ data }) => {
      setChatRoom((old: ChatRoomModel[]) => [...old, ...data.data])
      setPage(page + 1)
    })
  }

  const clearChatRoomUnreadMessages = (id: number): void => {
    setChatRoom((old) => old.map((room) => (room.id === id ? { ...room, unreadMessages: 0 } : room)))
  }

  const onSelectChatRoom = (data: ChatRoomModel): void => {
    setPageMessages(1)
    setSelectedChatRoom(data)
    fetchChatRoomMessages({ chat_room_id: data.id })
    clearChatRoomUnreadMessages(data.id)
    setUnreadMessage((prev: number) => prev - +(data?.unreadMessages || 0))
  }

  const appendSelectedChatRoomMessages = (message: ChatMessageModel): void => {
    setRoomMessages((old: ChatMessageModel[]) => [...old, message])
  }

  const fetchChatRoomMessagesNextPage = (): void => {
    api
      .get('/chat-rooms-messages', {
        params: {
          chat_room_id: selectedChatRoom?.id,
          page: pageMessages + 1,
          per_page: 10,
        },
      })
      .then(({ data }) => {
        setRoomMessages((old: ChatMessageModel[]) => [...data.data, ...old])
        setPageMessages(pageMessages + 1)
      })
  }
  const sendMessage = (message: string): void => {
    const now = moment().toISOString()
    setLoadingMessage(true)
    api
      .post('/chat-rooms-messages', { chat_room_id: selectedChatRoom?.id, message })
      .then(({ data }) => {
        console.log(data)
        setChatRoom((old: ChatRoomModel[]) =>
          old.map((room) =>
            room.id === selectedChatRoom?.id
              ? {
                  ...room,
                  lastMessage: data.data,
                  lastMessageAt: now,
                }
              : room,
          ),
        )
        appendSelectedChatRoomMessages(data.data)
      })
      .catch(() => {})
      .finally(() => {
        setLoadingMessage(false)
      })
  }
  useEffect(() => {
    if (socket) {
      socket.on(`Chat-Participant:${userId}`, ({ chatRoom }: any) => {
        console.log(chatRoom)
        setChatRoom((old: ChatRoomModel[]) => old.map((room) => (room.id === chatRoom?.id ? chatRoom : room)))
        if (selectedChatRoom?.id === chatRoom.id) {
          appendSelectedChatRoomMessages(chatRoom?.lastMessage as ChatMessageModel)
        }
      })
    }
    return () => {
      if (socket) {
        socket.off(`Chat-Participant:${userId}`)
      }
    }
  }, [userId, selectedChatRoom])
  return {
    fetchChatRooms,
    fetchChatRoomsNextPage,
    chatRooms,
    clearChatRoomUnreadMessages,
    selectedChatRoom,
    onSelectChatRoom,
    fetchChatRoomMessages,
    fetchChatRoomMessagesNextPage,
    selectedChatRoomMessages: [] as ChatMessageModel[],
    appendSelectedChatRoomMessages,
    chatRoomMessages,
    sendMessage,
    loadingChatRooms,
    loadingChatRoomMessages,
    loadingSendMessage,
  }
}
