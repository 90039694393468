import React from 'react'

interface AppContextProps {
    socket: any,
    setSocket: (data: any) => void
}

export const AppContext = React.createContext<AppContextProps>({
    socket: null,
    setSocket: () => null
})
