import React from 'react'
import './style.css'
import { TableContainer, TableContainerProps } from '../table-container/table-container'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'antd'
import _ from 'lodash'

export const TableRoute = ({ dataSource, loading, loadData, pagination, onDelete }: TableContainerProps<any, any>) => {
  const navigate = useNavigate()
  return (
    <TableContainer
      onDelete={onDelete}
      loadData={loadData}
      pagination={pagination}
      id={'table_route'}
      loading={loading}
      onEdit={(record) => navigate(`/routes/${record?.id}`)}
      columns={[
        { dataIndex: 'name', key: 'name', title: 'Nome' },
        {
          dataIndex: 'description',
          key: 'description',
          title: 'Descrição',
          render: (val) => {
            return (
              <Tooltip title={val}>
                {_.truncate(val, {
                  omission: ' [...]',
                  separator: ' ',
                  length: 150,
                })}
              </Tooltip>
            )
          },
        },
      ]}
      dataSource={dataSource}
    />
  )
}
