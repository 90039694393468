import React, { ReactNode } from 'react'
import { FormFilterContainer, FormFilterItem, InputPhone } from 'rk-react-component'
import { Form, Input } from 'antd'
import { formatFormFilterData } from '../../../services/format'

interface FormFilterPartyProps {
  onFinish: (
    data: Partial<
      any & {
        page: number
        per_page: number
      }
    >,
  ) => void
  initialValues?: Partial<any>
}

export const FormFilterRole = ({ onFinish, initialValues }: FormFilterPartyProps) => {
  const [form] = Form.useForm()
  return (
    <FormFilterContainer
      formProps={{
        initialValues: initialValues,
        onFinish: (data) => {
          onFinish(formatFormFilterData({ ...data, page: 1, per_page: 10 }))
        },
      }}
      form={form}
      onReset={() => onFinish({})}
      hiddenShowMore={true}
    >
      {() =>
        (
          <>
            <FormFilterItem name={['user', 'name_lk']}>
              <Input placeholder={'Nome'} data-testid={'user_name_lk'} />
            </FormFilterItem>
            <FormFilterItem name={['user', 'email_lk']}>
              <Input placeholder={'Email'} data-testid={'user_email_lk'} />
            </FormFilterItem>
            <FormFilterItem name={['user', 'mobile_lk']}>
              <InputPhone placeholder={'Telefone'} data-testid={'user_mobile_lk'} />
            </FormFilterItem>
          </>
        ) as ReactNode
      }
    </FormFilterContainer>
  )
}
