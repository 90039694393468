import React from 'react'
import ReactDOM from 'react-dom/client'
import './main/index.css'
import App from './main/App'
import reportWebVitals from './main/reportWebVitals'
import {ConfigProvider} from 'antd'
import ptBR from 'antd/locale/pt_BR'
import {UserbackProvider} from '@userback/react'
import {configProject} from './config-project'
import 'moment-timezone'
import * as Sentry from "@sentry/react";


Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: +(process.env.REACT_APP_SENTRY_TRACE || 0),
});

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <Sentry.ErrorBoundary fallback={null} showDialog>
        <UserbackProvider token={process.env.REACT_APP_USER_BACK}>
            <ConfigProvider
                locale={ptBR}
                theme={{
                    token: {
                        colorPrimary: configProject.colorPrimary,
                        colorLink: configProject.colorLink,
                    },
                }}
            >
                <App/>
            </ConfigProvider>
        </UserbackProvider>
    </Sentry.ErrorBoundary>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
