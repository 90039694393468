import { Dropdown, Space, Spin, Typography } from 'antd'
import { AiOutlineDown } from 'react-icons/ai'
import React, { useContext } from 'react'
import { MeContext } from '../../contexts/me-context/me-context'
import { useNavigate } from 'react-router-dom'
import { RoleModel } from '../../@types/role'
import { formatRole } from '../../services/format'
import { useGetAllApi } from 'rk-react-component'
import { api } from '../../services/api'

export const DropdownRole = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const { role, setRole, me } = useContext(MeContext)
  const { dataList } = useGetAllApi<any, any>({
    url: '/roles',
    api: api,
    fixParams: { user_id: me?.id, active: true },
  })

  const itemRole = () => {
    const itemAux = []
    dataList.map((i, index) => {
      itemAux.push({
        key: i.id,
        label: (
          <RenderItem
            role={i}
            onClick={() => {
              setLoading(true)
              api
                .put(`/users/${me?.id}`, { active_role_id: i?.id })
                .then(() => {
                  localStorage.setItem('active_role', JSON.stringify(i))
                  setRole(i)
                  navigate('/')
                })
                .finally(() => {
                  setLoading(false)
                })
            }}
          />
        ),
      })
      if (index < dataList.length - 1) {
        itemAux.push({
          type: 'divider',
        })
      }
    })
    return itemAux
  }

  return (
    <Spin spinning={loading}>
      <Dropdown arrow={true} trigger={['click']} menu={{ items: itemRole() }}>
        <Space style={{ cursor: 'pointer' }}>
          <Typography.Text style={{ color: 'white', fontSize: 12 }}>{formatRole?.[role?.role] || ''}</Typography.Text>
          <AiOutlineDown size={12} color={'white'} />
        </Space>
      </Dropdown>
    </Spin>
  )
}

const RenderItem = ({ role, onClick }: { role: RoleModel; onClick: (role: RoleModel) => void }) => {
  return (
    <Space direction={'vertical'} onClick={() => onClick(role)}>
      <Space>
        <Typography.Title
          level={5}
          style={{
            marginRight: 8,
            marginLeft: 4,
            marginTop: 0,
          }}
        >
          {formatRole[role?.role] || '-'}
        </Typography.Title>
      </Space>
      {role?.allow_model ? (
        <Typography.Text
          style={{
            marginRight: 8,
            marginLeft: 4,
            marginTop: 0,
          }}
        >
          {role?.allow_model?.name}
        </Typography.Text>
      ) : null}
    </Space>
  )
}
