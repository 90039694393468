import { OverlayView } from 'react-google-maps'
import { GoogleMapsItems, GoogleMapsMarkerComposedPoint } from 'rk-react-component'
import React from 'react'

export const DashboardMap = ({ defaultCoords, drivers, setDefaultCoords }: any) => {
  return (
    <GoogleMapsItems
      mapsProps={{
        onCenterChanged: () => {
          setDefaultCoords({
            lat: undefined,
            lng: undefined,
          })
        },
        center: {
          lat: +defaultCoords.lat,
          lng: +defaultCoords.lng,
        },
        defaultZoom: 11,
      }}
      items={drivers.map((d) => (
        <>
          <OverlayView
            position={{ lat: d?.car_track?.latitude, lng: d?.car_track?.longitude }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={(width, height) => ({
              x: -(width / 2),
              y: -(height * 1.5),
            })}
          >
            <div
              style={{
                background: `white`,
                border: `1px solid #ccc`,
                padding: '0px 8px 0px 8px',
                borderRadius: 8,
              }}
            >
              <h2>{d?.driver?.user?.name || 'Nome do motorista'}</h2>
            </div>
          </OverlayView>
          <GoogleMapsMarkerComposedPoint
            lat={d?.car_track?.latitude}
            lng={d?.car_track?.longitude}
            front={{
              url: `${process.env.REACT_APP_API_URL}/static/media/icon-bordered.759cc95e8bb63616ba36.png`,
              xOffset: 9,
              yOffset: 33,
              width: 18,
              height: 18,
            }}
          />
        </>
      ))}
    />
  )
}
