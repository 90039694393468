import React from 'react'
import { GoogleMapsItems, GoogleMapsMarkerComposedPoint } from 'rk-react-component'
import { useNavigate, useParams } from 'react-router-dom'
import { PageHeader } from '../../../components/page-header/page-header'
import { Button, Card, Divider } from 'antd'

export const DriverShowPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  // const {data, loading} = useGetOneApi({
  //   url: "/drivers",
  //   id,
  //   api,
  // });
  // const title = 'Motorista' + (data?.user?.name ? ` - (${data?.user?.name})` : '')
  const title = 'Motorista'

  return (
    <>
      <PageHeader
        title={title}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            id={'btn_new_model'}
            key={'btn_new_model'}
            type={'primary'}
            onClick={() => navigate(`/drivers/${id}`)}
          >
            Editar
          </Button>,
        ]}
      />
      <Divider />
      <Card title={'Última Localização'}>
        {/*/!* eslint-disable-next-line @typescript-eslint/ban-ts-comment *!/*/}
        {/*/!* @ts-ignore *!/*/}
        <GoogleMapsItems
          items={[
            <GoogleMapsMarkerComposedPoint
              front={{
                height: 24,
                url: 'https://rockapps.com.br/wp-content/uploads/2020/06/cropped-rockapps-cube-32x32.png',
                width: 24,
                xOffset: 11,
                yOffset: 36,
              }}
              lat={-22.4850387}
              lng={-44.0620898}
            />,
          ]}
          mapsProps={{
            defaultCenter: {
              lat: -22.4850387,
              lng: -44.0620898,
            },
            defaultZoom: 13,
          }}
        />
      </Card>
    </>
  )
}
