import React from 'react'

interface ChatContextProps {
    chatRooms: any[],
    setChatRooms: (data: any) => void
    chatSelected: any,
    setChatSelected: (data: any) => void
}

export const ChatContext = React.createContext<ChatContextProps>({
    chatRooms: [],
    setChatRooms: (data) => {

    },
    chatSelected: {},
    setChatSelected: (data) => {

    }
})
