import React, { memo } from 'react'
import { api } from '../../services/api'
import { Spin, Typography } from 'antd'
import { AddIcon, EditIcon } from '../../main/icon-modules'
import { SelectAction } from 'rk-react-component'

export const MasterSelectSupplier = memo(
  function ({ supplier, executionId, updateRecord }: any) {
    const [loading, setLoading] = React.useState(false)
    const [value, setValue] = React.useState(supplier?.id)
    const [showSelect, setShowSelect] = React.useState(false)
    if (loading) {
      return (
        <div style={{ display: 'flex', minWidth: 150, justifyContent: 'center', alignItems: 'center' }}>
          <Spin spinning={true} size={'small'} />
        </div>
      )
    }
    if (!showSelect) {
      return value ? (
        <Typography.Paragraph
          style={{ marginBottom: 0, minWidth: 150, cursor: 'pointer' }}
          strong
          onClick={() => setShowSelect(true)}
        >
          {supplier?.name} <EditIcon size={12} style={{ marginLeft: 12 }} />{' '}
        </Typography.Paragraph>
      ) : (
        <Typography.Paragraph
          style={{ marginBottom: 0, minWidth: 150, color: 'red', cursor: 'pointer' }}
          strong
          onClick={() => setShowSelect(true)}
        >
          Sem Fornecedor <AddIcon size={12} style={{ marginLeft: 12 }} />{' '}
        </Typography.Paragraph>
      )
    }

    return (
      <div>
        <SelectAction
          api={api}
          data-testid={'supplier_id'}
          loading={loading}
          value={supplier?.id}
          defaultOptions={supplier ? [supplier] : []}
          onChange={(val) => {
            setLoading(true)
            setValue(val)
            api
              .put('/executions-list/' + executionId, { supplier_id: val })
              .then(({ data }) => {
                updateRecord(data?.data)
              })
              .finally(() => {
                setLoading(false)
              })
          }}
          style={{ width: 150 }}
          placeholder={'Selecione um Fornecedor'}
          url={'/suppliers'}
        />
      </div>
    )
  },
  (prev, next) => prev.supplier?.id === next.supplier?.id && prev.executionId === next.executionId,
)
