import React from 'react'
import './style.css'
import {TableContainer} from '../table-container/table-container'
import {Link} from 'react-router-dom'
import {MediaModel} from '../../../@types/media'
import {Image} from 'antd'

interface TableMediaProps {
    dataSource: MediaModel[]
    loading: boolean
    loadData: (params: any) => void
    pagination?: any
}

export const TableMedia = ({dataSource, loading, loadData, pagination}: TableMediaProps) => {
    return (
        <TableContainer
            loadData={loadData}
            pagination={pagination}
            id={'table_media'}
            loading={loading}
            columns={[
                {
                    dataIndex: 'id',
                    key: 'id',
                    title: 'Cód.',
                    render: (val) => <Link to={`/medias/${val}`}>{val}</Link>,
                },
                {
                    dataIndex: 'url',
                    key: 'url',
                    title: 'Mídia',
                    render: (val) => <Image src={val} height={40}/>,
                },
                {dataIndex: 'name', key: 'name', title: 'Nome'},
            ]}
            dataSource={dataSource}
        />
    )
}
