import React from 'react'
import { Button, Modal, Space } from 'antd'
import { useGetAllApi } from 'rk-react-component'
import { api } from '../../../services/api'
import { TableDriver } from '../../tables/table-driver/table-driver'
import { FormDriver } from '../../forms/form-driver/form-driver'

interface ModalDriverProps {
  fixParams?: any
  supplierId: number
}

const modalInitialState = { visible: false, data: null }

export const ModalAddDriver = ({ fixParams, supplierId }: ModalDriverProps) => {
  const [modal, setModal] = React.useState(modalInitialState)
  const { remove, dataList, fetchDataList, loading, setDataList } = useGetAllApi<any, any>({
    url: '/drivers',
    fixParams: { ...fixParams, supplier_id: supplierId },
    api,
  })
  return (
    <>
      <Space style={{ width: '100%' }} direction={'vertical'}>
        <Button data-testid={'btn_new_model'} type={'primary'} onClick={() => setModal({ visible: true, data: null })}>
          Vincular Motorista
        </Button>
        <TableDriver loadData={fetchDataList} onDelete={remove} loading={loading} dataSource={dataList} />
      </Space>
      <Modal
        open={modal.visible}
        onCancel={() => setModal(modalInitialState)}
        footer={false}
        closeIcon={false}
        destroyOnClose={true}
      >
        <FormDriver
          initialValues={modal.data}
          fixParams={fixParams}
          onFinish={(data) => {
            setModal(modalInitialState)
            setDataList((old) => [...old, data])
          }}
          onBack={() => setModal(modalInitialState)}
        />
      </Modal>
    </>
  )
}
