export const configProject = {
  colorPrimary: 'rgb(245,125,1)',
  colorLink: 'rgb(245,125,1)',
  colorMenuBackground: 'rgb(49,49,49)',
  colorBgMenuItemCollapsedElevated: 'rgb(49,49,49)',
  colorTextMenu: 'rgb(255,255,255)',
  colorBgCollapsedButton: 'rgb(255,255,255)',
  colorTextMenuTitle: 'rgb(0,0,0)',
  colorTextMenuSelected: 'rgb(245,125,1)',
  colorBgHeader: 'rgb(175,94,0)',
  colorMenuBackgroundSelected: 'rgba(245,125,1,1)',
}
