import { InputPasswordStrength as InputPasswordS } from 'rk-react-component'
import React from 'react'

export const InputPasswordStrength = ({ ...props }) => {
  return (
    <InputPasswordS
      onPasswordValidation={() => {}}
      showHints
      showStrengthBar
      validations={{
        includeNumber: true,
        includeSymbol: true,
        lowercase: true,
        minLength: 10,
        uppercase: true,
      }}
      formItemProps={{ name: 'password' }}
      inputProps={{ placeholder: 'Senha' }}
      {...props}
    />
  )
}
