import { DriverModel } from '../../@types/driver'

export const mockDataDriver: DriverModel[] = [
  {
    id: 1,
    document_cpf: '12345678901',
    document_rg: '123456789',
    user_id: 1,
    placa: 'ABC1234',
  },
  {
    id: 2,
    document_cpf: '12345678901',
    document_rg: '123456789',
    user_id: 2,
    placa: 'ABC1234',
  },
  {
    id: 3,
    document_cpf: '12345678901',
    document_rg: '123456789',
    user_id: 3,
    placa: 'ABC1234',
  },
]
