import React from 'react'
import { Flex } from 'antd'
import { FaRegThumbsDown, FaRegThumbsUp } from 'react-icons/fa'

export const columnBoolean = ({ field, title }) => {
  return {
    dataIndex: field,
    key: field,
    title: title,
    render: (val) => (
      <Flex gap={8} justify={'center'} align={'center'}>
        {val ? 'Sim' : 'Não'}
        {val ? <FaRegThumbsUp color={'green'} /> : <FaRegThumbsDown color={'red'} />}
      </Flex>
    ),
  }
}
