import { Card, Divider } from 'antd'
import React from 'react'
import { PageHeader } from '../../../components/page-header/page-header'
import { useNavigate } from 'react-router-dom'

interface PageFormContainerProps {
  loading: boolean
  FormComponent: any
  title: string
    onBack?: () => void
}

export const PageFormContainer = ({ loading, FormComponent, title, onBack }: PageFormContainerProps) => {
  const navigate = useNavigate()
  return (
    <>
      <PageHeader title={title} onBack={() => {
          if(typeof onBack === 'function') {
              onBack()
          }else {
            navigate(-1)
          }
      }} />
      <Divider />
      <Card loading={loading} size={'small'}>
        <FormComponent />
      </Card>
    </>
  )
}
