import React from 'react'
import { Form, Input } from 'antd'
import { FormFilterContainer, FormFilterItem, InputPhone } from 'rk-react-component'

interface FormFilterPartyProps {
  onFinish: (data: Partial<any> & { page: number; per_page: number }) => void
  initialValues?: any
}

export const FormFilterUser = ({ onFinish, initialValues }: FormFilterPartyProps) => {
  const [form] = Form.useForm()
  return (
    <FormFilterContainer
      formProps={{
        onFinish: (data) => {
          onFinish({ ...data, page: 1, per_page: 10 })
        },
        initialValues: initialValues,
      }}
      form={form}
      hiddenShowMore={true}
    >
      {() => (
        <>
          <FormFilterItem name={'name_lk'}>
            <Input placeholder={'Nome'} data-testid={'name_lk'} />
          </FormFilterItem>
          <FormFilterItem name={'email_lk'}>
            <Input placeholder={'Email'} data-testid={'email_lk'} />
          </FormFilterItem>
          <FormFilterItem name={'mobile_lk'}>
            <InputPhone placeholder={'Celular'} data-testid={'mobile_lk'} />
          </FormFilterItem>
        </>
      )}
    </FormFilterContainer>
  )
}
