import { api } from '../../../services/api'
import React, { useEffect } from 'react'
import { useGetAllApi } from 'rk-react-component'
import { TableUserDevices } from '../../tables/table-user-devices/table-user-devices'

interface TabUserDevicesProps {
  userId: number
}

export const TabUserDevices = ({ userId }: TabUserDevicesProps) => {
  const { dataList, fetchDataList, loading, setDataList, pagination } = useGetAllApi<any, any>({
    url: `/users/${userId}/user-devices`,
    initialRequest: false,
    api,
  })

  useEffect(() => {
    if (userId) {
      fetchDataList({ page: 1, per_page: 10 })
    }
  }, [userId])
  return (
    <TableUserDevices
      dataSource={dataList}
      pagination={pagination}
      id={'ss'}
      loading={loading}
      loadData={fetchDataList}
    />
  )
}
