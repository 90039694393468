import {
  FaBuilding,
  FaCity,
  FaEdit,
  FaEye,
  FaFlag,
  FaInfo,
  FaPlus,
  FaTrash,
  FaTruck,
  FaUser,
  FaUsers
} from "react-icons/fa";
import {PiWarning} from "react-icons/pi";
import {FaMessage} from "react-icons/fa6";
import {BiNotification} from "react-icons/bi";
import {RiAdminLine} from "react-icons/ri";

// MODULES
export const HeadquarterIcon = FaCity
export const RegionIcon = FaFlag
export const CompanyIcon = FaBuilding
export const ProfileIcon = FaUser
export const SupplierIcon = FaTruck
export const ChatIcon = FaMessage

export const AdminIcon = RiAdminLine

export const UserIcon = FaUsers

// OTHERS
export const EditIcon = FaEdit
export const DeleteIcon = FaTrash
export const InfoIcon = FaInfo
export const ShowIcon = FaEye
export const AddIcon = FaPlus
export const WarningIcon = PiWarning
export const NotificationIcon = BiNotification

