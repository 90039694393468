import React, { useEffect } from 'react'
import { useGetAllApi } from 'rk-react-component'
import { PageIndexContainer } from '../../../containers/pages/page-index-container/page-index-container'
import { api } from '../../../services/api'
import { useNavigate } from 'react-router-dom'
import { TableUserTi } from '../../../containers/tables/table-user-ti/table-user-ti'
import { FormFilterUserTi } from '../../../containers/form-filter/form-filter-user-ti/form-filter-user-ti'

export const UserTiIndexPage = () => {
  const navigate = useNavigate()
  const { dataList, loading, fetchDataList, pagination } = useGetAllApi<any, any>({
    url: '/users-ti',
    api,
    initialRequest: false,
  })
  useEffect(() => {
    fetchDataList({ page: 1, per_page: 10 })
  }, [])
  return (
    <PageIndexContainer
      title={'Usuários'}
      btnTitle={''}
      url={'/users'}
      FormFilterComponent={<FormFilterUserTi onFinish={fetchDataList} />}
      TableComponent={() => (
        <TableUserTi
          onEdit={(record) => navigate('/users-ti/' + record.id)}
          loadData={fetchDataList}
          pagination={pagination}
          dataSource={dataList}
          loading={loading}
        />
      )}
    />
  )
}
