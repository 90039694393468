import React from 'react'
import { useParams } from 'react-router-dom'
import { PageFormContainer } from '../../../containers/pages/page-form-container/page-form-container'
import { GetOneApiReturn, useGetOneApi } from 'rk-react-component'
import { api } from '../../../services/api'
import {FormRegion} from "../../../containers/forms/form-region/form-region";
import {RegionModel} from "../../../@types/region";

export const ManagerRegionFormPage = () => {
  const { id } = useParams()
  const { data, loading }: GetOneApiReturn<RegionModel> = useGetOneApi({
    url: '/regions',
    id,
    api,
  })
  return (
    <PageFormContainer
      title={'Gerente Regional'}
      FormComponent={() => (
        <FormRegion
          initialValues={data}
          onFinish={() => {

          }}
        />
      )}
      loading={loading}
    />
  )
}
