import React from 'react'
import { Button, Row, Space, Typography } from 'antd'
import { FormContainer, FormItem } from 'rk-react-component'
import { api } from '../../../services/api'
import { DatePickerMoment } from '../../../components/date-picke-moment/date-picker-moment'
import { SelectCompany } from '../../inputs/select-company/select-company'

export const TabExecutionReportRegion = ({ onFinish }) => {
  const [loading, setLoading] = React.useState(false)
  return (
    <Space direction={'vertical'} style={{ width: '100%' }}>
      <Typography.Title level={4}>Relatório de Operação</Typography.Title>
      <FormContainer
        loading={loading}
        hiddenActions={true}
        onFinish={(data) => {
          setLoading(true)
          api
            .put('/execution-full-report', data, { responseType: 'blob' })
            .then(({ data }) => onFinish(data))
            .catch(() => {})
            .finally(() => setLoading(false))
        }}
      >
        <FormItem
          label={'Período'}
          name={'period'}
          rules={[{ required: true, message: 'É necessário informar o Período' }]}
        >
          <DatePickerMoment.MonthPicker format={'MMM/YY'} />
        </FormItem>
        <FormItem label={'Loja'} name={'company_id'}>
          <SelectCompany allowClear={true} />
        </FormItem>
        <Row>
          <Button type={'primary'} htmlType={'submit'} loading={loading}>
            Gerar
          </Button>
        </Row>
      </FormContainer>
    </Space>
  )
}
