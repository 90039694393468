import { Space, Spin } from 'antd'
import { api } from '../../../services/api'
import React, { useEffect } from 'react'
import { FormAddressGoogleMap, useGetAllApi } from 'rk-react-component'

interface TabAddressProps {
  modelType: string
  modelId: number
}

export const TabAddress = ({ modelType, modelId }: TabAddressProps) => {
  const [isMounted, setMounted] = React.useState(false)
  const { dataList, fetchDataList, loading, setDataList } = useGetAllApi<any, any>({
    url: '/addresses',
    initialRequest: false,
    fixParams: { model_id: modelId, model_type: modelType },
    api,
  })

  useEffect(() => {
    if (modelId) {
      fetchDataList({ page: 1, per_page: 1 }, () => {
        setMounted(true)
      })
    }
    return () => {
      setMounted(false)
    }
  }, [modelId])
  console.log(dataList[0])
  return (
    <Spin spinning={loading || !isMounted}>
      {isMounted ? (
        <Space direction={'vertical'} style={{ width: '100%' }}>
          <FormAddressGoogleMap
            brazilOnly={true}
            modelType={modelType}
            modelId={modelId}
            initialValues={dataList[0]}
            onFinish={(data) => setDataList([data])}
            api={api}
          />
        </Space>
      ) : null}
    </Spin>
  )
}
