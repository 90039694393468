import MockAdapter from 'axios-mock-adapter'
import { mockDataExecution } from '../mock-data/mock-data-execution'
import { mockDataDriver } from '../mock-data/mock-data-driver'
import { mockDataUser } from '../mock-data/mock-data-user'
import { mockDataTimer } from '../mock-data/mock-data-timer'

const execution = mockDataExecution[0]
const driver = mockDataDriver?.[execution?.driver_id - 1]
const driverFormatted = {
  ...driver,
  user: mockDataUser?.[driver?.user_id - 1],
}

export const mockExecutionApi = (mockApi: MockAdapter) => {
  mockApi.onGet('/executions').reply(
    200,
    mockDataExecution.map((i) => ({
      ...i,
      driver: mockDataDriver?.[i?.driver_id - 1],
    })),
  )
  mockApi.onGet('/executions/1').reply(200, {
    ...mockDataExecution[0],
    driver: driverFormatted,
    timers: mockDataTimer,
  })
}
