import React, { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import _ from 'lodash'
import { Avatar, Card, Col, Divider, Row, Space, Statistic, Tag, Typography } from 'antd'
import { formatDate, GoogleMapsRoute } from 'rk-react-component'
import { TableTimer } from '../../../containers/tables/table-timer/table-timer'
import { FaLink } from 'react-icons/fa'
import { ButtonGetReport } from '../../../components/button-get-report/button-get-report'
import { MeContext } from '../../../contexts/me-context/me-context'
import { useCloseExecution } from './use-close-execution'
import { useLoadPage } from './use-load-page'

export const ExecutionShowPage = () => {
  const { id } = useParams()
  const { role } = useContext(MeContext)
  const { data, fetchData, dataInsertion, setLoading, positions, isMounted, loading } = useLoadPage({ id })
  const { closeElement } = useCloseExecution({ setLoading, role, data, fetchData, id })

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <CardInfoDriver loading={loading} dataInsertion={dataInsertion} data={data} />
        </Col>
        <Col span={16}>
          <CardExecutionInfo loading={loading} dataInsertion={dataInsertion} id={id} data={data} />
        </Col>

        <ContainerMapPosition isMounted={isMounted} data={data} positions={positions} />
        <ContainerMapStatic data={data} isMounted={isMounted} />
        <Col span={24}>
          <Card style={{ padding: 0 }} extra={closeElement}>
            <TableTimer loading={false} dataSource={data?.timers} loadData={fetchData} />
          </Card>
        </Col>
      </Row>
    </>
  )
}

const CardExecutionInfo = ({ loading, dataInsertion, id, data }) => {
  return (
    <Card loading={loading} style={{ minHeight: 220 }}>
      <Space direction={'vertical'}>
        <Row>
          <Col span={12}>
            <ButtonGetReport
              url={'/execution-simple-report/' + id}
              title={'Baixar Relatório Simplificado'}
              disabled={data?.status !== 'FINISHED'}
              tooltip={'Só é permitido baixar o relatório de execução após a execução ser finalizada'}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 8, marginBottom: 8 }} />
        <Space split={<Divider type={'vertical'} />}>
          <Statistic
            title={'Cliente'}
            valueStyle={{ color: 'black', fontSize: 16 }}
            value={dataInsertion?.customer?.name}
          />
          <Statistic
            title={'PI'}
            valueStyle={{ color: 'black', fontSize: 16 }}
            value={dataInsertion?.id}
            suffix={
              <Link to={`/insertions/${dataInsertion?.id}`}>
                <FaLink />
              </Link>
            }
          />

          <Statistic
            title={'Data'}
            valueStyle={{ color: 'black', fontSize: 16 }}
            value={formatDate(data?.date, 'DD/MM/YYYY HH')}
          />
          <Statistic
            title={'Horas Previstas'}
            valueStyle={{ color: 'black', fontSize: 16 }}
            value={data?.expected_hours}
            suffix={'h'}
          />
        </Space>
      </Space>
    </Card>
  )
}

const CardInfoDriver = ({ loading, dataInsertion, data }) => {
  const nameSplit = _.split(data?.driver?.user?.name, ' ')
  return (
    <Card style={{ padding: 0, minHeight: 220 }} loading={loading}>
      <Space style={{ width: '100%' }} direction={'vertical'} align={'center'}>
        <Typography.Text style={{ marginTop: 8 }}>{data?.supplier?.name}</Typography.Text>
        <Avatar size={40}>
          {nameSplit?.[0]?.[0]}
          {nameSplit?.[1]?.[0]}
        </Avatar>
        <Typography.Title level={5} style={{ marginTop: 12, marginBottom: 8 }}>
          {data?.driver?.user?.name}
        </Typography.Title>
        <Typography.Paragraph>
          Placa: <Tag>{data?.driver?.placa}</Tag>
        </Typography.Paragraph>
      </Space>
    </Card>
  )
}

const ContainerMapPosition = ({ isMounted, data, positions }) => {
  if (!isMounted) return null

  if (!positions?.length) return null

  if (data?.static_map_url) return null

  return (
    <Col span={24}>
      <Card loading={!isMounted}>
        {isMounted ? (
          <GoogleMapsRoute
            positions={positions}
            mapsProps={{
              defaultCenter: {
                lat: +positions?.[0]?.lat,
                lng: +positions?.[0]?.lng,
              },
              defaultZoom: 13,
            }}
          />
        ) : null}
      </Card>
    </Col>
  )
}

const ContainerMapStatic = ({ data, isMounted }) => {
  if (!data?.static_map_url) return null
  if (!isMounted) return null
  return (
    <Col span={24}>
      <Card size={'small'}>
        <Space direction={'vertical'}>
          <img src={data.static_map_url} alt={'Mapa Estático'} style={{ width: '100%' }} />
        </Space>
      </Card>
    </Col>
  )
}
