import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PageFormContainer } from '../../../containers/pages/page-form-container/page-form-container'
import { GetOneApiReturn, useGetOneApi } from 'rk-react-component'
import { RouteModel } from '../../../@types/route'
import { FormRoute } from '../../../containers/forms/form-route/form-route'
import { api } from '../../../services/api'

export const RoutesFormPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { data, loading }: GetOneApiReturn<RouteModel> = useGetOneApi({
    url: '/routes',
    id,
    api,
  })
  return (
    <PageFormContainer
      title={'Rota'}
      FormComponent={() => (
        <FormRoute initialValues={data} onFinish={(dataApi) => navigate(`/routes/${dataApi?.id}`)} />
      )}
      loading={loading}
    />
  )
}
