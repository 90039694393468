import React, { useContext, useEffect, useMemo } from 'react'
import { useGetAllApi } from 'rk-react-component'
import { PageIndexContainer } from '../../../containers/pages/page-index-container/page-index-container'
import { TableDriver } from '../../../containers/tables/table-driver/table-driver'
import { api } from '../../../services/api'
import { MeContext } from '../../../contexts/me-context/me-context'
import { FormFilterRole } from '../../../containers/form-filter/form-filter-role/form-filter-role'

export const DriverIndexPage = () => {
  const { role } = useContext(MeContext)
  const fixParams = useMemo(() => {
    if (role?.role === 'MANAGER_SUPPLIER') {
      return { supplier_id: role?.allow_model_id }
    }
    throw new Error('Você não tem permissão para acessar está página!')
  }, [])
  const { dataList, loading, fetchDataList, pagination } = useGetAllApi<any, any>({
    url: '/drivers',
    fixParams,
    api,
    initialRequest: false,
  })
  useEffect(() => {
    fetchDataList({ per_page: 10, page: 1 })
  }, [])
  return (
    <PageIndexContainer
      title={'Motoristas'}
      btnTitle={'Novo Motorista'}
      url={'/drivers'}
      FormFilterComponent={<FormFilterRole onFinish={(data) => fetchDataList(data)} />}
      TableComponent={() => (
        <TableDriver dataSource={dataList} loading={loading} pagination={pagination} loadData={fetchDataList} />
      )}
    />
  )
}
