import React, { useContext } from 'react'
import { ChatContainer, ChatRooms } from 'rk-react-component'
import { useChatHook } from './use-chat-hook/use-chat-hook'
import { api } from '../../services/api'
import { Card, Col, Divider, Row, Spin } from 'antd'
import 'react-chat-elements/dist/main.css'
import { MeContext } from '../../contexts/me-context/me-context'
import { AppContext } from '../../contexts/app-context/app-context'

export const ChatScreen = () => {
  const { me } = useContext(MeContext)
  const { socket } = useContext(AppContext)
  const { ...props } = useChatHook({ api, userId: me?.id, socket })

  return (
    <Spin spinning={props.loadingChatRooms}>
      <Card size={'small'}>
        <Row style={{ minHeight: 700, overflow: 'hidden' }}>
          <Col span={8}>
            <ChatRooms
              fetchNextData={props.fetchChatRoomsNextPage}
              chatRooms={props.chatRooms}
              chatRoomSelected={props.selectedChatRoom}
              onSelectChatRoom={props.onSelectChatRoom}
              height={700}
            />
          </Col>
          <Col span={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Divider type={'vertical'} style={{ height: '100%' }}></Divider>
          </Col>
          <Col span={15}>
            <ChatContainer
              height={700}
              chatRoomSelected={props.selectedChatRoom}
              messages={props.chatRoomMessages}
              loading={false}
              fetchNext={props?.fetchChatRoomMessagesNextPage}
              sendMessage={props.sendMessage}
            />
          </Col>
        </Row>
      </Card>
    </Spin>
  )
}
