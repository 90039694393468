import { Typography } from 'antd'
import React from 'react'
import { TableContainer } from '../table-container/table-container'
import { DriverModel } from '../../../@types/driver'

interface TableDriverProps {
  dataSource: DriverModel[]
  loading: boolean
  loadData: (params: any) => void
  pagination?: any
  onDelete?: any
}

export const TableDriver = ({ dataSource, loading, loadData, pagination, onDelete }: TableDriverProps) => {
  return (
    <TableContainer
      onDelete={onDelete}
      loadData={loadData}
      pagination={pagination}
      id={'table_driver'}
      loading={loading}
      columns={[
        {
          key: 'name',
          dataIndex: 'name',
          title: 'Nome',
          render: (val, record) => (
            <Typography.Text strong>{record?.user?.name || 'Nome Indisponível'}</Typography.Text>
          ),
        },
        {
          key: 'email',
          dataIndex: 'email',
          title: 'Email',
          render: (val, record) => (
            <Typography.Text strong>{record?.user?.email || 'Nome Indisponível'}</Typography.Text>
          ),
        },
        { key: 'placa', dataIndex: 'placa', title: 'Placa' },
      ]}
      dataSource={dataSource}
    />
  )
}
