import React, { useContext } from 'react'
import { Form, Input } from 'antd'
import { RouteModel } from '../../../@types/route'
import { FormContainer, FormItem, SelectAction, useFormApi } from 'rk-react-component'
import { api } from '../../../services/api'
import { MeContext } from '../../../contexts/me-context/me-context'
import { notificationSuccessSave } from '../../../services/notification-success-save'

interface FormRouteProps {
  onFinish: (data: RouteModel) => void
  initialValues: RouteModel | null
}

export const FormRoute = ({ onFinish, initialValues }: FormRouteProps) => {
  const [form] = Form.useForm()
  const { role } = useContext(MeContext)
  const { save, loading } = useFormApi<any, any>({
    fixParams: role?.role === 'MANAGER_COMPANY' ? { company_id: role?.allow_model_id } : {},
    url: '/routes',
    id: initialValues?.id,
    api,
  })
  return (
    <FormContainer
      dateCast={['shipping_date']}
      name={'form_route'}
      initialValues={initialValues}
      form={form}
      onFinish={(data) => {
        save(
          data,
          (response) => {
            onFinish(response)
            notificationSuccessSave(!initialValues?.id)
          },
          (e) => console.warn(e),
        )
      }}
      loading={loading}
    >
      <FormItem label={'Nome'} name={'name'} rules={[{ required: true, message: 'O campo "Nome" é obrigatório.' }]}>
        <Input placeholder={'Nome'} data-testid={'name'} />
      </FormItem>
      <FormItem
        label={'Descrição'}
        name={'description'}
        rules={[{ required: true, message: 'O campo "Descrição" é obrigatório.' }]}
      >
        <Input.TextArea placeholder={'Descrição'} data-testid={'description'} />
      </FormItem>
      {role?.role !== 'MANAGER_COMPANY' && (
        <FormItem
          label={'Empresa'}
          name={'company_id'}
          rules={[{ required: true, message: 'O campo "Empresa" é obrigatório.' }]}
        >
          <SelectAction api={api} url={'/companies'} fieldSearch={'name_lk'} data-testid={'company_id'} />
        </FormItem>
      )}
    </FormContainer>
  )
}
