import React from 'react'
import { FormFilterContainer, FormFilterItem } from 'rk-react-component'
import { Form, Input } from 'antd'

interface FormFilterHeadquarterProps {
  onFinish: (data: Partial<any> & { page: number; per_page: number }) => void
  initialValues?: any
}

export const FormFilterHeadquarter = ({ onFinish, initialValues }: FormFilterHeadquarterProps) => {
  const [form] = Form.useForm()
  return (
    <FormFilterContainer
      formProps={{
        onFinish: (data) => {
          onFinish({ ...data, page: 1, per_page: 10 })
        },
        initialValues: initialValues,
      }}
      form={form}
      hiddenShowMore={true}
    >
      {() => (
        <>
          <FormFilterItem name={'name_lk'}>
            <Input placeholder={'Nome'} data-testid={'name_lk'} />
          </FormFilterItem>
          <FormFilterItem name={'description_lk'}>
            <Input placeholder={'Descrição'} data-testid={'description_lk'} />
          </FormFilterItem>
        </>
      )}
    </FormFilterContainer>
  )
}
