import React, { useContext } from 'react'
import { Form, Input } from 'antd'
import { CompanyModel } from '../../../@types/company'
import { FormContainer, FormItem, InputCnpj, SelectAction, TreeSelectAction, useFormApi } from 'rk-react-component'
import { api } from '../../../services/api'
import { MeContext } from '../../../contexts/me-context/me-context'
import { notificationSuccessSave } from '../../../services/notification-success-save'
import { SelectSupplier } from '../../inputs/select-supplier/select-supplier'

interface FormCompanyProps {
  onFinish: (data: CompanyModel) => void
  initialValues?: CompanyModel
  onBack?: () => void
}

export const FormCompany = ({ onFinish, initialValues, onBack }: FormCompanyProps) => {
  const [form] = Form.useForm()
  const headquarter_id = Form.useWatch('headquarter_id', form)
  const { role } = useContext(MeContext)
  const { save, loading } = useFormApi<any, any>({
    url: '/companies',
    id: initialValues?.id,
    api,
  })
  return (
    <FormContainer
      onBack={onBack}
      name={'form_company'}
      initialValues={initialValues}
      form={form}
      onFinish={(data) => {
        save(
          {
            ...data,
            document_cnpj: data.document_cnpj.replace(/\D/g, ''),
          },
          (response) => {
            onFinish(response)
            notificationSuccessSave(!initialValues?.id)
          },
          (e) => console.warn(e),
        )
      }}
      loading={loading}
    >
      <FormItem label={'Nome'} name={'name'} rules={[{ required: true, message: 'O campo "Nome" é obrigatório.' }]}>
        <Input placeholder={'Nome'} data-testid={'name'} />
      </FormItem>
      <FormItem label={'Cód.'} name={'code'}>
        <Input placeholder={'Cód'} data-testid={'code'} />
      </FormItem>
      <FormItem
        label={'Nome Fantasia'}
        name={'fantasy_name'}
        rules={[{ required: true, message: 'O campo "Nome Fantasia" é obrigatório.' }]}
      >
        <Input placeholder={'Nome Fantasia'} data-testid={'fantasy_name'} />
      </FormItem>
      <FormItem
        label={'Razão Social'}
        name={'razao_social'}
        rules={[{ required: true, message: 'O campo "Razão Social" é obrigatório.' }]}
      >
        <Input placeholder={'Razão Social'} data-testid={'razao_social'} />
      </FormItem>
      <FormItem label={'CNPJ'} name={'document_cnpj'}>
        <InputCnpj placeholder={'Razão Social'} data-testid={'document_cnpj'} />
      </FormItem>
      <FormItem
        label={'Descrição'}
        name={'description'}
        rules={[{ required: true, message: 'O campo "Descrição" é obrigatório.' }]}
      >
        <Input.TextArea placeholder={'Descrição'} data-testid={'description'} />
      </FormItem>
      <FormItem label={'Fornecedor Padrão'} name={'supplier_default_id'}>
        <SelectSupplier
          style={{ width: '100%' }}
          defaultOptions={initialValues?.supplier_default ? [initialValues?.supplier_default] : []}
        />
      </FormItem>
      {role.role === 'MASTER' && (
        <FormItem
          label={'Sede'}
          name={'headquarter_id'}
          rules={[{ required: true, message: 'O campo "Sede" é obrigatório.' }]}
        >
          <SelectAction url={'/headquarters'} api={api} fieldSearch={'name_lk'} data-testid={'headquarter_id'} />
        </FormItem>
      )}
      {(role.role === 'MASTER' || role.role === 'MANAGER_HEADQUARTER') && (
        <FormItem
          label={'Região'}
          name={'region_id'}
          rules={[{ required: true, message: 'O campo "Região" é obrigatório.' }]}
        >
          <TreeSelectAction
            data-testid={'region_id'}
            url={'/regions'}
            api={api}
            fixParams={{
              headquarter_id: headquarter_id || role.allow_model_id,
              parent_id_is_null: true,
            }}
            disabled={!headquarter_id && role.role === 'MASTER'}
          />
        </FormItem>
      )}
    </FormContainer>
  )
}
