import React, { useContext } from 'react'
import { Space, Typography } from 'antd'
import { FormContainer, FormItem } from 'rk-react-component'
import { api } from '../../../services/api'
import { DatePickerMoment } from '../../../components/date-picke-moment/date-picker-moment'
import { MeContext } from '../../../contexts/me-context/me-context'

export const TabSupplierFinancialReport = ({ onFinish }) => {
  const [loading, setLoading] = React.useState(false)
  const { role } = useContext(MeContext)
  return (
    <Space direction={'vertical'} style={{ width: '100%' }}>
      <Typography.Title level={4}>Relatório Financeiro</Typography.Title>
      <FormContainer
        loading={loading}
        onFinish={(data) => {
          setLoading(true)
          api
            .put('/financial-full-report', { ...data, supplier_id: role?.allow_model_id }, { responseType: 'blob' })
            .then(({ data }) => {
              onFinish(data)
            })
            .catch(() => {})
            .finally(() => setLoading(false))
        }}
      >
        <FormItem label={'Período'} name={'period'} rules={[{ required: true, message: 'O Período é obrigatório' }]}>
          <DatePickerMoment.MonthPicker format={'MMM/YY'} />
        </FormItem>
      </FormContainer>
    </Space>
  )
}
