import React from 'react'
import { Form, Input, Segmented } from 'antd'
import { FormContainer, FormItem, InputCpf, InputPhone, useFormApi } from 'rk-react-component'
import { api } from '../../../services/api'
import { notificationSuccessSave } from '../../../services/notification-success-save'
import { SelectUser } from '../../inputs/select-user/select-user'
import { InputPasswordStrength } from '../../inputs/input-password/input-password-strength'

interface FormDriverProps {
  onFinish: (data: any) => void
  initialValues?: Partial<any> | null
  onBack?: () => void
  fixParams: any
}

export const FormDriver = ({ onFinish, initialValues, onBack, fixParams }: FormDriverProps) => {
  const [form] = Form.useForm()
  const type_form = Form.useWatch('type_form', form)

  const { save, loading } = useFormApi<any, any>({
    url: `/drivers`,
    id: initialValues?.id,
    fixParams,
    api,
  })
  const { save: saveUser, loading: loadingUser } = useFormApi<any, any>({
    url: '/users',
    id: undefined,
    api,
  })

  return (
    <FormContainer
      onBack={onBack}
      name={'form_driver'}
      initialValues={{ type_form: 'select', ...initialValues }}
      form={form}
      onFinish={(data) => {
        data.document_cpf = data.document_cpf?.replace(/\D/g, '')

        if (type_form === 'new') {
          data.mobile = data.mobile?.replace(/\D/g, '')
          data.phone = data.phone?.replace(/\D/g, '')
          saveUser(
            data,
            (response) => {
              save(
                { user_id: response?.id, ...data },
                (responseDriver) => {
                  onFinish(responseDriver)
                  notificationSuccessSave(!initialValues?.id)
                },
                () => {},
              )
            },
            () => {},
          )
        } else if (type_form === 'select') {
          const { type_form, ...dataDriver } = data
          save(
            dataDriver,
            (response) => {
              onFinish(response)
              notificationSuccessSave(!initialValues?.id)
            },
            (e) => console.warn(e),
          )
        }
      }}
      loading={loading || loadingUser}
    >
      {!initialValues?.id ? (
        <FormItem label={'Como deseja adicionar?'} name={'type_form'}>
          <Segmented
            data-testid={'type_form'}
            options={[
              { label: 'Selecionar Usuário', value: 'select', icon: undefined },
              { label: 'Novo Usuário', value: 'new', icon: undefined },
            ]}
          />
        </FormItem>
      ) : null}

      {type_form === 'select' || initialValues?.id ? (
        <FormItem label={'Usuário'} name={'user_id'}>
          <SelectUser data-testid={'user_id'} disabled={!!initialValues?.id} />
        </FormItem>
      ) : (
        <>
          <FormItem label={'Nome'} name={'name'} rules={[{ required: true, message: 'O Nome é obrigatório' }]}>
            <Input placeholder={'Nome'} data-testid={'name'} />
          </FormItem>
          <FormItem label={'Email'} name={'email'} rules={[{ required: true, message: 'O Email é obrigatório' }]}>
            <Input placeholder={'Email'} data-testid={'email'} />
          </FormItem>
          <FormItem label={'Celular'} name={'mobile'} rules={[{ required: true, message: 'O Celular é obrigatório' }]}>
            <InputPhone placeholder={'Celular'} data-testid={'mobile'} />
          </FormItem>
          <FormItem label={'Telefone'} name={'phone'} rules={[{ required: true, message: 'O Telefone é obrigatório' }]}>
            <InputPhone placeholder={'Telefone'} data-testid={'phone'} />
          </FormItem>
          <InputPasswordStrength />
        </>
      )}

      <FormItem name={'placa'} label={'Placa'} rules={[{ required: true, message: 'O campo "Placa" é obrigatório.' }]}>
        <Input placeholder={'Placa'} data-testid={'placa'} />
      </FormItem>
      <FormItem
        name={'document_cpf'}
        label={'CPF'}
        rules={[{ required: true, message: 'O campo "CPF"é obrigatório.' }]}
      >
        <InputCpf data-testid={'document_cpf'} />
      </FormItem>
      <FormItem name={'document_rg'} label={'RG'}>
        <Input data-testid={'document_rg'} />
      </FormItem>
    </FormContainer>
  )
}
