import React, { useEffect, useState } from 'react'
import { Button, Divider, Form, Image, Input, notification, Result, Row, Spin } from 'antd'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { FormItem, InputCpf, InputPhone } from 'rk-react-component'
import { api } from '../../services/api'
import { InputPasswordStrength } from '../../containers/inputs/input-password/input-password-strength'

export const SignupDriver = () => {
  const [form] = Form.useForm()
  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [loadingSupplier, setLoadingSupplier] = useState(false)
  const [success, setSuccess] = useState(false)
  useEffect(() => {
    setLoadingSupplier(true)
    api
      .get('/suppliers/' + id)
      .then(({ data }) => {
        form.setFieldsValue({ supplier: data.name })
      })
      .catch(() => {
        navigate('/signin')
        notification.error({ message: 'Fornecedor não encontrado' })
      })
      .finally(() => {
        setLoadingSupplier(false)
      })
  }, [])
  return (
    <div
      style={{
        backgroundColor: 'white',
        height: 'calc(100vh)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <div style={{ display: 'flex', flex: 1 }} />
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center' }}>
        <span style={{ width: 400 }}>
          <Row justify={'center'}>
            <Image src={require('../../assets/logo-with-icon-orange.png')} preview={false} style={{ maxWidth: 240 }} />
            <Divider />
          </Row>
          <Spin spinning={loadingSupplier}>
            {success ? (
              <Result
                title={'Cadastro Realizado com sucesso!'}
                subTitle={'Agora você já pode fazer login com seu email e senha no Aplicativo.'}
                status={'success'}
              />
            ) : (
              <Form
                size={'large'}
                form={form}
                initialValues={{ supplier: 'Paulo Supplier' }}
                onFinish={(data) => {
                  if (data.password !== data.password_confirmation) {
                    notification.error({ message: 'As senhas não conferem' })
                    return
                  }
                  setLoading(true)
                  api
                    .post('/sign-up/driver/' + id, data)
                    .then(({ data: dataUser }) => {
                      setSuccess(true)
                    })
                    .catch((e) => {
                      console.warn(e)
                    })
                    .finally(() => {
                      setLoading(false)
                    })
                }}
              >
                <FormItem label={'Fornecedor'} name="supplier">
                  <Input placeholder={'Nome'} disabled={true} />
                </FormItem>
                <FormItem name={'name'} rules={[{ required: true, message: 'O Nome é obrigatório' }]}>
                  <Input placeholder={'Nome'} data-testid={'name'} />
                </FormItem>
                <FormItem name={'email'} rules={[{ required: true, message: 'O Email é obrigatório' }]}>
                  <Input placeholder={'Email'} data-testid={'email'} />
                </FormItem>
                <FormItem name={'mobile'} rules={[{ required: true, message: 'O Celular é obrigatório' }]}>
                  <InputPhone placeholder={'Celular'} data-testid={'mobile'} />
                </FormItem>
                <FormItem name={'placa'} rules={[{ required: true, message: 'O campo "Placa" é obrigatório.' }]}>
                  <Input placeholder={'Placa'} data-testid={'placa'} />
                </FormItem>
                <FormItem name={'document_cpf'} rules={[{ required: true, message: 'O campo "CPF"é obrigatório.' }]}>
                  <InputCpf data-testid={'document_cpf'} placeholder={'CPF'} />
                </FormItem>
                <InputPasswordStrength />
                <FormItem
                  name="password_confirmation"
                  rules={[{ required: true, message: 'O campo "Confirmação da senha" é obrigatório.' }]}
                >
                  <Input.Password placeholder={'Confirmar Senha'} />
                </FormItem>
                <Button type={'primary'} htmlType={'submit'} block loading={loading}>
                  Cadastrar
                </Button>
                <FormItem style={{ marginBottom: 16, justifyContent: 'flex-end', display: 'flex', flex: 1 }}>
                  <Link to={'/signin'}>Já sou Cadastrado</Link>
                </FormItem>
              </Form>
            )}
          </Spin>
        </span>
      </div>
      <div style={{ display: 'flex', flex: 2 }} />
    </div>
  )
}
