import { Avatar, Button, Divider, Popconfirm, Space, Tooltip, Typography } from 'antd'
import { DropdownRole } from '../../../components/dropdown-role/dropdown-role'
import { formatModel } from '../../../services/format'
import { BiLogOut } from 'react-icons/bi'
import { configProject } from '../../../config-project'
import React, { useContext } from 'react'
import { MeContext } from '../../../contexts/me-context/me-context'
import { useNavigate } from 'react-router-dom'
import { CompanyIcon, HeadquarterIcon, ProfileIcon, RegionIcon, SupplierIcon } from '../../icon-modules'
import { extractNameInitials } from '../../../utils/format-data'
import { NotificationDrawer } from '../../../components/notification-drawer/notification-drawer'

const ICON_SIZE = 16

export const MenuExtraRender = ({ collapsed }) => {
  const { role, me, setRole, setMe } = useContext(MeContext)
  const navigate = useNavigate()
  if (collapsed) {
    return (
      <Space direction={'vertical'} align={'center'} style={{ width: '100%' }}>
        <Tooltip title={'Meu Perfil'}>
          <Button
            type={'link'}
            icon={<ProfileIcon size={ICON_SIZE} color={configProject.colorTextMenuSelected} />}
            onClick={() => {
              navigate(`/my-profile/${me?.id}`)
            }}
          />
        </Tooltip>
        {role?.role === 'MANAGER_HEADQUARTER' && (
          <Tooltip title={'Minha Sede'}>
            <Button
              type={'link'}
              icon={<HeadquarterIcon size={ICON_SIZE} color={configProject.colorTextMenuSelected} />}
              onClick={() => {
                navigate(`/my-headquarter/${role?.allow_model_id}`)
              }}
            />
          </Tooltip>
        )}
        {role?.role === 'MANAGER_REGION' && (
          <Tooltip title={'Minha Região'}>
            <Button
              type={'link'}
              icon={<RegionIcon size={ICON_SIZE} color={configProject.colorTextMenuSelected} />}
              onClick={() => {
                navigate(`/my-region/${role?.allow_model_id}`)
              }}
            />
          </Tooltip>
        )}
        {role?.role === 'MANAGER_COMPANY' && (
          <Tooltip title={'Minha Loja'}>
            <Button
              type={'link'}
              icon={<CompanyIcon size={ICON_SIZE} color={configProject.colorTextMenuSelected} />}
              onClick={() => {
                navigate(`/my-company/${role?.allow_model_id}`)
              }}
            />
          </Tooltip>
        )}
        {role?.role === 'MANAGER_SUPPLIER' && (
          <Tooltip title={'Minha Empresa'}>
            <Button
              type={'link'}
              icon={<SupplierIcon size={ICON_SIZE} color={configProject.colorTextMenuSelected} />}
              onClick={() => {
                navigate(`/my-supplier/${role?.allow_model_id}`)
              }}
            />
          </Tooltip>
        )}
        <NotificationDrawer color={configProject.colorTextMenuSelected} iconSize={ICON_SIZE} />
      </Space>
    )
  }
  return (
    <>
      <Space direction={'vertical'}>
        <Space direction={'horizontal'}>
          <Avatar size={'large'} style={{ marginRight: 8 }}>
            {extractNameInitials(me?.name)}
          </Avatar>
          <div>
            <Typography.Title level={4} style={{ color: 'white', margin: 0 }}>
              {me?.name}
            </Typography.Title>
            <DropdownRole />
          </div>
        </Space>
        {role?.role === 'MANAGER_HEADQUARTER' ? (
          <div>
            <Typography.Title
              level={5}
              style={{
                marginRight: 8,
                color: 'white',
                marginLeft: 4,
                marginTop: 0,
              }}
            >
              <Typography.Text style={{ marginRight: 8, color: 'white' }}>
                {formatModel?.[role?.allow_model_type]}:
              </Typography.Text>
              {role?.allow_model?.name || ''}
            </Typography.Title>
          </div>
        ) : null}
        <Space size={0} direction={'horizontal'} split={<Divider type={'vertical'} />}>
          <Tooltip title={'Meu Perfil'}>
            <Button
              type={'link'}
              icon={<ProfileIcon size={ICON_SIZE} color={configProject.colorTextMenuSelected} />}
              onClick={() => {
                navigate(`/my-profile/${me?.id}`)
              }}
            />
          </Tooltip>
          {role?.role === 'MANAGER_HEADQUARTER' && (
            <Tooltip title={'Minha Sede'}>
              <Button
                type={'link'}
                icon={<HeadquarterIcon size={ICON_SIZE} color={configProject.colorTextMenuSelected} />}
                onClick={() => {
                  navigate(`/my-headquarter/${role?.allow_model_id}`)
                }}
              />
            </Tooltip>
          )}
          {role?.role === 'MANAGER_REGION' && (
            <Tooltip title={'Minha Região'}>
              <Button
                type={'link'}
                icon={<RegionIcon size={ICON_SIZE} color={configProject.colorTextMenuSelected} />}
                onClick={() => {
                  navigate(`/my-region/${role?.allow_model_id}`)
                }}
              />
            </Tooltip>
          )}
          {role?.role === 'MANAGER_COMPANY' && (
            <Tooltip title={'Minha Loja'}>
              <Button
                type={'link'}
                icon={<CompanyIcon size={ICON_SIZE} color={configProject.colorTextMenuSelected} />}
                onClick={() => {
                  navigate(`/my-company/${role?.allow_model_id}`)
                }}
              />
            </Tooltip>
          )}
          {role?.role === 'MANAGER_SUPPLIER' && (
            <Tooltip title={'Minha Empresa'}>
              <Button
                type={'link'}
                icon={<SupplierIcon size={ICON_SIZE} color={configProject.colorTextMenuSelected} />}
                onClick={() => {
                  navigate(`/my-supplier/${role?.allow_model_id}`)
                }}
              />
            </Tooltip>
          )}
          <NotificationDrawer color={configProject.colorTextMenuSelected} iconSize={ICON_SIZE} />
          <Popconfirm
            title={'Tem certeza que deseja sair?'}
            onConfirm={() => {
              setMe(null)
              setRole(null)
              localStorage.clear()
              navigate('/signin')
            }}
          >
            <Button type={'link'} icon={<BiLogOut size={ICON_SIZE} color={configProject.colorTextMenuSelected} />} />
          </Popconfirm>
        </Space>
        <Divider style={{ marginTop: 12, marginBottom: 12 }} />
      </Space>
    </>
  )
}
