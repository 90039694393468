import React from 'react'
import { FormContainer, FormItem, InputPhone, SelectAction, useFormApi } from 'rk-react-component'
import { Avatar, Flex, Form, Input, Segmented } from 'antd'
import { RoleModel } from '../../../@types/role'
import { api } from '../../../services/api'
import { notificationSuccessSave } from '../../../services/notification-success-save'
import { InputPasswordStrength } from '../../inputs/input-password/input-password-strength'

interface FormRoleUserProps {
  onFinish: (data: RoleModel) => void
  initialValues?: RoleModel | null
  onBack?: () => void
  fixParams: {
    role:
      | 'MANAGER_HEADQUARTER'
      | 'MANAGER_SUPPLIER'
      | 'MANAGER_REGION'
      | 'MANAGER_COMPANY'
      | 'MASTER'
      | 'DRIVER'
      | 'OPERATIONAL_REGION'
      | 'MARKETING_REGION'
      | 'TI_ASSAI'
    allow_model_type: 'HeadquarterModel' | 'SupplierModel' | 'RegionModel' | 'CompanyModel' | null
    allow_model_id: number | null
  }
}

export const FormRoleUser = ({ onFinish, initialValues, onBack, fixParams }: FormRoleUserProps) => {
  const [form] = Form.useForm()
  const type_form = Form.useWatch('type_form', form)
  const { save, loading } = useFormApi<any, any>({
    url: `/roles`,
    fixParams,
    id: initialValues?.id,
    api,
  })

  const { save: saveUser, loading: loadingUser } = useFormApi<any, any>({
    url: `/users`,
    id: null,
    api,
  })
  return (
    <FormContainer
      onBack={onBack}
      name={'form_role_user'}
      initialValues={{ type_form: 'select', ...initialValues }}
      form={form}
      onFinish={(data) => {
        if (type_form === 'new') {
          const { allow_model_type, allow_model_id, type_form, role, ...userData } = data
          saveUser(
            { ...userData, mobile: userData.mobile?.replace(/\D/g, '') },
            (response) => {
              save(
                {
                  allow_model_type: allow_model_type,
                  allow_model_id: allow_model_id,
                  role: role,
                  user_id: response?.id,
                },
                (responseDriver) => {
                  onFinish(responseDriver)
                  notificationSuccessSave(!initialValues?.id)
                },
                () => {},
              )
            },
            () => {},
          )
        } else if (type_form === 'select') {
          const { type_form, ...roleData } = data
          save(
            roleData,
            (response) => {
              onFinish(response)
              notificationSuccessSave(!initialValues?.id)
            },
            (e) => console.warn(e),
          )
        }
      }}
      loading={loading}
    >
      {!initialValues?.id ? (
        <FormItem label={'Como deseja adicionar?'} name={'type_form'}>
          <Segmented
            data-testid={'type_form'}
            options={[
              { label: 'Selecionar Usuário', value: 'select', icon: undefined },
              { label: 'Novo Usuário', value: 'new', icon: undefined },
            ]}
          />
        </FormItem>
      ) : null}
      {type_form === 'select' || initialValues?.id ? (
        <FormItem label={'Usuário'} name={'user_id'}>
          <SelectAction
            data-testid={'user_id'}
            api={api}
            url={'/users'}
            fieldSearch={'name_lk'}
            disabled={!!initialValues?.id}
            displayLabel={({ item }) => (
              <Flex style={{ borderBottomWidth: 1 }} gap={12} align={'center'}>
                <Avatar size={'small'} />
                <div>
                  <strong>{item?.name}</strong>
                  <div>{item?.email}</div>
                </div>
              </Flex>
            )}
          />
        </FormItem>
      ) : (
        <>
          <FormItem label={'Nome'} name={'name'} rules={[{ required: true, message: 'O Nome é obrigatório' }]}>
            <Input placeholder={'Nome'} data-testid={'name'} />
          </FormItem>
          <FormItem label={'Email'} name={'email'} rules={[{ required: true, message: 'O Email é obrigatório' }]}>
            <Input placeholder={'Email'} data-testid={'email'} />
          </FormItem>
          <FormItem label={'Celular'} name={'mobile'} rules={[{ required: true, message: 'O Celular é obrigatório' }]}>
            <InputPhone placeholder={'Celular'} data-testid={'mobile'} />
          </FormItem>
          <InputPasswordStrength formItemProps={{ label: 'Senha', name: 'password' }} />
        </>
      )}
    </FormContainer>
  )
}
