import { api } from '../../../services/api'
import { SelectAction, SelectActionProps } from 'rk-react-component'
import React from 'react'

export const SelectDriver = ({ ...props }: Partial<SelectActionProps>) => {
  return (
    <SelectAction
      api={api}
      url={'/drivers-select'}
      style={{ width: 150 }}
      fieldSearch={'user_name'}
      placeholder={'Selecione um motorista'}
      displayLabel={({ item }) => {
        if (!item?.user?.name) {
          return '-'
        }
        return `${item?.user?.name} - ${item?.user?.email}`
      }}
      displayLabelValue={({ item }) => {
        if (!item?.user?.name) {
          return '-'
        }
        return `${item?.user?.name} - ${item?.user?.email}`
      }}
      {...props}
    />
  )
}
