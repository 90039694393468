import React, { useContext } from 'react'
import { SigninPage } from './signin-page'
import { MeContext } from '../../contexts/me-context/me-context'
import { api } from '../../services/api'
import { notification } from 'antd'
import { useNavigate } from 'react-router-dom'

const failSearchRole = () => {
  notification.error({ message: 'Erro ao buscar roles, contate o suporte' })
  localStorage.clear()
}

export const SigninFactory = () => {
  const navigate = useNavigate()
  const { setMe, setRole } = useContext(MeContext)
  const [isLoading, setIsLoading] = React.useState(false)
  return (
    <SigninPage
      loading={isLoading}
      onSignin={({ email = '', password = '' }) => {
        setIsLoading(true)
        api
          .post('/sign-in', { email: email.trim(), password: password.trim() })
          .then(({ data: dataUser }) => {
            console.log('aqui', dataUser)
            const response = dataUser.data
            localStorage.setItem('user', JSON.stringify(response))
            setMe(response)
            if (response.active_role) {
              localStorage.setItem('active_role', JSON.stringify(response.active_role))
              setRole(response.active_role)
              navigate('/dashboard')
            } else {
              api
                .get(`/roles`, { params: { user_id: response?.id, active: true } })
                .then(({ data: dataRole }) => {
                  setIsLoading(false)
                  if (!dataRole?.data?.length) {
                    failSearchRole()
                    return
                  }
                  const dataRoleAux = dataRole?.data?.[0]

                  localStorage.setItem('active_role', JSON.stringify(dataRoleAux))
                  setRole(dataRoleAux)
                  navigate('/dashboard')
                })
                .catch(() => {
                  setIsLoading(false)
                  failSearchRole()
                })
            }
          })
          .catch(() => {
            setIsLoading(false)
            notification.error({ message: 'Erro ao fazer login' })
          })
      }}
    />
  )
}
