import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GetOneApiReturn, useGetOneApi } from 'rk-react-component'
import { RegionModel } from '../../../@types/region'
import { FormRegion } from '../../../containers/forms/form-region/form-region'
import { api } from '../../../services/api'
import { PageFormTabContainer } from '../../../containers/pages/page-form-tab-container/page-form-tab-container'
import { ModalAddRole } from '../../../containers/modals/modal-add-role/modal-add-role'
import { MeContext } from '../../../contexts/me-context/me-context'

export const RegionFormPage = () => {
  const navigate = useNavigate()
  const { role } = useContext(MeContext)
  const { id } = useParams()
  const { data, loading }: GetOneApiReturn<RegionModel> = useGetOneApi({
    url: '/regions',
    id,
    api,
  })

  useEffect(() => {
    if (role?.role !== 'MASTER') {
      navigate('/regions')
    }
  }, [role])

  return (
    <PageFormTabContainer
      title={'Região'}
      items={[
        {
          FormComponent: (
            <FormRegion initialValues={data} onFinish={(dataApi) => navigate(`/regions/${dataApi?.id}`)} />
          ),
          disabled: false,
          key: '1',
          label: 'Informações',
          loading,
          title: 'Informações',
        },
        {
          FormComponent: (
            <ModalAddRole
              fixParams={{
                allow_model_type: 'RegionModel',
                allow_model_id: +data?.id,
                role: 'MANAGER_REGION',
              }}
            />
          ),
          disabled: !data?.id,
          key: '2',
          label: 'Responsáveis',
          loading,
          title: 'Responsáveis',
        },
        {
          FormComponent: (
            <ModalAddRole
              fixParams={{
                allow_model_type: 'RegionModel',
                allow_model_id: +data?.id,
                role: 'OPERATIONAL_REGION',
              }}
            />
          ),
          disabled: !data?.id,
          key: '3',
          label: 'Gerente Regional de Operações',
          loading,
          title: 'Gerente Regional de Operações',
        },
        {
          FormComponent: (
            <ModalAddRole
              fixParams={{
                allow_model_type: 'RegionModel',
                allow_model_id: +data?.id,
                role: 'MARKETING_REGION',
              }}
            />
          ),
          disabled: !data?.id,
          key: '4',
          label: 'Consultor de Marketing Regional',
          loading,
          title: 'Consultor de Marketing Regional',
        },
      ]}
    />
  )
}
