import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetOneApi } from 'rk-react-component'
import { PageFormContainer } from '../../../containers/pages/page-form-container/page-form-container'
import { api } from '../../../services/api'
import { FormRoleUser } from '../../../containers/forms/form-role-user/form-role-user'

export const MasterFormPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { data, loading } = useGetOneApi<any>({ url: '/roles', id, api })

  return (
    <PageFormContainer
      onBack={() => navigate('/masters')}
      title={'Administrador'}
      FormComponent={() => (
        <FormRoleUser
          onBack={() => navigate('/masters')}
          initialValues={data}
          onFinish={(dataApi) => navigate(`/masters`)}
          fixParams={{
            allow_model_type: null,
            allow_model_id: null,
            role: 'MASTER',
          }}
        />
      )}
      loading={loading}
    />
  )
}
