import { api } from '../../../services/api'
import { SelectAction, SelectActionProps } from 'rk-react-component'
import React from 'react'

export const SelectUser = ({ ...props }: Partial<SelectActionProps>) => {
  return (
    <SelectAction
      api={api}
      url={'/users-select'}
      fieldSearch={'name_lk'}
      placeholder={'Selecione um usuário'}
      displayLabel={({ item }) => item?.name || '-'}
      displayLabelValue={({ item }) => item?.name || '-'}
      {...props}
    />
  )
}
