import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetOneApi } from 'rk-react-component'
import { PageFormTabContainer } from '../../../containers/pages/page-form-tab-container/page-form-tab-container'
import { api } from '../../../services/api'
import { FormPasswordTi } from '../../../containers/forms/form-password-ti/form-password-ti'

export const UserTiFormPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data, loading } = useGetOneApi<any>({
    url: '/users-ti',
    id,
    api,
  })

  return (
    <PageFormTabContainer
      title={'Usuário'}
      items={[
        {
          disabled: !data?.id,
          key: 'tab_password',
          label: 'Senha',
          loading: loading,
          title: 'Trocar Senha',
          FormComponent: <FormPasswordTi onFinish={() => {}} userId={data?.id} />,
        },
      ]}
    />
  )
}
