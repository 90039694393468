import React, { useContext } from 'react'
import { FormContainer, FormItem, useFormApi } from 'rk-react-component'
import { Form, Input } from 'antd'
import { AddressModel } from '../../../@types/address'
import { api } from '../../../services/api'
import { MeContext } from '../../../contexts/me-context/me-context'
import { InputPasswordStrength } from '../../inputs/input-password/input-password-strength'

interface FormPasswordProps {
  onFinish: (data: AddressModel) => void
  userId: number
}

export const FormPassword = ({ onFinish, userId }: FormPasswordProps) => {
  const [form] = Form.useForm()
  const { role } = useContext(MeContext)
  const { save, loading } = useFormApi<any, any>({
    url: `/change-password`,
    id: null,
    fixParams: { user_id: userId },
    api,
  })
  return (
    <FormContainer
      name={'form_password'}
      initialValues={null}
      form={form}
      onFinish={(data) => {
        save(
          {
            ...data,
          },
          (response) => {
            onFinish(response)
          },
          (e) => console.warn(e),
        )
      }}
      loading={loading}
    >
      {role?.role !== 'MASTER' ? (
        <FormItem label={'Senha Antiga'} name={'password_old'}>
          <Input.Password />
        </FormItem>
      ) : null}
      <InputPasswordStrength formItemProps={{ label: 'Senha Nova', name: 'password_new' }} />
      <FormItem label={'Confirmar Senha'} name={'password_confirmation'}>
        <Input.Password />
      </FormItem>
    </FormContainer>
  )
}
