import React, { useMemo } from 'react'
import { Button, Divider, notification, PaginationProps, Popconfirm, Space, Table, TableProps } from 'antd'
import { DeleteIcon, EditIcon } from '../../../main/icon-modules'
import './style.css'

export interface TableContainerProps<D, P> extends TableProps<D> {
  onEdit?: (record: D) => void
  total?: number
  pagination?: any
  loadData?: (params: Partial<P> & { page: number; per_page: number }) => void
  onDelete?: any
  setDataList?: any
  filter?: any
}

export const TableContainer = <D, P>({
  onEdit,
  onDelete,
  columns,
  pagination,
  loadData,
  filter = {},
  ...props
}: TableContainerProps<D, P>) => {
  const columnsFormatted = React.useMemo(() => {
    const columnsAux = columns
    if (typeof onEdit === 'function' || typeof onDelete === 'function') {
      columnsAux.push({
        title: 'Ações',
        dataIndex: 'actions',
        render: (text: any, record: any) => (
          <Space size={0} split={<Divider type={'horizontal'} />}>
            {typeof onEdit === 'function' ? (
              <Button
                type={'link'}
                onClick={(event) => {
                  onEdit(record)
                  event.stopPropagation()
                  event.preventDefault()
                }}
                icon={<EditIcon />}
              />
            ) : null}
            {typeof onDelete === 'function' ? (
              <Popconfirm
                title={'Tem certeza que deseja excluir este registro?'}
                onConfirm={(event) => {
                  onDelete(record, () => {
                    notification.success({ message: 'Registro excluído com sucesso!' })
                  })
                  event.stopPropagation()
                  event.preventDefault()
                }}
              >
                <Button
                  type={'link'}
                  onClick={(event) => {
                    event.stopPropagation()
                    event.preventDefault()
                  }}
                  icon={<DeleteIcon />}
                />
              </Popconfirm>
            ) : null}
          </Space>
        ),
      })
    }
    return columnsAux
  }, [columns, onEdit])
  const paginationAntd = useMemo<PaginationProps | false>(() => {
    if (pagination === false) {
      return false
    }
    return {
      per_page: pagination?.per_page || 10,
      page: pagination?.page || 1,
      current: pagination?.page || 1,
      pageSize: pagination?.per_page || 10,
      size: 'small',
      position: ['bottomCenter'],
      total: pagination?.total || 0,
      showSizeChanger: true,
      showTotal: (total: any, range: any) => `${range[0]}-${range[1]} de ${total} itens`,
      onChange: (page, pageSize) => {
        if (typeof loadData === 'function') {
          loadData({ ...filter, page: page, per_page: pageSize } as Partial<P> & { page: number; per_page: number })
        }
      },
    } as PaginationProps
  }, [pagination])

  return (
    <Table
      rowKey={'id'}
      {...props}
      onRow={(record) => ({
        onClick: (event) => {
          event.stopPropagation()
          event.preventDefault()
          typeof onEdit == 'function' && onEdit(record)
        },
      })}
      rowClassName={() => 'row-cursor-pointer'}
      columns={columnsFormatted}
      size={'small'}
      scroll={{ x: true }}
      pagination={paginationAntd}
    />
  )
}
