import React, { useContext, useEffect, useState } from 'react'
import { PageHeader } from '../../components/page-header/page-header'
import { Card, Col, Divider, Row } from 'antd'
import { useNavigate } from 'react-router-dom'
import { SelectAction } from 'rk-react-component'
import _ from 'lodash'
import { AppContext } from '../../contexts/app-context/app-context'
import { MeContext } from '../../contexts/me-context/me-context'
import { api } from '../../services/api'
import { DashboardMap } from './dashboard-map'

export const DashboardPage: React.FC = () => {
  const navigate = useNavigate()
  const { socket } = useContext(AppContext)
  const { role } = useContext(MeContext)
  const [isMounted, setIsMounted] = useState(false)
  const [drivers, setDrivers] = useState([])
  const [defaultCoords, setDefaultCoords] = useState({ lat: -22.908333, lng: -43.196388 })

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    setIsMounted(true)
    if (socket?.connected) {
      socket.on('drivers_online', ({ driver, car_track, supplier_id, company_id, region_id }) => {
        setDrivers((old) => {
          console.log(role?.role, role?.allow_model_id, company_id, region_id)
          const aux = _.mapKeys(old, 'driver_id')
          if (role?.role === 'MASTER' || role?.role === 'MANAGER_HEADQUARTER') {
            return Object.values({ ...aux, [driver?.id]: { driver, car_track, driver_id: driver.id } })
          }
          if (role?.role === 'MANAGER_SUPPLIER' && role?.allow_model_id === supplier_id) {
            return Object.values({ ...aux, [driver?.id]: { driver, car_track, driver_id: driver.id } })
          }
          if (role?.role === 'MANAGER_COMPANY' && role?.allow_model_id === company_id) {
            return Object.values({ ...aux, [driver?.id]: { driver, car_track, driver_id: driver.id } })
          }
          if (
            (role?.role === 'MANAGER_REGION' ||
              role?.role === 'MARKETING_REGION' ||
              role?.role === 'OPERATIONAL_REGION') &&
            role?.allow_model_id === region_id
          ) {
            return Object.values({ ...aux, [driver?.id]: { driver, car_track, driver_id: driver.id } })
          }
          return old
        })
      })
      socket.on('drivers_offline', (prop) => {
        setDrivers((old) => {
          return _.filter(old, (o) => o.socket_id !== prop)
        })
      })
    }
  }, [socket?.connected, isMounted, role])
  console.log('drivers', defaultCoords)
  return (
    <>
      <PageHeader title={'Dashboard'} onBack={() => navigate(-1)} />

      <Divider />
      <Card
        size={'small'}
        title={'Motoristas Online'}
        loading={!isMounted}
        extra={[
          <SelectAction
            style={{ minWidth: 200 }}
            allowClear={true}
            key={'select-companies'}
            api={api}
            onChange={(value) => {
              if (!value) return
              const record = JSON.parse(value)
              setDefaultCoords({ lat: record?.address?.latitude, lng: record?.address?.longitude })
            }}
            fieldSearch={'name_lk'}
            fieldValue={({ item }) => JSON.stringify(item, null, 2)}
            url={'/companies/with-coords'}
            placeholder={'Selecione uma Loja'}
          />,
        ]}
      >
        <Row>
          <Col span={24}>
            {isMounted && (
              <DashboardMap setDefaultCoords={setDefaultCoords} defaultCoords={defaultCoords} drivers={drivers} />
            )}
          </Col>
        </Row>
      </Card>
    </>
  )
}
