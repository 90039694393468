import { Tag } from 'antd'
import React from 'react'

type TypeStatusExecution = 'SCHEDULED' | 'ONGOING' | 'FINISHED'

interface TagStatusExecutionProps {
  status: TypeStatusExecution
}

export const TagStatusExecution = ({ status }: TagStatusExecutionProps) => {
  const config = {
    SCHEDULED: { label: 'Agendado', color: 'yellow' },
    ONGOING: { label: 'Em Andamento', color: 'blue' },
    FINISHED: { label: 'Finalizada', color: 'green' },
    CANCELLED: { label: 'Cancelado', color: 'red' },
  }

  return <Tag color={config[status]?.color || 'red'}>{config[status]?.label || status}</Tag>
}
