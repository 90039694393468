import React, { useEffect, useMemo, useState } from 'react'
import { PageHeader } from '../../../components/page-header/page-header'
import { useNavigate } from 'react-router-dom'
import { api } from '../../../services/api'
import moment from 'moment'
import _ from 'lodash'
import { Button, Card, Space, Spin, Table, Tag, Typography } from 'antd'
import { FaCheck, FaSearch } from 'react-icons/fa'
import { DatePickerMoment } from '../../../components/date-picke-moment/date-picker-moment'
import { createArrDaysMonth, createMonthColumns } from '../../../utils/create-month-columns'

export const ReportMonthPage = () => {
  const navigate = useNavigate()
  const [insertions, setInsertions] = useState([])
  const [loading, setLoading] = useState(false)
  const [dateFilter, setDateFilter] = useState(moment().startOf('month'))

  useEffect(() => {
    loadReport()
  }, [])
  const loadReport = () => {
    setLoading(true)
    api
      .get('/reports/month', {
        params: {
          date: dateFilter.endOf('month').toISOString(),
        },
      })
      .then(({ data }) => {
        setInsertions(data?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const columnsPeriod = useMemo(() => {
    return createMonthColumns((val, record, key) => {
      if (
        record?.orders?.[0] &&
        record?.orders?.[0]?.executions.filter((i) => moment(i?.date).format('DD') === key)?.length
      ) {
        return <FaCheck />
      }
      return ''
    })
  }, [insertions])

  return (
    <Spin spinning={loading} style={{ minHeight: '100vh' }}>
      <PageHeader
        onBack={() => navigate(-1)}
        title={'Relatório Mensal'}
        extra={[
          <DatePickerMoment.MonthPicker
            value={dateFilter}
            format={'MMM/YYYY'}
            onChange={(d) => {
              setDateFilter(d)
            }}
          />,
          <Button
            onClick={() => {
              loadReport()
            }}
          >
            <FaSearch />
          </Button>,
        ]}
      />
      <Space direction={'vertical'}>
        {insertions?.length && !loading
          ? insertions.map((p: any) => {
              return (
                <>
                  <Tag style={{ marginTop: 12 }}>
                    <Typography.Title
                      level={2}
                      style={{
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    >
                      {p?.region_parent?.name}
                    </Typography.Title>
                  </Tag>
                  {p?.regions?.map((dataSource) => (
                    <Card title={dataSource[0]?.customer?.region?.name}>
                      <Table
                        rowKey={'id'}
                        columns={[
                          {
                            dataIndex: 'company',
                            key: 'company',
                            title: 'Empresa',
                            ellipsis: true,
                            render: (_, record: any) => record?.customer?.name,
                          },
                          {
                            dataIndex: 'campaign',
                            key: 'order',
                            title: 'Campanha',
                            ellipsis: true,
                            render: (_, record: any) => record?.orders?.[0]?.campaign,
                          },
                          ...columnsPeriod,
                        ]}
                        size={'small'}
                        scroll={{ x: true }}
                        dataSource={dataSource}
                        pagination={false}
                        summary={(data) => {
                          const total_table = data.reduce((total, d) => {
                            if (d?.orders?.length) {
                              return _.sumBy(d?.orders, (o: any) => o?.executions?.length) + total
                            }
                            return 0
                          }, 0)
                          return (
                            <Table.Summary.Row>
                              <Table.Summary.Cell index={0}>
                                <Typography.Text strong type={'secondary'}>
                                  Total
                                </Typography.Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={1}>
                                <Typography.Text strong type={'secondary'}>
                                  {total_table}
                                </Typography.Text>
                              </Table.Summary.Cell>
                              {createArrDaysMonth((start) => {
                                const key = start.format('DD')
                                const total = data.reduce((total, d) => {
                                  if (
                                    d?.orders?.[0] &&
                                    d?.orders?.[0]?.executions.filter((i) => moment(i?.date).format('DD') === key)
                                      ?.length
                                  ) {
                                    return total + 1
                                  }
                                  return 0
                                }, 0)

                                return (
                                  <Table.Summary.Cell index={+key + 1} align={'center'}>
                                    <Typography.Text strong type={'secondary'}>
                                      {total}
                                    </Typography.Text>
                                  </Table.Summary.Cell>
                                )
                              })}
                            </Table.Summary.Row>
                          )
                        }}
                      />
                    </Card>
                  ))}
                </>
              )
            })
          : null}
      </Space>
      {/*{(!formatRegion?.length && !loading) ? <Empty/> : null}*/}
    </Spin>
  )
}
