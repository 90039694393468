import React from 'react'
import { Button, Modal, Space } from 'antd'
import { useGetAllApi } from 'rk-react-component'
import { api } from '../../../services/api'
import { FormRoleUser } from '../../forms/form-role-user/form-role-user'
import { TableRoleUser } from '../../tables/table-role-user/table-role-user'

interface ModalAddRoleProps {
  fixParams: {
    role:
      | 'MANAGER_HEADQUARTER'
      | 'MANAGER_SUPPLIER'
      | 'MANAGER_REGION'
      | 'MANAGER_COMPANY'
      | 'MASTER'
      | 'DRIVER'
      | 'OPERATIONAL_REGION'
      | 'MARKETING_REGION'
      | 'TI_ASSAI'
    allow_model_type: 'HeadquarterModel' | 'SupplierModel' | 'RegionModel' | 'CompanyModel' | null
    allow_model_id: number | null
  }
}

const modalInitialState = { visible: false, data: null }

export const ModalAddRole = ({ fixParams }: ModalAddRoleProps) => {
  const [modal, setModal] = React.useState(modalInitialState)
  const { setDataList, remove, dataList, fetchDataList, pagination, loading } = useGetAllApi<any, any>({
    url: '/roles',
    fixParams,
    api,
  })
  return (
    <>
      <Space style={{ width: '100%' }} direction={'vertical'}>
        <Button data-testid="btn_new_model" type={'primary'} onClick={() => setModal({ visible: true, data: null })}>
          Adicionar novo Responsável
        </Button>
        <TableRoleUser
          loadData={fetchDataList}
          pagination={pagination}
          onDelete={remove}
          loading={loading}
          dataSource={dataList}
        />
      </Space>
      <Modal
        open={modal.visible}
        onCancel={() => setModal(modalInitialState)}
        footer={false}
        closeIcon={false}
        destroyOnClose={true}
      >
        <FormRoleUser
          fixParams={fixParams}
          onFinish={(data) => {
            setModal(modalInitialState)
            setDataList((old) => [...old, data])
          }}
        />
      </Modal>
    </>
  )
}
