import React from 'react'
import { Form, Input, notification, Upload } from 'antd'
import { FormContainer, FormItem, SelectAction } from 'rk-react-component'
import { api } from '../../../services/api'
import { DatePickerMoment } from '../../../components/date-picke-moment/date-picker-moment'
import { useNavigate } from 'react-router-dom'

interface FormSupplierProps {
  onFinish: (data: any) => void
  initialValues: Partial<any> | null
  dateCast?: string[]
}

export const FormImport = ({ initialValues, dateCast }: FormSupplierProps) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }
  return (
    <FormContainer
      dateCast={dateCast}
      name={'form_import'}
      initialValues={initialValues}
      form={form}
      onFinish={(data) => {
        const file = data?.file[0]?.originFileObj
        if (!file) return
        const formData = new FormData()
        formData.append('file', data?.file[0]?.originFileObj)
        formData.append('filename', data.filename)
        formData.append('region_id', data.region_id)
        formData.append('type', 'ImportPlanningJob')
        formData.append('date', data.date.format('YYYY-MM-DD'))
        setLoading(true)
        api
          .post('/imports', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(() => {
            navigate(`/imports`)
          })
          .catch((e) => {
            notification.error({
              message: 'Erro ao salvar',
              description: e?.response?.data?.message || 'Erro ao salvar',
            })
          })
          .finally(() => {
            setLoading(false)
          })
      }}
      loading={loading}
    >
      <FormItem
        label={'Arquivo'}
        name={'file'}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'O campo "Arquivo" é obrigatório.' }]}
      >
        <Upload listType="picture-card" beforeUpload={() => false} maxCount={1}>
          Carregar
        </Upload>
      </FormItem>
      <FormItem label={'Nome'} name={'filename'} rules={[{ required: true, message: 'O campo "Nome" é obrigatório.' }]}>
        <Input placeholder={'Nome'} data-testid={'filename'} />
      </FormItem>
      <FormItem
        label={'Região'}
        name={'region_id'}
        rules={[{ required: true, message: 'O campo "Região" é obrigatório.' }]}
      >
        <SelectAction api={api} url={'/regions'} />
      </FormItem>

      <FormItem
        label={'Período'}
        name={'date'}
        rules={[{ required: true, message: 'O campo "Período" é obrigatório.' }]}
      >
        <DatePickerMoment format={'MM/YYYY'} picker={'month'} />
      </FormItem>
    </FormContainer>
  )
}
