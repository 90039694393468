import React, { useContext, useEffect, useState } from 'react'
import { PageHeader } from '../../components/page-header/page-header'
import { Card, Col, Divider, Row, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { GoogleMapsItems, GoogleMapsMarkerComposedPoint } from 'rk-react-component'
import { OverlayView } from 'react-google-maps'
import _ from 'lodash'
import { AppContext } from '../../contexts/app-context/app-context'
import { MeContext } from '../../contexts/me-context/me-context'

export const DashboardSupplierPage: React.FC = () => {
  const navigate = useNavigate()
  const { socket } = useContext(AppContext)
  const { role } = useContext(MeContext)
  const [isMounted, setIsMounted] = useState(false)
  const [drivers, setDrivers] = useState([])
  const [driverSelected, setDriverSelected] = useState(null)

  useEffect(() => {
    setIsMounted(true)
    console.log(socket, 'socket')
    if (socket) {
      socket.on('drivers_online', ({ driver, car_track, supplier_id, company_id, region_id }) => {
        setDrivers((old) => {
          console.log(role?.allow_model_id, supplier_id)
          const aux = _.mapKeys(old, 'driver_id')
          if (role?.role === 'MASTER') {
            return Object.values({ ...aux, [driver?.id]: { driver, car_track, driver_id: driver.id } })
          }
          if (role?.role === 'MANAGER_SUPPLIER' && role?.allow_model_id === supplier_id) {
            return Object.values({ ...aux, [driver?.id]: { driver, car_track, driver_id: driver.id } })
          }
          if (role?.role === 'MANAGER_COMPANY' && role?.allow_model_id === company_id) {
            return Object.values({ ...aux, [driver?.id]: { driver, car_track, driver_id: driver.id } })
          }
          if (role?.role === 'MANAGER_REGION' && role?.allow_model_id === region_id) {
            return Object.values({ ...aux, [driver?.id]: { driver, car_track, driver_id: driver.id } })
          }
          return old
        })
      })
      socket.on('drivers_offline', (prop) => {
        setDrivers((old) => {
          return _.filter(old, (o) => o.socket_id !== prop)
        })
      })
    }
  }, [socket, role])
  return (
    <>
      <PageHeader title={'Dashboard'} onBack={() => navigate(-1)} />
      <Divider />
      <Card>
        <Typography.Title level={4}>Convide seus motoristas para plataforma!</Typography.Title>
        <Typography.Paragraph strong>Compartilhe o link abaixo</Typography.Paragraph>
        <Typography.Paragraph
          copyable
        >{`${process.env.REACT_APP_ADMIN_URL}/signup/driver/${role.allow_model_id}`}</Typography.Paragraph>
      </Card>
      <Divider />
      <Card size={'small'} title={'Motoristas Online'} loading={!isMounted}>
        <Row>
          <Col span={24}>
            {isMounted && (
              <GoogleMapsItems
                mapsProps={{
                  defaultCenter: {
                    lat: drivers?.[0]?.car_track?.latitude || -22.908333,
                    lng: drivers?.[0]?.car_track?.longitude || -43.196388,
                  },
                  defaultZoom: 13,
                }}
                items={drivers.map((d) => (
                  <>
                    <OverlayView
                      position={{ lat: d?.car_track?.latitude, lng: d?.car_track?.longitude }}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      getPixelPositionOffset={(width, height) => ({
                        x: -(width / 2),
                        y: -(height * 1.5),
                      })}
                    >
                      <div
                        style={{
                          background: `white`,
                          border: `1px solid #ccc`,
                          padding: '0px 8px 0px 8px',
                          borderRadius: 8,
                        }}
                      >
                        <h2>{d?.driver?.user?.name || 'Nome do motorista'}</h2>
                      </div>
                    </OverlayView>
                    <GoogleMapsMarkerComposedPoint
                      lat={d?.car_track?.latitude}
                      lng={d?.car_track?.longitude}
                      front={{
                        url: `${process.env.REACT_APP_API_URL}/static/media/icon-bordered.759cc95e8bb63616ba36.png`,
                        xOffset: 9,
                        yOffset: 33,
                        width: 18,
                        height: 18,
                      }}
                    />
                  </>
                ))}
              />
            )}
          </Col>
        </Row>
      </Card>
    </>
  )
}
