import React, { useState } from 'react'
import { Button, Modal, Tooltip } from 'antd'
import { api } from '../../services/api'

interface ButtonGetReportProps {
  url: string
  title: string
  disabled?: boolean
  tooltip?: string
}

export const ButtonGetReport = ({ url, title, disabled, tooltip }: ButtonGetReportProps) => {
  const [loading, setLoading] = useState(false)
  const [pdfFile, setPdfFile] = useState(null)
  const onClick = () => {
    setLoading(true)
    api
      .put(url, {}, { responseType: 'blob' })
      .then(({ data }) => {
        setPdfFile(URL.createObjectURL(data))
      })
      .catch(() => {})
      .finally(() => setLoading(false))
  }
  return (
    <>
      <Tooltip title={tooltip}>
        <Button type={'primary'} onClick={onClick} loading={loading} disabled={disabled}>
          {title}
        </Button>
      </Tooltip>
      <Modal open={pdfFile} onCancel={() => setPdfFile(null)} width={'90%'} onOk={() => setPdfFile(null)}>
        <iframe src={pdfFile} width={'100%'} height={800} style={{ margin: '0 auto', display: 'block' }} />
      </Modal>
    </>
  )
}
