import React from 'react'
import { FormFilterContainer, FormFilterItem } from 'rk-react-component'
import { Form, Input } from 'antd'

interface FormFilterRouteProps {
  onFinish: (data: Partial<any>) => void
}

export const FormFilterRoute = ({ onFinish }: FormFilterRouteProps) => {
  const [form] = Form.useForm()
  return (
    <FormFilterContainer formProps={{ onFinish }} form={form} hiddenShowMore={true}>
      {() => (
        <FormFilterItem name={'name'}>
          <Input placeholder={'Nome'} data-testid={'name'} />
        </FormFilterItem>
      )}
    </FormFilterContainer>
  )
}
