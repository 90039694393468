import { UserModel } from '../../@types/user'

export const mockDataUser: UserModel[] = [
  {
    id: 1,
    name: 'John Doe',
    nickname: 'johndoe',
    email: 'user@rockapps.com.br',
    active_role_id: 'abc123',
    document_cpf: '12345678900',
    document_rg: '987654321',
    mobile: '123-456-7890',
    phone: '987-654-3210',
  },
  {
    id: 2,
    name: 'Jane Smith',
    nickname: 'janesmith',
    email: 'user2@rockapps.com.br',
    active_role_id: 'def456',
    document_cpf: '98765432109',
    document_rg: '123456789',
    mobile: '555-123-4567',
    phone: '555-987-6543',
  },
  {
    id: 3,
    name: 'Alice Johnson',
    nickname: 'alicej',
    email: 'user3@rockapps.com.br',
    active_role_id: 'ghi789',
    document_cpf: '54321678901',
    document_rg: '987654321',
    mobile: '789-456-1230',
    phone: '321-654-9870',
  },
  {
    id: 4,
    name: 'Bob Williams',
    nickname: 'bobw',
    email: 'user4@rockapps.com.br',
    active_role_id: 'jkl012',
    document_cpf: '78965432109',
    document_rg: '123456789',
    mobile: '555-987-6543',
    phone: '555-123-4567',
  },
  {
    id: 5,
    name: 'Emma Davis',
    nickname: 'emmad',
    email: 'user5@rockapps.com.br',
    active_role_id: 'mno345',
    document_cpf: '54321987654',
    document_rg: '987654321',
    mobile: '321-987-6543',
    phone: '321-123-4567',
  },
  {
    id: 6,
    name: 'Michael Johnson',
    nickname: 'michaelj',
    email: 'user6@rockapps.com.br',
    active_role_id: 'pqr678',
    document_cpf: '78965432101',
    document_rg: '123456789',
    mobile: '555-654-3210',
    phone: '555-321-9876',
  },
]
