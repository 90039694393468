import React from 'react'
import { FormContainer, FormItem, InputPhone, useFormApi } from 'rk-react-component'
import { Form, Input } from 'antd'
import { api } from '../../../services/api'
import { notificationSuccessSave } from '../../../services/notification-success-save'
import { InputPasswordStrength } from '../../inputs/input-password/input-password-strength'

interface FormUserProps {
  onFinish: (data: any) => void
  initialValues?: Partial<any>
  onBack?: () => void
}

export const FormUser = ({ onFinish, initialValues, onBack }: FormUserProps) => {
  const [form] = Form.useForm()
  const { save, loading } = useFormApi<any, any>({
    url: '/users',
    id: initialValues?.id,
    api,
  })
  return (
    <FormContainer
      name={'form_user'}
      initialValues={initialValues}
      form={form}
      onFinish={async (data) => {
        save(
          {
            ...data,
            mobile: data?.mobile ? data.mobile.replace(/\D/g, '') : '',
          },
          (response) => {
            onFinish(response)
            notificationSuccessSave(!initialValues?.id)
          },
          (e) => console.warn(e),
        )
      }}
      loading={loading}
      onBack={onBack}
    >
      <FormItem label={'Nome'} name={'name'}>
        <Input placeholder={'Nome'} data-testid={'name'} />
      </FormItem>
      <FormItem label={'Email'} name={'email'}>
        <Input placeholder="Email" data-testid={'email'} />
      </FormItem>
      <FormItem label={'Celular'} name={'mobile'}>
        <InputPhone placeholder={'Celular'} data-testid={'mobile'} />
      </FormItem>
      {!initialValues?.id ? <InputPasswordStrength /> : null}
    </FormContainer>
  )
}
