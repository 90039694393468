import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { MainLayout } from './main-layout'
import { MeContext } from '../contexts/me-context/me-context'
import 'moment/locale/pt-br'
import { ChatContext } from '../contexts/chat-context/chat-context'
import { AppContext } from '../contexts/app-context/app-context'
import { notification } from 'antd'
import { io } from 'socket.io-client'
import { ChatIcon } from './icon-modules'
import { configProject } from '../config-project'
import { FilterContext } from 'rk-react-component'
import { api } from '../services/api'

function App() {
  const [user, setUser] = useState(null)
  const [unreadMessage, setUnreadMessage] = useState(0)
  const [chatRooms, setChatRooms] = useState<any[]>([])
  const [chatSelected, setChatSelected] = useState()
  const [socketState, setSocketState] = useState<any>()
  const [role, setRole] = useState(null)
  const [ready, setReady] = useState(false)
  const [filter, setFilter] = useState({
    masterExecutionIndex: {},
  })
  useEffect(() => {
    const userAux = localStorage.getItem('user')
    setUser(userAux ? JSON.parse(userAux) : JSON.stringify({ id: null }))
    const roleAux = localStorage.getItem('active_role')
    setRole(roleAux ? JSON.parse(roleAux) : JSON.stringify({ id: null }))
    setReady(true)
  }, [])

  useEffect(() => {
    if (user?.id) {
      const socket = io(process.env.REACT_APP_API_URL)
      api.get('/count-unread-messages').then(({ data: dataResponse }) => {
        setUnreadMessage(dataResponse?.data)
      })
      setSocketState(socket)
    } else if (socketState) {
      socketState.disconnect()
    }
  }, [user])
  useEffect(() => {
    if (socketState && user?.id) {
      socketState.on(`Chat-User:${user?.id}`, ({ chatRoom: { lastMessage } }) => {
        setUnreadMessage((prev) => prev + 1 || 1)
        notification.info({
          message: lastMessage?.sender?.name,
          description: lastMessage?.message,
          duration: 5,
          icon: <ChatIcon color={configProject.colorPrimary} />,
        })
      })
    }
    return () => {
      if (socketState) {
        socketState.off(`Chat-User:${user?.id}`)
      }
    }
  }, [user, socketState])

  if (!ready) return null
  return (
    <AppContext.Provider value={{ socket: socketState, setSocket: setSocketState }}>
      <ChatContext.Provider value={{ chatRooms, setChatRooms, chatSelected, setChatSelected }}>
        <MeContext.Provider
          value={{ role, me: user, setRole, setMe: setUser, ready, setReady, unreadMessage, setUnreadMessage }}
        >
          <FilterContext.Provider value={{ filter, setFilter }}>
            <BrowserRouter>
              <MainLayout me={user} role={role} style={{}} />
            </BrowserRouter>
          </FilterContext.Provider>
        </MeContext.Provider>
      </ChatContext.Provider>
    </AppContext.Provider>
  )
}

export default App
