import React from 'react'
import { Form, InputNumber } from 'antd'
import { ExecutionModel } from '../../../@types/execution'
import { FormContainer, FormItem, SelectAction, useFormApi } from 'rk-react-component'
import { api } from '../../../services/api'
import { DatePickerComponent } from '../../inputs/date-picker-component/date-picker-component'
import { SelectDriver } from '../../inputs/select-driver/select-driver'
import { SelectCompany } from '../../inputs/select-company/select-company'

interface FormExecutionProps {
  onFinish: (data: ExecutionModel) => void
  initialValues: Partial<ExecutionModel> | null
  dateCast?: string[]
  onBack?: () => void
  fixParams?: Record<string, any>
}

export const FormExecutionMaster = ({ onFinish, initialValues, dateCast, onBack, fixParams }: FormExecutionProps) => {
  const [form] = Form.useForm()
  const supplierId = Form.useWatch('supplier_id', form)
  const { save, loading } = useFormApi<any, any>({
    url: '/executions-master',
    id: initialValues?.id,
    api,
    fixParams,
  })

  return (
    <FormContainer
      onBack={onBack}
      dateCast={dateCast}
      name={'form_execution'}
      initialValues={initialValues}
      form={form}
      onValuesChange={(values) => {
        if (values?.supplier_id) {
          form.setFieldsValue({ driver_id: undefined })
        }
      }}
      onFinish={(data) => {
        save(
          data,
          (response) => {
            onFinish(response)
          },
          (e) => console.warn(e),
        )
      }}
      loading={loading}
    >
      <FormItem label={'Data'} name={'date'} rules={[{ required: true, message: 'O campo Data é Obrigatório' }]}>
        <DatePickerComponent style={{ width: '100%' }} />
      </FormItem>
      <FormItem label={'Loja'} name={'customer_id'} rules={[{ required: true, message: 'O campo Loja é Obrigatório' }]}>
        <SelectCompany api={api} style={{ width: '100%' }} />
      </FormItem>
      <FormItem label={'Fornecedor'} name={'supplier_id'}>
        <SelectAction api={api} url={'/suppliers'} fieldSearch={'name_lk'} />
      </FormItem>
      <FormItem label={'Motorista'} name={'driver_id'}>
        <SelectDriver
          api={api}
          style={{ width: '100%' }}
          disabled={!supplierId}
          fixParams={{ supplier_id: supplierId }}
        />
      </FormItem>
      <FormItem
        label={'Horas'}
        name={'expected_hours'}
        rules={[{ required: true, message: 'O campo Horas é Obrigatório' }]}
      >
        <InputNumber />
      </FormItem>
    </FormContainer>
  )
}
