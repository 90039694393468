import React from 'react'
import { Route, Routes } from 'react-router-dom'
import {
  chat,
  company,
  dashboard,
  dashboard_supplier,
  driver,
  execution,
  headquarter,
  import_file,
  master,
  master_execution,
  region,
  region_executions,
  report_company,
  report_headquarter,
  report_master,
  report_region,
  reportSupplier,
  route,
  supplier,
  supplier_execution,
  user,
  user_ti,
} from './routes-modules'
import { HeadquarterFormPage } from '../pages/headquarter/headquarter-form-page/headquarter-form-page'
import { ManagerRegionFormPage } from '../pages/manager-region/manager-region-form-page/manager-region-form-page'
import { CompanyFormPage } from '../pages/company/company-form-page/company-form-page'
import { UserFormPage } from '../pages/user/user-form-page/user-form-page'
import { MyProfileFormPage } from '../pages/my-profile/my-profile-form-page'
import { ManagerSupplierFormPage } from '../pages/manager-supplier/manager-supplier-form-page/manager-supplier-form-page'

export const routesMaster = () => {
  return {
    location: [
      dashboard.location,
      master.location,
      user.location,
      report_master.location,
      headquarter.location,
      region.location,
      company.location,
      supplier.location,
      master_execution.location,
      import_file.location,
      chat.location,
    ],
    routes: (
      <Routes>
        <Route path={'/my-profile/:id'} element={<UserFormPage />} />
        {dashboard.routes}
        {master.routes}
        {user.routes}
        {report_master.routes}
        {headquarter.routes}
        {region.routes}
        {company.routes}
        {supplier.routes}
        {execution.routes}
        {master_execution.routes}
        {import_file.routes}
        {chat.routes}
      </Routes>
    ),
  }
}

export const routesHeadquarterManager = () => {
  return {
    location: [
      dashboard.location,
      user_ti.location,
      region.location,
      company.location,
      route.location,
      report_headquarter.location,
      chat.location,
    ],
    routes: (
      <Routes>
        <Route path={'/my-headquarter/:id'} element={<HeadquarterFormPage />} />
        <Route path={'/my-profile/:id'} element={<MyProfileFormPage />} />
        {dashboard.routes}
        {company.routes}
        {user_ti.routes}
        {execution.routes}
        {region.routes}
        {route.routes}
        {chat.routes}
        {report_headquarter.routes}
      </Routes>
    ),
  }
}

export const routesRegionManager = () => {
  return {
    location: [
      dashboard.location,
      company.location,
      region_executions.location,
      route.location,
      report_region.location,
      chat.location,
    ],
    routes: (
      <Routes>
        <Route path={'/my-region/:id'} element={<ManagerRegionFormPage />} />
        <Route path={'/my-profile/:id'} element={<MyProfileFormPage />} />
        {dashboard.routes}
        {company.routes}
        {execution.routes}
        {region_executions.routes}
        {region.routes}
        {report_region.routes}
        {route.routes}
        {chat.routes}
      </Routes>
    ),
  }
}

export const routesRegionOperational = () => {
  return {
    location: [
      dashboard.location,
      company.location,
      region_executions.location,
      route.location,
      report_region.location,
      chat.location,
    ],
    routes: (
      <Routes>
        <Route path={'/my-region/:id'} element={<ManagerRegionFormPage />} />
        <Route path={'/my-profile/:id'} element={<MyProfileFormPage />} />
        {dashboard.routes}
        {company.routes}
        {region_executions.routes}
        {execution.routes}
        {region.routes}
        {report_region.routes}
        {route.routes}
        {chat.routes}
      </Routes>
    ),
  }
}

export const routesRegionMarketing = () => {
  return {
    location: [
      dashboard.location,
      company.location,
      region_executions.location,
      route.location,
      report_region.location,
      chat.location,
    ],
    routes: (
      <Routes>
        <Route path={'/my-region/:id'} element={<ManagerRegionFormPage />} />
        <Route path={'/my-profile/:id'} element={<MyProfileFormPage />} />
        {dashboard.routes}
        {company.routes}
        {region_executions.routes}
        {execution.routes}
        {region.routes}
        {report_region.routes}
        {route.routes}
        {chat.routes}
      </Routes>
    ),
  }
}

export const routesSupplier = () => {
  return {
    location: [
      dashboard_supplier.location,
      reportSupplier.location,
      driver.location,
      supplier_execution.location,
      chat.location,
    ],
    routes: (
      <Routes>
        <Route path={'/my-supplier/:id'} element={<ManagerSupplierFormPage />} />
        <Route path={'/my-profile/:id'} element={<MyProfileFormPage />} />
        {dashboard_supplier.routes}
        {driver.routes}
        {reportSupplier.routes}
        {execution.routes}
        {supplier_execution.routes}
        {chat.routes}
      </Routes>
    ),
  }
}

export const routesCompanyManager = () => {
  return {
    location: [dashboard.location, report_company.location, route.location, chat.location],
    routes: (
      <Routes>
        <Route path={'/my-company/:id'} element={<CompanyFormPage />} />
        <Route path={'/my-profile/:id'} element={<MyProfileFormPage />} />
        {dashboard.routes}
        {report_company.routes}
        {route.routes}
        {execution.routes}
        {chat.routes}
      </Routes>
    ),
  }
}

export const routesTIAssai = () => {
  return {
    location: [user_ti.location, chat.location],
    routes: (
      <Routes>
        <Route path={'/my-profile/:id'} element={<MyProfileFormPage />} />
        {user_ti.routes}
        {chat.routes}
      </Routes>
    ),
  }
}

export const routesDefault = () => {
  return {
    location: [],
    routes: <Routes />,
  }
}
