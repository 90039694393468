import React, { useEffect } from 'react'
import { useGetAllApi } from 'rk-react-component'
import { TableUser } from '../../../containers/tables/table-user/table-user'
import { PageIndexContainer } from '../../../containers/pages/page-index-container/page-index-container'
import { FormFilterUser } from '../../../containers/form-filter/form-filter-user/form-filter-user'
import { api } from '../../../services/api'
import { useNavigate } from 'react-router-dom'

export const UserIndexPage = () => {
  const navigate = useNavigate()
  const { dataList, loading, fetchDataList, pagination, remove, filter } = useGetAllApi<any, any>({
    url: '/users',
    api,
    filterKey: 'userIndexPage',
    initialRequest: false,
  })
  useEffect(() => {
    fetchDataList({ page: 1, per_page: 10, ...filter })
  }, [])
  return (
    <PageIndexContainer
      title={'Usuários'}
      btnTitle={'Novo Usuário'}
      url={'/users'}
      FormFilterComponent={<FormFilterUser onFinish={fetchDataList} initialValues={filter} />}
      TableComponent={() => (
        <TableUser
          onDelete={remove}
          onEdit={(record) => navigate('/users/' + record.id)}
          loadData={(params) => fetchDataList({ ...filter, ...params })}
          pagination={pagination}
          dataSource={dataList}
          loading={loading}
        />
      )}
    />
  )
}
