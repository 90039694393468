import React, { useContext } from 'react'
import './style.css'
import { TableContainer, TableContainerProps } from '../table-container/table-container'
import { useNavigate } from 'react-router-dom'
import { MeContext } from '../../../contexts/me-context/me-context'

export const TableCompany = ({ dataSource, loading, pagination, loadData }: TableContainerProps<any, any>) => {
  const navigate = useNavigate()
  const { role } = useContext(MeContext)
  return (
    <TableContainer
      loadData={loadData}
      pagination={pagination}
      id={'table_company'}
      loading={loading}
      onEdit={role?.role === 'MASTER' ? (record) => navigate(`/companies/${record?.id}`) : undefined}
      columns={[
        { dataIndex: 'name', key: 'name', title: 'Nome' },
        { dataIndex: 'code', key: 'code', title: 'Cód.' },
        {
          dataIndex: 'headquarter',
          key: 'headquarter',
          title: 'Sede',
          render: (val) => {
            return val?.name
          },
        },
        { dataIndex: 'region', key: 'region', title: 'Região', render: (val) => val?.name },
      ]}
      dataSource={dataSource}
    />
  )
}
