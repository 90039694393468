import React from 'react'
import { FormFilterContainer, FormFilterItem } from 'rk-react-component'
import { Form, Select, Switch } from 'antd'
import { DatePickerComponent } from '../../inputs/date-picker-component/date-picker-component'
import { SelectDriver } from '../../inputs/select-driver/select-driver'
import { SelectCompany } from '../../inputs/select-company/select-company'
import { SelectSupplier } from '../../inputs/select-supplier/select-supplier'
import { SelectRegion } from '../../inputs/select-region/select-region'

interface FormFilterSupplierProps {
  onFinish: (data: Partial<any> & { page: number; per_page: number }) => void
  initialValues?: any
}

export const FormFilterMasterExecution = ({ onFinish, initialValues }: FormFilterSupplierProps) => {
  const [form] = Form.useForm()
  return (
    <FormFilterContainer
      formProps={{
        name: 'form_filter_master_execution',
        initialValues: initialValues,
        onFinish: (data) => {
          onFinish({
            ...data,
            period: data?.period ? data?.period.format('YYYY-MM-DD') : undefined,
            date: data?.date ? data?.date.format('YYYY-MM-DD') : undefined,
            driver_id_is_null: data?.driver_id_is_null || undefined,
            supplier_id_is_null: data?.supplier_id_is_null || undefined,
            page: 1,
            per_page: 10,
          })
        },
      }}
      dateCast={['period', 'date']}
      form={form}
      hiddenShowMore={true}
    >
      {() => (
        <>
          <FormFilterItem name={'region_id'}>
            <SelectRegion allowClear={true} style={{ minWidth: 200 }} placeholder={'Hub'} data-testid={'region_id'} />
          </FormFilterItem>
          <FormFilterItem name={'customer_id'}>
            <SelectCompany
              allowClear={true}
              style={{ minWidth: 200 }}
              placeholder={'Loja'}
              data-testid={'customer_id'}
            />
          </FormFilterItem>
          <FormFilterItem name={'supplier_id'}>
            <SelectSupplier
              allowClear={true}
              style={{ minWidth: 200 }}
              placeholder={'Fornecedor'}
              data-testid={'supplier_id'}
            />
          </FormFilterItem>
          <FormFilterItem name={'period'}>
            <DatePickerComponent
              format={'MM/YYYY'}
              data-testid={'period'}
              style={{ minWidth: 200 }}
              allowClear={true}
              picker={'month'}
              placeholder={'Selecione o Período'}
            />
          </FormFilterItem>
          <FormFilterItem name={'date'}>
            <DatePickerComponent
              format={'DD/MM/YYYY'}
              style={{ minWidth: 200 }}
              allowClear={true}
              data-testid={'date'}
            />
          </FormFilterItem>
          <FormFilterItem name={'status'}>
            <Select
              allowClear={true}
              placeholder={'Status'}
              data-testid={'status'}
              style={{ minWidth: 150 }}
              options={[
                { label: 'Finalizado', value: 'FINISHED' },
                { label: 'Agendado', value: 'SCHEDULED' },
                { label: 'Em Andamento', value: 'ONGOING' },
              ]}
            />
          </FormFilterItem>
          <FormFilterItem name={'driver_id'}>
            <SelectDriver
              placeholder={'Motorista'}
              style={{ minWidth: 200 }}
              allowClear={true}
              data-testid={'driver_id'}
            />
          </FormFilterItem>
          <FormFilterItem name={'supplier_id_is_null'} valuePropName={'checked'} label={'Sem Fornecedor'}>
            <Switch data-testid={'supplier_id_is_null'} />
          </FormFilterItem>
          <FormFilterItem name={'driver_id_is_null'} valuePropName={'checked'} label={'Sem Motorista'}>
            <Switch data-testid={'driver_id_is_null'} />
          </FormFilterItem>
        </>
      )}
    </FormFilterContainer>
  )
}
