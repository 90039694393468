import React from 'react'
import { FormFilterContainer, FormFilterItem, SelectAction } from 'rk-react-component'
import { Form, Select, Switch } from 'antd'
import { DatePickerComponent } from '../../inputs/date-picker-component/date-picker-component'
import { api } from '../../../services/api'

interface FormFilterSupplierProps {
  onFinish: (data: Partial<any> & { page: number; per_page: number }) => void
}

export const FormFilterRegionExecution = ({ onFinish }: FormFilterSupplierProps) => {
  const [form] = Form.useForm()
  return (
    <FormFilterContainer
      formProps={{
        onFinish: (data) => {
          onFinish({
            ...data,
            period: data?.period ? data?.period.format('YYYY-MM-DD') : undefined,
            date: data?.date ? data?.date.format('YYYY-MM-DD') : undefined,
            driver_id_is_null: data?.driver_id_is_null || undefined,
            supplier_id_is_null: data?.supplier_id_is_null || undefined,
            page: 1,
            per_page: 10,
          })
        },
      }}
      form={form}
      hiddenShowMore={true}
    >
      {() => (
        <>
          <FormFilterItem name={'customer_id'}>
            <SelectAction
              allowClear={true}
              style={{ minWidth: 200 }}
              placeholder={'Loja'}
              data-testid={'customer_id'}
              fieldSearch={'name_lk'}
              api={api}
              url={'/companies-select'}
            />
          </FormFilterItem>
          <FormFilterItem name={'period'}>
            <DatePickerComponent
              format={'MM/YYYY'}
              data-testid={'period'}
              style={{ minWidth: 200 }}
              allowClear={true}
              picker={'month'}
              placeholder={'Selecione o Período'}
            />
          </FormFilterItem>
          <FormFilterItem name={'date'}>
            <DatePickerComponent
              format={'DD/MM/YYYY'}
              style={{ minWidth: 200 }}
              allowClear={true}
              data-testid={'date'}
            />
          </FormFilterItem>
          <FormFilterItem name={'status'}>
            <Select
              allowClear={true}
              placeholder={'Status'}
              data-testid={'status'}
              style={{ minWidth: 150 }}
              options={[
                { label: 'Finalizado', value: 'FINISHED' },
                { label: 'Agendado', value: 'SCHEDULED' },
                { label: 'Em Andamento', value: 'ONGOING' },
              ]}
            />
          </FormFilterItem>
          <FormFilterItem name={'supplier_id_is_null'} valuePropName={'checked'} label={'Sem Fornecedor'}>
            <Switch data-testid={'supplier_id_is_null'} />
          </FormFilterItem>
          <FormFilterItem name={'driver_id_is_null'} valuePropName={'checked'} label={'Sem Motorista'}>
            <Switch data-testid={'driver_id_is_null'} />
          </FormFilterItem>
        </>
      )}
    </FormFilterContainer>
  )
}
