import { Typography } from 'antd'
import React from 'react'
import { TableContainer, TableContainerProps } from '../table-container/table-container'

export const TableUserTi = ({ dataSource, loading, loadData, pagination, onEdit }: TableContainerProps<any, any>) => {
  return (
    <TableContainer
      loadData={loadData}
      onEdit={onEdit}
      pagination={pagination}
      id={'table_user'}
      loading={loading}
      columns={[
        {
          key: 'name',
          dataIndex: 'name',
          title: 'Nome',
          render: (val, record) => <Typography.Text strong>{val}</Typography.Text>,
        },
        {
          key: 'email',
          dataIndex: 'email',
          title: 'E-mail',
          render: (val) => <Typography.Text strong>{val}</Typography.Text>,
        },
      ]}
      dataSource={dataSource}
    />
  )
}
