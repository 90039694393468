import React, { useEffect, useState } from 'react'
import { useGetAllApi } from 'rk-react-component'
import { PageIndexContainer } from '../../../containers/pages/page-index-container/page-index-container'
import { api } from '../../../services/api'
import { TableMasterExecution } from '../../../containers/tables/table-master-execution/table-master-execution'
import { FormFilterMasterExecution } from '../../../containers/form-filter/form-filter-master-execution/form-filter-master-execution'
import { Button, Modal } from 'antd'
import { FormExecutionMaster } from '../../../containers/forms/form-execution-master/form-execution-master'

export const MasterExecutionIndexPage = () => {
  const [showModal, setShowModal] = useState(false)
  const { dataList, fetchDataList, loading, pagination, remove, setDataList, filter } = useGetAllApi<any, any>({
    url: '/executions-list',
    filterKey: 'masterExecutionIndexPage',
    api,
    initialRequest: false,
  })
  console.log(filter)
  useEffect(() => {
    fetchDataList({ per_page: 10, page: 1, ...filter })
  }, [])
  return (
    <>
      <PageIndexContainer
        title={'Execuções'}
        extra={[
          <Button
            type={'primary'}
            onClick={() => {
              setShowModal(true)
            }}
          >
            Nova Execução
          </Button>,
        ]}
        url={'/executions-list'}
        TableComponent={() => (
          <TableMasterExecution
            filter={filter}
            pagination={pagination}
            loadData={(data) => fetchDataList({ ...filter, ...data })}
            dataSource={dataList}
            loading={loading}
            onDelete={remove}
            setDataList={setDataList}
          />
        )}
        FormFilterComponent={
          <FormFilterMasterExecution onFinish={(data) => fetchDataList(data)} initialValues={filter} />
        }
      />
      <Modal
        destroyOnClose={true}
        footer={false}
        title={'Nova Execução'}
        open={showModal}
        onCancel={() => {
          setShowModal(false)
        }}
      >
        <FormExecutionMaster
          onFinish={() => {
            setShowModal(false)
            fetchDataList(filter)
          }}
          initialValues={{}}
        />
      </Modal>
    </>
  )
}
