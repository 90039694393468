import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetOneApi } from 'rk-react-component'
import { FormUser } from '../../../containers/forms/form-user/form-user'
import { PageFormTabContainer } from '../../../containers/pages/page-form-tab-container/page-form-tab-container'
import { api } from '../../../services/api'
import { TabAddress } from '../../../containers/tabs/tab-address/tab-address'
import { FormPassword } from '../../../containers/forms/form-password/form-password'
import { TabUserDevices } from '../../../containers/tabs/tab-user-devices/tab-user-devices'

export const UserFormPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data: dataUser, loading: loadingUserGetOne } = useGetOneApi<any>({
    url: '/users',
    id,
    api,
  })

  return (
    <PageFormTabContainer
      title={'Usuário'}
      items={[
        {
          disabled: false,
          key: 'tab_user',
          label: 'Info Geral',
          loading: loadingUserGetOne,
          title: 'Novo Usuário',
          FormComponent: (
            <FormUser initialValues={dataUser} onFinish={(dataApi) => navigate(`/users/${dataApi?.id}`)} />
          ),
        },
        {
          disabled: false,
          key: 'tab_user_devices',
          label: 'Permissões (Mobile)',
          loading: loadingUserGetOne,
          title: 'Novo Usuário',
          FormComponent: <TabUserDevices userId={dataUser?.id} />,
        },
        {
          disabled: !dataUser?.id,
          key: 'tab_address',
          label: 'Endereço',
          loading: false,
          title: 'Novo Endereço',
          FormComponent: <TabAddress modelId={dataUser?.id} modelType={'UsersModel'} />,
        },
        {
          disabled: !dataUser?.id,
          key: 'tab_password',
          label: 'Senha',
          loading: false,
          title: 'Trocar Senha',
          FormComponent: <FormPassword onFinish={() => {}} userId={dataUser?.id} />,
        },
      ]}
    />
  )
}
