import React from 'react'
import { api } from '../../services/api'
import { Spin, Tooltip, Typography } from 'antd'
import { AddIcon, EditIcon, InfoIcon } from '../../main/icon-modules'
import { SelectDriver } from '../../containers/inputs/select-driver/select-driver'

interface SupplierSelectDriverProps {
  driver: any
  executionId: number
  updateRecord: any
  disabled?: boolean
  fixParams?: any
}

export const SupplierSelectDriver = ({
  driver,
  executionId,
  updateRecord,
  disabled,
  fixParams,
}: SupplierSelectDriverProps) => {
  const [loading, setLoading] = React.useState(false)
  const [value, setValue] = React.useState(driver?.id)
  const [showSelect, setShowSelect] = React.useState(false)
  if (loading) {
    return (
      <div style={{ display: 'flex', minWidth: 150, justifyContent: 'center', alignItems: 'center' }}>
        <Spin spinning={true} size={'small'} />
      </div>
    )
  }
  if (!showSelect) {
    return value ? (
      <Typography.Paragraph
        disabled={disabled}
        style={{ marginBottom: 0, minWidth: 150, cursor: 'pointer' }}
        onClick={() => setShowSelect(!disabled)}
        strong
      >
        {driver?.user?.name} <EditIcon size={12} style={{ marginLeft: 12 }} />{' '}
      </Typography.Paragraph>
    ) : (
      <Typography.Paragraph
        disabled={disabled}
        type={disabled ? 'secondary' : 'danger'}
        style={{ marginBottom: 0, minWidth: 150, cursor: 'pointer' }}
        onClick={() => setShowSelect(!disabled)}
        strong
      >
        Sem Motorista{' '}
        {disabled ? (
          <Tooltip title={'É necessário atribuir o fornecedor primeiro'}>
            <InfoIcon />
          </Tooltip>
        ) : (
          <AddIcon size={12} style={{ marginLeft: 12 }} />
        )}
      </Typography.Paragraph>
    )
  }

  return (
    <div>
      <SelectDriver
        loading={loading}
        value={driver?.id}
        data-testid={'supplier_select_driver'}
        defaultOptions={driver ? [driver] : []}
        fixParams={fixParams}
        onChange={(val) => {
          setLoading(true)
          setValue(val)
          api
            .put('/executions-list/' + executionId, { driver_id: val })
            .then(({ data }) => {
              updateRecord(data?.data)
            })
            .finally(() => {
              setLoading(false)
            })
        }}
        style={{ width: 150 }}
        placeholder={'Selecione um motorista'}
      />
    </div>
  )
}
