import React, { useContext, useState } from 'react'
import { Button, Divider, Form, Image, Input, notification, Row, Select } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { FormItem, InputCnpj, InputCpf, InputPhone } from 'rk-react-component'
import { api } from '../../services/api'
import { MeContext } from '../../contexts/me-context/me-context'
import { InputPasswordStrength } from '../../containers/inputs/input-password/input-password-strength'

export const SignupSupplier = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { setMe, setRole } = useContext(MeContext)
  const [loading, setLoading] = useState(false)
  const type = Form.useWatch('type', form)
  return (
    <div
      style={{
        backgroundColor: 'white',
        height: 'calc(100vh)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <div style={{ display: 'flex', flex: 1 }} />
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center' }}>
        <span style={{ width: 400 }}>
          <Row justify={'center'}>
            <Image src={require('../../assets/logo-with-icon-orange.png')} preview={false} style={{ maxWidth: 240 }} />
            <Divider />
          </Row>
          <Form
            size={'large'}
            form={form}
            initialValues={{ type: 'PF' }}
            onFinish={(data) => {
              console.log(data)
              if (data.password !== data.password_confirmation) {
                notification.error({ message: 'As senhas não conferem' })
                return
              }
              setLoading(true)
              api
                .post('/sign-up/supplier', data)
                .then(({ data: dataUser }) => {
                  localStorage.setItem('user', JSON.stringify(dataUser))
                  setMe(dataUser)
                  localStorage.setItem('active_role', JSON.stringify(dataUser.active_role))
                  setRole(dataUser.active_role)
                  navigate('/dashboard')
                })
                .catch((e) => {
                  console.warn(e)
                })
                .finally(() => {
                  setLoading(false)
                })
            }}
          >
            <FormItem name="name" rules={[{ required: true, message: 'O campo "Nome" é obrigatório.' }]}>
              <Input placeholder={'Nome'}></Input>
            </FormItem>
            <FormItem name="email" rules={[{ required: true, message: 'O campo "Email" é obrigatório.' }]}>
              <Input placeholder={'Email'}></Input>
            </FormItem>
            <FormItem name="mobile" rules={[{ required: true, message: 'O campo "Telefone" é obrigatório.' }]}>
              <InputPhone placeholder={'Telefone'}></InputPhone>
            </FormItem>
            <FormItem name={'type'} rules={[{ required: true, message: 'O campo "Tipo" é obrigatório.' }]}>
              <Select
                placeholder={'Selecione o tipo de cadastro'}
                options={[
                  { label: 'Pessoa Física', value: 'PF' },
                  { label: 'Pessoa Jurídica', value: 'PJ' },
                ]}
              />
            </FormItem>
            <FormItem name="document" rules={[{ required: true, message: 'O campo "Documento" é obrigatório.' }]}>
              {type === 'PJ' ? <InputCnpj placeholder={'CNPJ'} /> : <InputCpf placeholder={'CPF'} />}
            </FormItem>
            {type === 'PJ' ? (
              <FormItem
                name={'fantasy_name'}
                rules={[{ required: true, message: 'O campo "Nome Fantasia" é obrigatório.' }]}
              >
                <Input placeholder={'Nome Fantasia'}></Input>
              </FormItem>
            ) : null}
            {type === 'PJ' ? (
              <FormItem
                name={'razao_social'}
                rules={[{ required: true, message: 'O campo "Razão Social" é obrigatório.' }]}
              >
                <Input placeholder={'Razão Social'}></Input>
              </FormItem>
            ) : null}
            <InputPasswordStrength placeholder={'Senha'} />
            <FormItem
              name="password_confirmation"
              rules={[{ required: true, message: 'O campo "Confirmação da senha" é obrigatório.' }]}
            >
              <Input.Password placeholder={'Confirmar Senha'} />
            </FormItem>
            <Button type={'primary'} htmlType={'submit'} block loading={loading}>
              Cadastrar
            </Button>
            <FormItem style={{ marginBottom: 16, justifyContent: 'flex-end', display: 'flex', flex: 1 }}>
              <Link to={'/signin'}>Já sou Cadastrado</Link>
            </FormItem>
          </Form>
        </span>
      </div>
      <div style={{ display: 'flex', flex: 2 }} />
    </div>
  )
}
