import React, { useEffect } from 'react'
import { useGetAllApi } from 'rk-react-component'
import { TableHeadquarter } from '../../../containers/tables/table-headquarter/table-headquarter'
import { PageIndexContainer } from '../../../containers/pages/page-index-container/page-index-container'
import { FormFilterHeadquarter } from '../../../containers/form-filter/form-filter-headquarter/form-filter-headquarter'
import { api } from '../../../services/api'

export const HeadquarterIndexPage = () => {
  const { dataList, fetchDataList, loading, pagination, filter } = useGetAllApi<any, any>({
    url: '/headquarters',
    api,
    initialRequest: false,
    filterKey: 'headquarterIndexPage',
  })

  useEffect(() => {
    fetchDataList({ per_page: 10, page: 1 })
  }, [])

  return (
    <PageIndexContainer
      title={'Sedes'}
      btnTitle={'Nova Sede'}
      url={'/headquarters'}
      TableComponent={() => (
        <TableHeadquarter
          dataSource={dataList}
          loading={loading}
          pagination={pagination}
          loadData={(params) => fetchDataList({ ...filter, params })}
        />
      )}
      FormFilterComponent={<FormFilterHeadquarter onFinish={fetchDataList} initialValues={filter} />}
    />
  )
}
