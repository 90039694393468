import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FormCompany } from '../../../containers/forms/form-company/form-company'
import { GetOneApiReturn, useGetOneApi } from 'rk-react-component'
import { CompanyModel } from '../../../@types/company'
import { api } from '../../../services/api'
import { PageFormTabContainer } from '../../../containers/pages/page-form-tab-container/page-form-tab-container'
import { ModalAddRole } from '../../../containers/modals/modal-add-role/modal-add-role'
import { TabAddress } from '../../../containers/tabs/tab-address/tab-address'

export const CompanyFormPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data, loading }: GetOneApiReturn<CompanyModel> = useGetOneApi({
    url: '/companies',
    id,
    api,
  })
  return (
    <PageFormTabContainer
      title={'Empresa'}
      items={[
        {
          FormComponent: (
            <FormCompany initialValues={data} onFinish={(dataApi) => navigate(`/companies/${dataApi?.id}`)} />
          ),
          disabled: false,
          key: '1',
          label: 'Informações',
          loading,
          title: 'Informações',
        },
        {
          disabled: !data?.id,
          key: 'tab_address',
          label: 'Endereço',
          loading: false,
          title: 'Novo Endereço',
          FormComponent: <TabAddress modelId={data?.id} modelType={'CompanyModel'} />,
        },
        {
          FormComponent: (
            <ModalAddRole
              fixParams={{
                allow_model_type: 'CompanyModel',
                allow_model_id: data?.id,
                role: 'MANAGER_COMPANY',
              }}
            />
          ),
          disabled: !data?.id,
          key: '2',
          label: 'Responsáveis',
          loading,
          title: 'Responsáveis',
        },
      ]}
    />
  )
}
