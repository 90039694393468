import React, { useContext } from 'react'
import { FormContainer, FormItem, SelectAction, useFormApi } from 'rk-react-component'
import { Form, Input } from 'antd'
import { RegionModel } from '../../../@types/region'
import { api } from '../../../services/api'
import { MeContext } from '../../../contexts/me-context/me-context'
import { notificationSuccessSave } from '../../../services/notification-success-save'

interface FormRegionProps {
  onFinish: (data: RegionModel) => void
  initialValues: RegionModel | null
  onBack?: () => void
}

export const FormRegion = ({ onBack, onFinish, initialValues }: FormRegionProps) => {
  const [form] = Form.useForm()
  const headquarterId = Form.useWatch('headquarter_id', form)
  const { role } = useContext(MeContext)
  const { save, loading } = useFormApi<any, any>({
    url: '/regions',
    id: initialValues?.id,
    api,
    fixParams: role.role !== 'MASTER' ? { headquarter_id: role.allow_model_id } : {},
  })
  return (
    <FormContainer
      onBack={onBack}
      name={'form_region'}
      initialValues={initialValues}
      form={form}
      hiddenActions={role.role !== 'MASTER'}
      onFinish={(data) => {
        save(
          data,
          (response) => {
            onFinish(response)
            notificationSuccessSave(!initialValues?.id)
          },
          (e) => console.warn(e),
        )
      }}
      loading={loading}
    >
      <FormItem label={'Nome'} name={'name'} rules={[{ required: true, message: 'O campo "Nome" é obrigatório.' }]}>
        <Input />
      </FormItem>
      <FormItem
        label={'Descrição'}
        name={'description'}
        rules={[{ required: true, message: 'O campo "Descrição" é obrigatório.' }]}
      >
        <Input.TextArea />
      </FormItem>
      {role.role === 'MASTER' && (
        <>
          <FormItem
            label={'Sede'}
            name={'headquarter_id'}
            rules={[{ required: true, message: 'O campo "Sede" é obrigatório.' }]}
          >
            <SelectAction api={api} url={'/headquarters'} fieldSearch={'name_lk'} />
          </FormItem>
          <FormItem label={'Região Pai'} name={'parent_id'}>
            <SelectAction
              api={api}
              fieldSearch={'name_lk'}
              url={'/regions'}
              fixParams={{
                headquarter_id: headquarterId,
                parent_id_is_null: true,
              }}
              disabled={!headquarterId}
            />
          </FormItem>
        </>
      )}
    </FormContainer>
  )
}
