import React, { useEffect } from 'react'
import { useGetAllApi } from 'rk-react-component'
import { PageIndexContainer } from '../../../containers/pages/page-index-container/page-index-container'
import { api } from '../../../services/api'
import { TableImport } from '../../../containers/tables/table-import/table-import'
import { FormFilterImport } from '../../../containers/form-filter/form-filter-import/form-filter-import'

export const ImportIndexPage = () => {
  const { dataList, fetchDataList, loading, pagination, remove } = useGetAllApi<any, any>({
    url: '/imports',
    fixParams: { order_by: { id: 'desc' } },
    api,
    initialRequest: false,
  })

  useEffect(() => {
    fetchDataList({ per_page: 10, page: 1 })
  }, [])
  return (
    <PageIndexContainer
      title={'Importações'}
      btnTitle={'Nova Importação'}
      url={'/imports'}
      TableComponent={() => (
        <TableImport
          pagination={pagination}
          loadData={fetchDataList}
          dataSource={dataList}
          loading={loading}
          onDelete={remove}
        />
      )}
      FormFilterComponent={
        <FormFilterImport
          onFinish={(data) => {
            fetchDataList(data)
          }}
        />
      }
    />
  )
}
