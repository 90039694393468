import moment from 'moment'
import { TimerModel } from '../../@types/timer'

export const mockDataTimer: TimerModel[] = [
  {
    id: 1,
    start_at: moment().subtract(8, 'hour').format('YYYY-MM-DD HH:mm:ss'),
    end_at: moment().subtract(7, 'hour').format('YYYY-MM-DD HH:mm:ss'),
    execution_id: 1,
    car_tracks: [],
    total_timer: 0,
    total_distance: 0
  },
  {
    id: 2,
    start_at: moment().subtract(5, 'hour').format('YYYY-MM-DD HH:mm:ss'),
    end_at: moment().subtract(3, 'hour').subtract(14, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
    execution_id: 1,
    car_tracks: [],
    total_timer: 0,
    total_distance: 0
  },
  {
    id: 3,
    start_at: moment().subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss'),
    end_at: moment().subtract(30, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
    execution_id: 1,
    car_tracks: [],
    total_timer: 0,
    total_distance: 0
  },
  {
    id: 4,
    start_at: moment().subtract(10, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
    end_at: moment().subtract(5, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
    execution_id: 1,
    car_tracks: [],
    total_timer: 0,
    total_distance: 0
  },
]
