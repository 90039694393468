import _ from 'lodash'

export const formatModel = {
  HeadquarterModel: 'Sede',
  RegionModel: 'Região',
  CompanyModel: 'Loja',
}

export const formatRole = {
  MANAGER_COMPANY: 'Gerente Loja',
  MASTER: 'Master',
  MANAGER_HEADQUARTER: 'Gerente da Sede',
  MANAGER_REGION: 'Gerente Regional',
  MANAGER_SUPPLIER: 'Fornecedor',
  MARKETING_REGION: 'Gerente de Marketing',
  OPERATIONAL_REGION: 'Gerente Operacional',
}

export const formatSecondToTimer = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(remainingSeconds).padStart(2, '0')

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}

export const formatFormFilterData = (data: Record<any, any>): any => {
  return _.omitBy(data, (value) => value === null || value === undefined || value === '')
}
