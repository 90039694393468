import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetOneApi } from 'rk-react-component'
import { FormSupplier } from '../../../containers/forms/form-supplier/form-supplier'
import { api } from '../../../services/api'
import { PageFormTabContainer } from '../../../containers/pages/page-form-tab-container/page-form-tab-container'
import { ModalAddRole } from '../../../containers/modals/modal-add-role/modal-add-role'
import { ModalAddDriver } from '../../../containers/modals/modal-add-driver/modal-add-driver'
import { TabAddress } from '../../../containers/tabs/tab-address/tab-address'

export const SupplierFormPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data, loading } = useGetOneApi<any>({
    url: '/suppliers',
    id,
    api,
  })
  return (
    <PageFormTabContainer
      title={'Fornecedor'}
      items={[
        {
          FormComponent: (
            <FormSupplier initialValues={data} onFinish={(dataApi) => navigate(`/suppliers/${dataApi?.id}`)} />
          ),
          disabled: false,
          key: '1',
          label: 'Informações',
          loading,
          title: 'Informações',
        },
        {
          disabled: !data?.id,
          key: 'tab_address',
          label: 'Endereço',
          loading: false,
          title: 'Novo Endereço',
          FormComponent: <TabAddress modelId={data?.id} modelType={'SupplierModel'} />,
        },
        {
          FormComponent: (
            <ModalAddRole
              fixParams={{
                allow_model_type: 'SupplierModel',
                allow_model_id: data?.id,
                role: 'MANAGER_SUPPLIER',
              }}
            />
          ),
          disabled: !data?.id,
          key: '2',
          label: 'Responsáveis',
          loading,
          title: 'Responsáveis',
        },
        {
          FormComponent: <ModalAddDriver fixParams={{ supplier_id: data?.id }} supplierId={data?.id} />,
          disabled: !data?.id,
          key: '3',
          label: 'Motoristas',
          loading,
          title: 'Motoristas',
        },
      ]}
    />
  )
}
