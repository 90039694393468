import React, { useContext, useEffect } from 'react'
import { useGetAllApi } from 'rk-react-component'
import { PageIndexContainer } from '../../../containers/pages/page-index-container/page-index-container'
import { TableRoute } from '../../../containers/tables/table-route/table-route'
import { FormFilterRoute } from '../../../containers/form-filter/form-filter-route/form-filter-route'
import { api } from '../../../services/api'
import { MeContext } from '../../../contexts/me-context/me-context'

export const RoutesIndexPage = () => {
  const { role } = useContext(MeContext)
  const { dataList, loading, fetchDataList, pagination, remove } = useGetAllApi<any, any>({
    url: '/routes',
    api,
    initialRequest: false,
  })

  useEffect(() => {
    fetchDataList({ per_page: 10, page: 1 })
  }, [])

  return (
    <PageIndexContainer
      title={'Rotas'}
      url={'/routes'}
      btnTitle={role?.role === 'MASTER' ? 'Nova Rota' : undefined}
      FormFilterComponent={<FormFilterRoute onFinish={(data) => fetchDataList(data)} />}
      TableComponent={() => (
        <TableRoute
          dataSource={dataList}
          loading={loading}
          loadData={fetchDataList}
          pagination={pagination}
          onDelete={role?.role === 'MASTER' ? remove : undefined}
        />
      )}
    />
  )
}
