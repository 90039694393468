import React from 'react'
import { LoginForm, ProFormText } from '@ant-design/pro-components'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { SignInFastLogin } from './sign-in-fast-login'
import { Button, Divider, Image, Row } from 'antd'
import { useNavigate } from 'react-router-dom'

export const SigninPage = ({ onSignin, loading }) => {
  const navigate = useNavigate()
  return (
    <div
      style={{
        backgroundColor: 'white',
        height: 'calc(100vh)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <div style={{ display: 'flex', flex: 1 }} />
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <LoginForm loading={loading} style={{ marginTop: 'auto' }} onFinish={onSignin}>
          <Row justify={'center'}>
            <Image src={require('../../assets/logo-with-icon-orange.png')} preview={false} style={{ maxWidth: 240 }} />
            <Divider />
          </Row>
          <ProFormText
            name="email"
            fieldProps={{
              size: 'large',
              prefix: <UserOutlined className={'prefixIcon'} />,
            }}
            placeholder={'Email'}
          />
          <ProFormText.Password
            name="password"
            fieldProps={{
              size: 'large',
              prefix: <LockOutlined className={'prefixIcon'} />,
            }}
            placeholder={'Senha'}
          />
          {/*<div style={{ marginBottom: 16, marginTop: -16, justifyContent: 'flex-end', display: 'flex' }}>*/}
          {/*  <Link to={'/forgot-password'}>Esqueci minha senha</Link>*/}
          {/*</div>*/}
          {process.env.REACT_APP_SHOW_HELPER === 'on' ? <SignInFastLogin /> : null}
        </LoginForm>
        <Button
          type={'link'}
          onClick={() => {
            navigate('/signup/supplier')
          }}
        >
          Sou Fornecedor e quero me cadastrar
        </Button>
      </div>
      <div style={{ display: 'flex', flex: 2 }} />
    </div>
  )
}
