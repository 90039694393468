import React, { useCallback } from 'react'
import './style.css'
import { TableContainer, TableContainerProps } from '../table-container/table-container'
import moment from 'moment'
import { Tag } from 'antd'
import { formatDate } from 'rk-react-component'
import { SupplierSelectDriver } from '../../../components/supplier-select-driver/supplier-select-driver'

export const TableSupplierExecution = ({
  dataSource,
  loading,
  loadData,
  pagination,
  setDataList,
}: TableContainerProps<any, any>) => {
  const updateRecord = useCallback((data) => {
    setDataList((old) => old.map((i) => (i.id === data.id ? data : i)))
  }, [])
  return (
    <TableContainer
      loadData={loadData}
      pagination={pagination}
      id={'table_import'}
      loading={loading}
      columns={[
        {
          dataIndex: 'customer',
          key: 'customer',
          title: 'Loja',
          render: (_val, record) => {
            return <div style={{ minWidth: 150 }}>{record?.insertion?.customer?.name || '-'}</div>
          },
        },
        {
          dataIndex: 'customer_code',
          key: 'customer_code',
          title: 'Cód.',
          render: (_val, record) => {
            return <div style={{ minWidth: 150 }}>{record?.insertion?.customer?.code || '-'}</div>
          },
        },
        {
          dataIndex: 'period',
          key: 'period',
          title: 'Período',
          render: (_val, record) => {
            return record?.insertion?.period ? formatDate(record?.insertion?.period, 'MMMM/YYYY') : '-'
          },
        },
        {
          dataIndex: 'status',
          key: 'status',
          title: 'Status',
          render: (val) => {
            if (val === 'FINISHED') {
              return <Tag color={'green'}>Finalizado</Tag>
            }
            if (val === 'SCHEDULED') {
              return <Tag color={'yellow'}>Agendado</Tag>
            }
            if (val === 'ONGOING') {
              return <Tag color={'blue'}>Em Andamento</Tag>
            }
            return '-'
          },
        },
        {
          dataIndex: 'expected_hours',
          key: 'expected_hours',
          title: 'Horas Previstas',
          render: (val) => (val || '-') + ' / h',
        },
        {
          dataIndex: 'driver',
          key: 'driver',
          title: 'Motorista',
          render: (_val, record) => {
            return <SupplierSelectDriver driver={record?.driver} executionId={record?.id} updateRecord={updateRecord} />
          },
        },
        {
          dataIndex: 'date',
          key: 'date',
          title: 'Data',
          render: (_val, record) => moment(record?.date).format('DD/MM/YYYY'),
        },
        {
          dataIndex: 'created_at',
          key: 'created_at',
          title: 'Data de Criação',
          render: (val) => moment(val).format('DD/MM/YYYY HH:mm'),
        },
      ]}
      dataSource={dataSource}
    />
  )
}
