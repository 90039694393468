import React from 'react'
import { Form, Input, Select } from 'antd'
import { FormContainer, FormItem, InputCnpj, InputCpf, useFormApi } from 'rk-react-component'
import { api } from '../../../services/api'
import { notificationSuccessSave } from '../../../services/notification-success-save'

interface FormSupplierProps {
  onFinish: (data: any) => void
  initialValues: Partial<any> | null
  dateCast?: string[]
}

export const FormSupplier = ({ onFinish, initialValues, dateCast }: FormSupplierProps) => {
  const [form] = Form.useForm()
  const type = Form.useWatch('type', form)
  const { save, loading } = useFormApi<any, any>({
    url: '/suppliers',
    id: initialValues?.id,
    api,
  })
  return (
    <FormContainer
      dateCast={dateCast}
      name={'form_supplier'}
      initialValues={initialValues}
      form={form}
      onValuesChange={(val) => {
        if (val?.type) {
          form.setFieldValue('document', '')
        }
      }}
      onFinish={(data) => {
        save(
          {
            ...data,
            document: data.document.replace(/\D/g, ''),
          },
          (response) => {
            onFinish(response)
            notificationSuccessSave(!initialValues?.id)
          },
          (e) => console.warn(e),
        )
      }}
      loading={loading}
    >
      <FormItem label={'Nome'} name={'name'} rules={[{ required: true, message: 'O campo "Nome" é obrigatório.' }]}>
        <Input placeholder={'Nome'} data-testid={'name'} />
      </FormItem>
      <FormItem
        label={'Nome Fantasia'}
        name={'fantasy_name'}
        rules={[{ required: true, message: 'O campo "Nome Fantasia" é obrigatório.' }]}
      >
        <Input placeholder={'Nome Fantasia'} data-testid={'fantasy_name'} />
      </FormItem>
      <FormItem
        label={'Razão Social'}
        name={'razao_social'}
        rules={[{ required: true, message: 'O campo "Razão Social" é obrigatório.' }]}
      >
        <Input placeholder={'Razão Social'} data-testid={'razao_social'} />
      </FormItem>
      <FormItem label={'Tipo'} name={'type'} rules={[{ required: true, message: 'O campo "Tipo" é obrigatório.' }]}>
        <Select
          data-testid={'type'}
          placeholder={'Tipo'}
          options={[
            { label: 'Pessoa Física', value: 'PF' },
            { label: 'Pessoa Juridica', value: 'PJ' },
          ]}
        />
      </FormItem>
      {type === 'PF' ? (
        <FormItem label={'CPF'} name={'document'} rules={[{ required: true, message: 'O campo "CPF" é obrigatório.' }]}>
          <InputCpf data-testid={'document'} />
        </FormItem>
      ) : (
        <FormItem
          label={'CNPJ'}
          name={'document'}
          rules={[{ required: true, message: 'O campo "CNPJ" é obrigatório.' }]}
        >
          <InputCnpj data-testid={'document'} />
        </FormItem>
      )}
    </FormContainer>
  )
}
