import { Button, Card, Divider } from 'antd'
import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageHeader } from '../../../components/page-header/page-header'

interface PageIndexContainerProps {
  url: string
  title: string
  btnTitle?: string
  FormFilterComponent: any
  TableComponent: any
  extra?: ReactNode[]
}

export const PageIndexContainer = ({
  url,
  title,
  btnTitle,
  FormFilterComponent,
  TableComponent,
  extra,
}: PageIndexContainerProps) => {
  const navigate = useNavigate()
  return (
    <>
      <PageHeader
        title={title}
        onBack={() => navigate(-1)}
        extra={
          extra?.length
            ? extra
            : btnTitle
            ? [
                <Button
                  id={'btn_new_model'}
                  data-testid={'btn_new_model'}
                  key={'btn_new_model'}
                  type={'primary'}
                  onClick={() => navigate(`${url}/store`)}
                >
                  {btnTitle}
                </Button>,
              ]
            : []
        }
      />
      <Divider />
      <Card size={'small'}>{FormFilterComponent}</Card>
      <br />
      <Card bodyStyle={{ padding: 0 }}>
        <TableComponent />
      </Card>
    </>
  )
}
