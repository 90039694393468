import React, { useContext, useEffect, useState } from 'react'
import { DatePickerMoment } from '../../../components/date-picke-moment/date-picker-moment'
import { Card, Divider, Form, Image, Modal, Space, Tag, Tooltip } from 'antd'
import { FaEye, FaThumbsDown, FaThumbsUp } from 'react-icons/fa'
import { PageHeader } from '../../../components/page-header/page-header'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { api } from '../../../services/api'
import { FormFilterContainer, useGetAllApi } from 'rk-react-component'
import { TableContainer } from '../../../containers/tables/table-container/table-container'
import { TagStatusExecution } from '../../../components/tag-status-execution/tag-status-execution'
import ReactAudioPlayer from 'react-audio-player'
import { MeContext } from '../../../contexts/me-context/me-context'

export const ReportDailyPage = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { role } = useContext(MeContext)
  const [dataFilter, setDateFilter] = useState(moment())
  const [showMedia, setShowMedia] = useState({ visible: false, media: null })
  const { dataList, fetchDataList, loading } = useGetAllApi<any, any>({
    url: '/reports/daily',
    api,
    initialRequest: false,
  })

  useEffect(() => {
    fetchDataList({ page: 1, per_page: 10, date: dataFilter.format('YYYY-MM-DD') })
    // loadReport()
  }, [])
  return (
    <Space style={{ width: '100%' }} direction={'vertical'}>
      <PageHeader onBack={() => navigate(-1)} title={'Relatório Diário - ' + dataFilter.format('DD/MM/YYYY')} />
      <Card size={'small'}>
        <FormFilterContainer
          form={form}
          formProps={{
            onFinish: (data) => {
              fetchDataList({ ...data, page: 1, per_page: 10, date: dataFilter.format('YYYY-MM-DD') })
            },
          }}
        >
          {() => {
            return (
              <>
                <DatePickerMoment
                  clearIcon={false}
                  value={dataFilter}
                  format={'DD/MMM/YYYY'}
                  onChange={(d) => {
                    setDateFilter(d)
                  }}
                />
              </>
            )
          }}
        </FormFilterContainer>
      </Card>
      <Card size={'small'}>
        <TableContainer
          loading={loading}
          columns={[
            {
              dataIndex: ['insertion', 'customer', 'region'],
              key: 'insertion',
              title: 'Região',
              render: (val) => <Tag>{val?.parent?.name}</Tag>,
            },
            {
              dataIndex: ['insertion', 'customer', 'region'],
              key: 'insertion',
              title: '',
              ellipsis: { showTitle: true },
              render: (val) => val.name,
            },
            {
              dataIndex: ['insertion', 'customer', 'name'],
              key: 'insertion',
              title: 'Empresa',
              ellipsis: true,
            },
            ...(role?.role === 'MASTER'
              ? [
                  {
                    dataIndex: ['insertion', 'supplier', 'name'],
                    key: 'insertion',
                    title: 'Fornecedor',
                    render: (val) => <div style={{ minWidth: 200 }}>{val}</div>,
                  },
                ]
              : []),
            {
              dataIndex: ['driver', 'user', 'name'],
              key: 'driver',
              title: 'Motorista',
              render: (val) => <div style={{ minWidth: 200 }}>{val}</div>,
            },
            {
              dataIndex: 'status',
              key: 'status',
              title: 'Status',
              render: (val) => <TagStatusExecution status={val} />,
            },
            {
              dataIndex: 'date_check_in_signature',
              key: 'date_check_in_signature',
              title: 'Check-in',
              align: 'center',
              render: (val, record) => {
                return (
                  <Space split={<Divider type={'vertical'} />} size={0} align={'center'}>
                    {val ? (
                      <Tooltip title={moment(val).format('DD/MM/YYYY HH:mm')}>
                        <FaThumbsUp color={'green'} />
                      </Tooltip>
                    ) : (
                      <FaThumbsDown color={'red'} />
                    )}
                    {record?.media_check_in?.id ? (
                      <FaEye
                        color={'#ccc'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setShowMedia({ visible: true, media: record?.media_check_in })
                        }}
                      />
                    ) : null}
                  </Space>
                )
              },
            },
            {
              dataIndex: 'date_check_out_signature',
              key: 'date_check_out_signature',
              title: 'Check-out',
              align: 'center',
              render: (val, record) => {
                return (
                  <Space split={<Divider type={'vertical'} />} size={0} align={'center'}>
                    {val ? (
                      <Tooltip title={moment(val).format('DD/MM/YYYY HH:mm')}>
                        <FaThumbsUp color={'green'} />
                      </Tooltip>
                    ) : (
                      <FaThumbsDown color={'red'} />
                    )}
                    {record?.media_check_out?.id ? (
                      <FaEye
                        color={'#ccc'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setShowMedia({ visible: true, media: record?.media_check_out })
                        }}
                      />
                    ) : null}
                  </Space>
                )
              },
            },
            {
              dataIndex: 'expected_hours',
              key: 'expected_hours',
              title: 'Horas',
              align: 'right',
              render: (val) => val + ' H',
            },
            {
              dataIndex: 'media',
              key: 'media',
              title: 'Mídia',
              align: 'center',
              render: (_val, record) => {
                if (record?.media?.id) {
                  return <ReactAudioPlayer src={record?.media?.path} controls />
                }
                return '-'
              },
            },
          ]}
          dataSource={dataList}
          loadData={() => {}}
          pagination={false}
        />
      </Card>
      <Modal
        open={showMedia?.visible}
        destroyOnClose={true}
        onCancel={() => setShowMedia({ visible: false, media: null })}
        footer={false}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
          <Image src={showMedia?.media?.path} alt={showMedia?.media?.name} style={{ height: 500 }} preview={false} />
        </div>
      </Modal>
    </Space>
  )
}
