import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import React, { useContext, useEffect } from 'react'
import { SigninFactory } from '../pages/sign-in/signin-factory'
import { Layout } from 'rk-react-component'
import { configProject } from '../config-project'
import { MenuExtraRender } from './layouts/menu-extra-render/menu-extra-render'
import { useRouteRole } from '../hooks/use-route-role'
import { FooterLayout } from './layouts/footer-layout/footer-layout'
import { ForgotPassword } from '../pages/forgot-password/forgot-password'
import { SignupSupplier } from '../pages/signup-supplier/signup-supplier'
import { SignupDriver } from '../pages/signup-driver/signup-driver'
import { Badge, Flex, notification } from 'antd'
import { MeContext } from '../contexts/me-context/me-context'

const token = {
  sider: {
    colorMenuBackground: configProject.colorMenuBackground,
    colorTextMenu: configProject.colorTextMenu,
    colorTextMenuSelected: configProject.colorTextMenuSelected,
    colorTextMenuItemHover: configProject.colorTextMenuSelected,
    colorTextMenuTitle: configProject.colorTextMenuTitle,
    colorTextSubMenu: 'red',
    colorBgCollapsedButton: configProject.colorBgCollapsedButton,
    colorBgMenuItemCollapsedElevated: configProject.colorBgMenuItemCollapsedElevated,
  },
  colorPrimary: configProject.colorPrimary,
  header: { colorBgHeader: configProject.colorBgHeader },
}

export const MainLayout = ({ me }: any) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [isMounted, setIsMounted] = React.useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (isMounted) {
      if (
        !me?.id &&
        location.pathname !== '/signin' &&
        location.pathname !== '/reset-password' &&
        !location.pathname.includes('/signup')
      ) {
        navigate('/signin')
      }
      if (me?.id && (location.pathname === '/signin' || location.pathname.includes('/signup'))) {
        navigate('/')
        notification.info({ message: 'Você já está logado!' })
      }
    }
    // eslint-disable-next-line
  }, [me, isMounted])

  const { routes } = useRouteRole()

  if (!isMounted) return null

  if (!me?.id) {
    return (
      <Routes>
        <Route path={'/signin'} element={<SigninFactory />} />
        <Route path={'/signup/driver/:id'} element={<SignupDriver />} />
        <Route path={'/signup/supplier'} element={<SignupSupplier />} />
        <Route path={'/forgot-password'} element={<ForgotPassword />} />
      </Routes>
    )
  }
  return (
    <Layout
      menuExtraRender={MenuExtraRender}
      title={'Cidade Guia'}
      token={token}
      locationRouter={location}
      logo={require('../assets/icon-bordered.png')}
      LinkComponent={Link}
      routes={routes?.location}
      menuFooterRender={FooterLayout}
      menuItemRender={(item, dom) => {
        return <MenuRenderItem item={item} dom={dom} />
      }}
    >
      {routes.routes}
    </Layout>
  )
}

const MenuRenderItem = ({ item, dom }) => {
  const { unreadMessage } = useContext(MeContext)
  console.log(unreadMessage)
  return (
    <Link to={item.path}>
      {item.name === 'Chat' ? (
        <Flex gap={12} align={'center'}>
          {dom}
          <Badge count={unreadMessage} />
        </Flex>
      ) : (
        dom
      )}
    </Link>
  )
}
