import { api } from '../../../services/api'
import { SelectAction, SelectActionProps } from 'rk-react-component'
import React from 'react'

export const SelectCompany = ({ ...props }: Partial<SelectActionProps>) => {
  return (
    <SelectAction
      api={api}
      url={'/companies-select'}
      style={{ width: 150 }}
      fieldSearch={'name_lk'}
      placeholder={'Selecione uma Loja'}
      {...props}
    />
  )
}
