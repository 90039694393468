import { GetOneApiReturn, useGetOneApi } from 'rk-react-component'
import { api } from '../../../services/api'
import { TimerModel } from '../../../@types/timer'
import React, { useEffect, useMemo } from 'react'
import { notification } from 'antd'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

export const useLoadPage = ({ id }) => {
  const [isMounted, setMounted] = React.useState(false)
  const navigate = useNavigate()
  const {
    data,
    setLoading,
    setData,
    loading: loadingExecution,
    fetchData,
  } = useGetOneApi<any>({
    url: '/executions',
    id,
    api,
  })
  const { data: dataTimer }: GetOneApiReturn<TimerModel> = useGetOneApi<any>({
    url: '/timers',
    id: 1,
    api,
  })
  const { data: dataOrder, loading: loadingOrder } = useGetOneApi<any>({
    url: '/orders',
    id: data?.order_id,
    api: api,
  })
  const { data: dataInsertion, loading: loadingInsertion } = useGetOneApi<any>({
    url: '/insertions',
    id: dataOrder?.insertion_id,
    api: api,
  })

  const positions = useMemo(() => formatPosition({ timers: data?.timers }), [data?.timers])

  useEffect(() => {
    if (data && data.status && data.status !== 'FINISHED' && data.status !== 'ONGOING' && data.status !== 'SCHEDULED') {
      notification.warning({
        message: 'Atenção',
        description: 'Só é possível visualizar execuções com status Finalizado ou Em Andamento ou Agendada',
      })
      navigate(-1)
    }
    if (dataTimer?.id) {
      setMounted(true)
    }
  }, [dataTimer, positions])

  return {
    data,
    dataTimer,
    dataOrder,
    dataInsertion,
    loading: loadingOrder || loadingInsertion || loadingExecution,
    setLoading,
    fetchData,
    setData,
    isMounted,
    positions,
  }
}

const formatPosition = ({ timers }) => {
  const coords = []
  if (timers?.length) {
    _.map(timers, (timer: TimerModel) => {
      if (timer?.car_tracks?.length) {
        return _.map(timer.car_tracks, (track) => {
          coords.push({
            lat: track?.latitude,
            lng: track?.longitude,
          })
        })
      }
    })
  }
  return coords
}
