import React, { useState } from 'react'
import { Modal, Space } from 'antd'
import { PageFormTabContainer } from '../../../containers/pages/page-form-tab-container/page-form-tab-container'
import { TabExecutionReport } from '../../../containers/tabs/tab-execution-report/tab-execution-report'
import { TabMasterFinancialReport } from '../../../containers/tabs/tab-master-financial-report/tab-master-financial-report'

export const ReportsMasterPage = () => {
  const [pdfFile, setPdfFile] = useState(null)

  const openModal = (data: any) => {
    setPdfFile(URL.createObjectURL(data))
  }

  return (
    <Space style={{ width: '100%' }} direction={'vertical'}>
      <PageFormTabContainer
        title={'Relatórios'}
        items={[
          {
            key: '1',
            title: 'Relatório de Operação',
            label: 'Relatório de Operação',
            FormComponent: <TabExecutionReport onFinish={openModal} />,
          },
          {
            key: '2',
            title: 'Relatório Financeiro',
            label: 'Relatório Financeiro',
            FormComponent: <TabMasterFinancialReport onFinish={openModal} />,
          },
        ]}
      />
      <Modal open={pdfFile} onCancel={() => setPdfFile(null)} width={'90%'} onOk={() => setPdfFile(null)}>
        <iframe src={pdfFile} width={'100%'} height={800} style={{ margin: '0 auto', display: 'block' }} />
      </Modal>
    </Space>
  )
}
