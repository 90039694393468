import React from 'react'
import { UserModel } from '../../@types/user'
import { RoleModel } from '../../@types/role'

export const MeContext = React.createContext<{
  ready: boolean
  me: UserModel
  setMe: (user: UserModel) => void
  role: RoleModel
  setRole: (role: RoleModel) => void
  setReady: (val: boolean) => void
  setUnreadMessage: ((val: number) => void) | any
  unreadMessage: number
}>({
  ready: false,
  me: null,
  role: null,
  setMe: () => {},
  setRole: () => {},
  setReady: () => {},
  setUnreadMessage: () => {},
  unreadMessage: 0,
})
