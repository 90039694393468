import React from 'react'
import { FormFilterContainer, FormFilterItem, InputCnpj, InputCpf } from 'rk-react-component'
import { Form, Input, Select } from 'antd'

interface FormFilterSupplierProps {
  onFinish: (data: Partial<any> & { page: number; per_page: number }) => void
}

export const FormFilterImport = ({ onFinish }: FormFilterSupplierProps) => {
  const [form] = Form.useForm()
  const type = Form.useWatch('type', form)
  return (
    <FormFilterContainer
      formProps={{
        onFinish: (data) => {
          onFinish({ ...data, page: 1, per_page: 10 })
        },
      }}
      form={form}
      hiddenShowMore={true}
    >
      {() => (
        <>
          <FormFilterItem name={'name_lk'}>
            <Input placeholder={'Nome'} data-testid={'name_lk'} />
          </FormFilterItem>
          <FormFilterItem name={'type'}>
            <Select
              data-testid={'type'}
              placeholder={'Tipo'}
              style={{ minWidth: 150 }}
              options={[
                { label: 'Pessoa Física', value: 'PF' },
                { label: 'Pessoa Juridica', value: 'PJ' },
              ]}
            />
          </FormFilterItem>
          {type === 'PF' && (
            <FormFilterItem name={'document'}>
              <InputCpf placeholder={'Documento'} data-testid={'document'} />
            </FormFilterItem>
          )}
          {type === 'PJ' && (
            <FormFilterItem name={'document'}>
              <InputCnpj placeholder={'Documento'} data-testid={'document'} />
            </FormFilterItem>
          )}
        </>
      )}
    </FormFilterContainer>
  )
}
