import React from 'react'
import { Form, Input } from 'antd'
import { HeadquarterModel } from '../../../@types/headquarter'
import { FormContainer, FormItem, useFormApi } from 'rk-react-component'
import { api } from '../../../services/api'
import { notificationSuccessSave } from '../../../services/notification-success-save'

interface FormHeadquarterProps {
  onFinish: (data: any) => void
  initialValues: Partial<any> | null
  dateCast?: string[]
}

export const FormHeadquarter = ({ onFinish, initialValues, dateCast }: FormHeadquarterProps) => {
  const [form] = Form.useForm()
  const { save, loading } = useFormApi<HeadquarterModel, any>({
    url: '/headquarters',
    id: initialValues?.id,
    api,
  })
  return (
    <FormContainer
      dateCast={dateCast}
      name={'form_headquarter'}
      initialValues={initialValues}
      form={form}
      onFinish={async (data) => {
        save(
          data,
          (response) => {
            onFinish(response)
            notificationSuccessSave(!initialValues?.id)
          },
          (e) => console.warn(e),
        )
      }}
      loading={loading}
    >
      <FormItem label={'Nome'} name={'name'} rules={[{ required: true, message: 'O campo "Nome" é obrigatório.' }]}>
        <Input placeholder={'Nome'} data-testid={'name'} />
      </FormItem>
      <FormItem
        label={'Descrição'}
        name={'description'}
        rules={[{ required: true, message: 'O campo "Descrição" é obrigatório.' }]}
      >
        <Input.TextArea placeholder={'Descrição'} data-testid={'description'} />
      </FormItem>
    </FormContainer>
  )
}
