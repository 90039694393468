import moment from "moment/moment";
import _ from "lodash";
import {Space, TableColumnProps, Tooltip} from "antd";
import React, {ReactNode} from "react";
import {Moment} from "moment";

export const createArrDaysMonth = (callback: (data: Moment) => any) => {
    const start_at = moment().startOf('month')
    const end_at = moment().endOf('month')
    const days_diff = end_at.diff(start_at, 'days') + 1
    return _.range(days_diff).map((i) => {
        const start = start_at.clone().add(i, 'days')
        return callback(start)
    })
}

export const createMonthColumns = (render: (val: any, record: any, key: any) => ReactNode) => {
    return createArrDaysMonth((start) => {
        const key = start.format('DD')
        return {
            dataIndex: key,
            key: key,
            title: () => (
                <Tooltip title={start.format('dddd')}>
                    <Space direction={'vertical'} style={{width: '50px'}} size={0}>
                        <span>{start.format('ddd')}</span>
                        <span>{key}</span>
                    </Space>
                </Tooltip>
            ),
            align: 'center',
            width: 50,
            render: (val, record) => render(val, record, key)

        } as TableColumnProps<any>
    })
}