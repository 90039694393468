import {Button, Drawer, List, Space, Spin, Tooltip, Typography} from "antd";
import {NotificationIcon} from "../../main/icon-modules";
import {FaTrash} from "react-icons/fa";
import moment from "moment/moment";
import React, {useContext, useEffect, useState} from "react";
import {api} from "../../services/api";
import {MeContext} from "../../contexts/me-context/me-context";
import {BiCheck, BiCheckDouble} from "react-icons/bi";

interface NotificationDrawerProp {
    iconSize: number
    color: string
}

export const NotificationDrawer = ({iconSize, color}: NotificationDrawerProp) => {
    const {me} = useContext(MeContext)
    const [modalNotification, setModalNotification] = useState(false)
    const [loadingNotification, setLoadingNotification] = useState(false)
    const [loadingReadAll, setLoadingReadAll] = useState(false)
    const [notifications, setNotifications] = useState([])
    const fetchNotifications = () => {
        setLoadingNotification(true)
        api.get('/notifications', {params: {user_id: me?.id}}).then(({data}) => {
            setNotifications(data?.data)
        }).finally(() => {
            setLoadingNotification(false)
        })
    }
    useEffect(() => {
        if (modalNotification) {
            fetchNotifications()
        }
    }, [modalNotification]);
    return (
        <Tooltip title={'Notificações'}>
            <Button
                type={'link'}
                icon={<NotificationIcon size={iconSize} color={color}/>}
                onClick={() => {
                    setModalNotification(true)
                }}
            />
            <Drawer
                title="Notificações"
                placement="right"
                onClose={() => setModalNotification(false)} open={modalNotification}
                extra={[
                    <Tooltip title={'Marcar todos como lido'}>
                        <Button
                            loading={loadingReadAll}
                            type={'text'}
                            icon={(<BiCheckDouble size={24}/>)}
                            onClick={() => {
                                setLoadingReadAll(true)
                                api.post('/notifications/read-all', {user_id: me?.id}).then(() => {
                                    fetchNotifications()
                                }).finally(() => {
                                    setLoadingReadAll(false)
                                })
                            }}
                        />
                    </Tooltip>
                ]}
            >
                {loadingNotification && <Spin/>}
                <List
                    dataSource={notifications}
                    itemLayout={'vertical'}
                    renderItem={(item) => (
                        <List.Item key={item.id} extra={[
                            <Space direction={"vertical"}>
                                <Tooltip title={'Marcar como lido'}>
                                    <Button
                                        type={"text"}
                                        icon={(<BiCheck color={item?.readed_at ? 'green' : '#ccc'} size={24}/>)}
                                        onClick={() => {
                                            if (!item?.readed_at) {
                                                api.put(`/notifications/${item?.id}`, {
                                                    readed_at: moment().format('YYYY-MM-DD HH:mm:ss')
                                                }).then(({data: dataResponse}) => {
                                                    const notificationAux = notifications.map((n) => n?.id === item?.id ? dataResponse : n)
                                                    setNotifications(notificationAux)
                                                })
                                            }
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip title={'Excluir'}>
                                    <Button type={"text"} icon={(<FaTrash color={'#ccc'}/>)}/>
                                </Tooltip>
                            </Space>
                        ]}>
                            <Space direction={"vertical"}>
                                <Typography.Text style={{fontWeight: 'bold'}}>
                                    {item?.title}
                                </Typography.Text>
                                <Typography.Text style={{fontSize: 12, color: 'gray', marginTop: 0}}>
                                    {item?.description}
                                </Typography.Text>
                                <Typography.Text style={{fontSize: 10, color: 'gray', marginTop: 12}}>
                                    {moment(item?.created_at).fromNow()}
                                </Typography.Text>
                            </Space>

                        </List.Item>
                    )}
                />
            </Drawer>
        </Tooltip>
    )
}