import React from 'react'
import { MdDashboard } from 'react-icons/md'
import { Route } from 'react-router-dom'
import { DashboardPage } from '../pages/dashboard/dashboard-page'
import { FiFilm } from 'react-icons/fi'
import { CompanyIndexPage } from '../pages/company/company-index-page/company-index-page'
import { CompanyFormPage } from '../pages/company/company-form-page/company-form-page'
import { FaCalendar, FaCompass, FaFile } from 'react-icons/fa'
import { DriverIndexPage } from '../pages/driver/driver-index-page/driver-index-page'
import { DriverFormPage } from '../pages/driver/driver-form-page/driver-form-page'
import { UserIndexPage } from '../pages/user/user-index-page/user-index-page'
import { UserFormPage } from '../pages/user/user-form-page/user-form-page'
import { MediaIndexPage } from '../pages/media/media-index-page/media-index-page'
import { MediaFormPage } from '../pages/media/media-form-page/media-form-page'
import { HeadquarterIndexPage } from '../pages/headquarter/headquarter-index-page/headquarter-index-page'
import { HeadquarterFormPage } from '../pages/headquarter/headquarter-form-page/headquarter-form-page'
import { RoutesIndexPage } from '../pages/routes/routes-index-page/routes-index-page'
import { RoutesFormPage } from '../pages/routes/region-form-page/routes-form-page'
import { DriverShowPage } from '../pages/driver/driver-show-page/driver-show-page'
import { ExecutionShowPage } from '../pages/execution/execution-show-page/execution-show-page'
import { SupplierIndexPage } from '../pages/supplier/supplier-index-page/supplier-index-page'
import { SupplierFormPage } from '../pages/supplier/supplier-form-page/supplier-form-page'
import { AdminIcon, ChatIcon, CompanyIcon, HeadquarterIcon, RegionIcon, SupplierIcon, UserIcon } from './icon-modules'
import { RegionFormPage } from '../pages/region/region-form-page/region-form-page'
import { RegionIndexPage } from '../pages/region/region-index-page/region-index-page'
import { ChatScreen } from '../pages/chat/chat-screen'
import { ReportMonthPage } from '../pages/report/report-month-page/report-month-page'
import { HiDocumentReport } from 'react-icons/hi'
import { ReportDailyPage } from '../pages/report/report-daily-page/report-daily-page'
import { MasterFormPage } from '../pages/master/master-form-page/master-form-page'
import { MasterIndexPage } from '../pages/master/master-index-page/master-index-page'
import { DashboardSupplierPage } from '../pages/dashboard-supplier/dashboard-supplier-page'
import { FcDocument } from 'react-icons/fc'
import { ImportIndexPage } from '../pages/import/import-index-page/import-index-page'
import { ImportFormPage } from '../pages/import/import-form-page/import-form-page'
import { SupplierExecutionIndexPage } from '../pages/supplier-execution/supplier-execution-index-page/supplier-execution-index-page'
import { MasterExecutionIndexPage } from '../pages/master-execution/master-execution-index-page/master-execution-index-page'
import { UserTiFormPage } from '../pages/user-ti/user-ti-form-page/user-ti-form-page'
import { UserTiIndexPage } from '../pages/user-ti/user-ti-index-page/user-ti-index-page'
import { ReportsMasterPage } from '../pages/report/reports-master-page/reports-master-page'
import { SupplierFinancialReportPage } from '../pages/report/supplier-financial-report/supplier-financial-report-page'
import { RegionExecutionIndexPage } from '../pages/region-execution/region-execution-index-page/region-execution-index-page'
import { ReportsCustomerPage } from '../pages/report/reports-customer-page/reports-customer-page'
import { ReportsCompanyPage } from '../pages/report/reports-company-page/reports-company-page'
import { ReportsRegionPage } from '../pages/report/reports-region-page/reports-region-page'
import { ReportsHeadquarterPage } from '../pages/report/reports-headquarter-page/reports-headquarter-page'

export const dashboard = {
  location: { path: '/', name: 'Dashboard', icon: <MdDashboard /> },
  routes: <Route path={''} element={<DashboardPage />} />,
}
export const dashboard_supplier = {
  location: { path: '/', name: 'Dashboard', icon: <MdDashboard /> },
  routes: <Route path={''} element={<DashboardSupplierPage />} />,
}
export const company = {
  location: { path: '/companies', name: 'Empresas', icon: <CompanyIcon /> },
  routes: (
    <>
      <Route path={'/companies/store'} element={<CompanyFormPage />} />
      <Route path={'/companies/:id'} element={<CompanyFormPage />} />
      <Route path={'/companies'} element={<CompanyIndexPage />} />
    </>
  ),
}
export const region = {
  location: { path: '/regions', name: 'Regiões', icon: <RegionIcon /> },
  routes: (
    <>
      <Route path={'/regions/store'} element={<RegionFormPage />} />
      <Route path={'/regions/:id'} element={<RegionFormPage />} />
      <Route path={'/regions'} element={<RegionIndexPage />} />
    </>
  ),
}
// export const insertion = {
//   location: {
//     path: '/insertions',
//     name: 'Inserções',
//     icon: <FaClipboardList />,
//   },
//   routes: (
//     <>
//       <Route path={'/insertions/store'} element={<InsertionFormPage />} />
//       <Route path={'/insertions/:id'} element={<InsertionFormPage />} />
//       <Route path={'/insertions'} element={<InsertionIndexPage />} />
//     </>
//   ),
// }
export const import_file = {
  location: {
    path: '/imports',
    name: 'Importações',
    icon: <FcDocument />,
  },
  routes: (
    <>
      <Route path={'/imports/store'} element={<ImportFormPage />} />
      <Route path={'/imports'} element={<ImportIndexPage />} />
    </>
  ),
}
export const supplier_execution = {
  location: {
    path: '/executions',
    name: 'Execuções',
    icon: <FaFile />,
  },
  routes: (
    <>
      <Route path={'/executions'} element={<SupplierExecutionIndexPage />} />
    </>
  ),
}
export const region_executions = {
  location: {
    path: '/executions',
    name: 'Execuções',
    icon: <FaFile />,
  },
  routes: (
    <>
      <Route path={'/executions'} element={<RegionExecutionIndexPage />} />
    </>
  ),
}
export const master_execution = {
  location: {
    path: '/executions',
    name: 'Execuções',
    icon: <FaFile />,
  },
  routes: (
    <>
      <Route path={'/executions'} element={<MasterExecutionIndexPage />} />
    </>
  ),
}
export const execution = {
  location: {},
  routes: <Route path={'/executions/show/:id'} element={<ExecutionShowPage />} />,
}
export const report_master = {
  location: {
    path: '/reports/group',
    name: 'Relatórios',
    icon: <HiDocumentReport />,
    type: 'group',
    children: [
      { path: '/reports', name: 'Relatórios PDF', icon: <FaFile /> },
      { path: '/reports/daily', name: 'Diário', icon: <FaCalendar /> },
      { path: '/reports/month', name: 'Mês', icon: <FaCalendar /> },
    ],
  },
  routes: (
    <>
      <Route path={'/reports/month'} element={<ReportMonthPage />} />
      <Route path={'/reports/daily'} element={<ReportDailyPage />} />
      <Route path={'/reports'} element={<ReportsMasterPage />} />
    </>
  ),
}
export const report_company = {
  location: {
    path: '/reports/group',
    name: 'Relatórios',
    icon: <HiDocumentReport />,
    type: 'group',
    children: [{ path: '/reports', name: 'Relatórios PDF', icon: <FaFile /> }],
  },
  routes: (
    <>
      <Route path={'/reports'} element={<ReportsCompanyPage />} />
    </>
  ),
}
export const report_region = {
  location: {
    path: '/reports/group',
    name: 'Relatórios',
    icon: <HiDocumentReport />,
    type: 'group',
    children: [{ path: '/reports', name: 'Relatórios PDF', icon: <FaFile /> }],
  },
  routes: (
    <>
      <Route path={'/reports'} element={<ReportsRegionPage />} />
    </>
  ),
}
export const report_headquarter = {
  location: {
    path: '/reports/group',
    name: 'Relatórios',
    icon: <HiDocumentReport />,
    type: 'group',
    children: [{ path: '/reports', name: 'Relatórios PDF', icon: <FaFile /> }],
  },
  routes: (
    <>
      <Route path={'/reports'} element={<ReportsHeadquarterPage />} />
    </>
  ),
}
export const report_customer = {
  location: {
    path: '/reports/group',
    name: 'Relatórios',
    icon: <HiDocumentReport />,
    type: 'group',
    children: [
      { path: '/reports', name: 'Relatórios PDF', icon: <FaFile /> },
      { path: '/reports/daily', name: 'Diário', icon: <FaCalendar /> },
      // { path: '/reports/month', name: 'Mês', icon: <FaCalendar /> },
    ],
  },
  routes: (
    <>
      {/*<Route path={'/reports/month'} element={<ReportMonthPage />} />*/}
      <Route path={'/reports/daily'} element={<ReportDailyPage />} />
      <Route path={'/reports'} element={<ReportsCustomerPage />} />
    </>
  ),
}
export const reportSupplier = {
  location: {
    path: '/reports/group',
    name: 'Relatórios',
    icon: <HiDocumentReport />,
    type: 'group',
    children: [{ path: '/financial-report', name: 'Relatório Financeiro', icon: <FaFile /> }],
  },
  routes: (
    <>
      <Route path={'/financial-report'} element={<SupplierFinancialReportPage />} />
    </>
  ),
}
export const driver = {
  location: { path: '/drivers', name: 'Motoristas', icon: <FiFilm /> },
  routes: (
    <>
      <Route path={'/drivers/show/:id'} element={<DriverShowPage />} />
      <Route path={'/drivers/store'} element={<DriverFormPage />} />
      <Route path={'/drivers/:id'} element={<DriverFormPage />} />
      <Route path={'/drivers'} element={<DriverIndexPage />} />
    </>
  ),
}
export const master = {
  location: { path: '/masters', name: 'Administradores', icon: <AdminIcon /> },
  routes: (
    <>
      <Route path={'/masters/store'} element={<MasterFormPage />} />
      <Route path={'/masters/:id'} element={<MasterFormPage />} />
      <Route path={'/masters'} element={<MasterIndexPage />} />
    </>
  ),
}
export const user = {
  location: { path: '/users', name: 'Usuários', icon: <UserIcon /> },
  routes: (
    <>
      <Route path={'/users/store'} element={<UserFormPage />} />
      <Route path={'/users/:id'} element={<UserFormPage />} />
      <Route path={'/users'} element={<UserIndexPage />} />
    </>
  ),
}
export const user_ti = {
  location: { path: '/users-ti', name: 'Usuários', icon: <UserIcon /> },
  routes: (
    <>
      <Route path={'/users-ti/:id'} element={<UserTiFormPage />} />
      <Route path={'/users-ti'} element={<UserTiIndexPage />} />
    </>
  ),
}
export const media = {
  location: { path: '/medias', name: 'Mídias', icon: <FiFilm /> },
  routes: (
    <>
      <Route path={'/medias/store'} element={<MediaFormPage />} />
      <Route path={'/medias/:id'} element={<MediaFormPage />} />
      <Route path={'/medias'} element={<MediaIndexPage />} />
    </>
  ),
}
export const headquarter = {
  location: { path: '/headquarters', name: 'Sedes', icon: <HeadquarterIcon /> },
  routes: (
    <>
      <Route path={'/headquarters/store'} element={<HeadquarterFormPage />} />
      <Route path={'/headquarters/:id'} element={<HeadquarterFormPage />} />
      <Route path={'/headquarters'} element={<HeadquarterIndexPage />} />
    </>
  ),
}
export const route = {
  location: { path: '/routes', name: 'Rotas', icon: <FaCompass /> },
  routes: (
    <>
      <Route path={'/routes/store'} element={<RoutesFormPage />} />
      <Route path={'/routes/:id'} element={<RoutesFormPage />} />
      <Route path={'/routes'} element={<RoutesIndexPage />} />
    </>
  ),
}
export const supplier = {
  location: { path: '/suppliers', name: 'Fornecedores', icon: <SupplierIcon /> },
  routes: (
    <>
      <Route path={'/suppliers/store'} element={<SupplierFormPage />} />
      <Route path={'/suppliers/:id'} element={<SupplierFormPage />} />
      <Route path={'/suppliers'} element={<SupplierIndexPage />} />
    </>
  ),
}
export const chat = {
  location: { path: '/chats', name: 'Chat', icon: <ChatIcon /> },
  routes: (
    <>
      <Route path={'/chats'} element={<ChatScreen />} />
    </>
  ),
}
