import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GetOneApiReturn, useGetOneApi } from 'rk-react-component'
import { FormHeadquarter } from '../../../containers/forms/form-headquarter/form-headquarter'
import { HeadquarterModel } from '../../../@types/headquarter'
import { api } from '../../../services/api'
import { PageFormTabContainer } from '../../../containers/pages/page-form-tab-container/page-form-tab-container'
import { ModalAddRole } from '../../../containers/modals/modal-add-role/modal-add-role'

export const HeadquarterFormPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data, loading }: GetOneApiReturn<HeadquarterModel> = useGetOneApi({
    url: '/headquarters',
    id,
    api,
  })
  return (
    <PageFormTabContainer
      title={'Sede'}
      items={[
        {
          FormComponent: (
            <FormHeadquarter
              dateCast={['shipping_date']}
              initialValues={data}
              onFinish={(dataApi) => navigate(`/headquarters/${dataApi?.id}`)}
            />
          ),
          disabled: false,
          key: '1',
          label: 'Informações',
          loading,
          title: 'Informações',
        },
        {
          FormComponent: (
            <ModalAddRole
              fixParams={{
                allow_model_type: 'HeadquarterModel',
                allow_model_id: data?.id,
                role: 'MANAGER_HEADQUARTER',
              }}
            />
          ),
          disabled: !data?.id,
          key: '2',
          label: 'Responsáveis',
          loading,
          title: 'Responsáveis',
        },
      ]}
    />
  )
}
