import React from 'react'
import {FormFilterItem, FormFilterContainer} from 'rk-react-component'
import {Form, Input} from 'antd'

interface FormFilterMediaProps {
    onFinish: (data: any) => void
}

export const FormFilterMedia = ({onFinish}: FormFilterMediaProps) => {
    const [form] = Form.useForm()
    return (
        <FormFilterContainer formProps={{onFinish}} form={form}>
            {() => (
                <FormFilterItem name={'name'}>
                    <Input placeholder={'Nome'}/>
                </FormFilterItem>
            )}
        </FormFilterContainer>
    )
}
