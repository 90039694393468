import { api } from '../../../services/api'
import { Button } from 'antd'
import React from 'react'

export const useCloseExecution = ({ setLoading, role, data, fetchData, id }) => {
  const canClose = role?.role === 'MASTER' && (data?.status === 'ONGOING' || data?.status === 'SCHEDULED')
  const onClose = () => {
    setLoading(true)
    api
      .put(`/executions/${id}/close`)
      .then(({ data: dataResponse }) => {
        fetchData()
      })
      .finally(() => setLoading(false))
  }
  const closeElement = canClose
    ? [
        <Button key={'close'} onClick={onClose}>
          Fechar Corrida
        </Button>,
      ]
    : []

  return { closeElement, canClose, onClose }
}
