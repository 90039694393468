import React from 'react'
import { FormContainer, FormItem, useFormApi } from 'rk-react-component'
import { Form, Input } from 'antd'
import { AddressModel } from '../../../@types/address'
import { api } from '../../../services/api'
import { InputPasswordStrength } from '../../inputs/input-password/input-password-strength'

interface FormPasswordProps {
  onFinish: (data: AddressModel) => void
  userId: number
}

export const FormPasswordTi = ({ onFinish, userId }: FormPasswordProps) => {
  const [form] = Form.useForm()
  const { save, loading } = useFormApi<any, any>({
    url: `/users-ti`,
    id: userId,
    api,
  })
  return (
    <FormContainer
      name={'form_password'}
      initialValues={null}
      form={form}
      onFinish={(data) => {
        save(
          {
            ...data,
          },
          (response) => {
            onFinish(response)
          },
          (e) => console.warn(e),
        )
      }}
      loading={loading}
    >
      <InputPasswordStrength />
      <FormItem label={'Confirmar Senha'} name={'password_confirmation'}>
        <Input.Password />
      </FormItem>
    </FormContainer>
  )
}
