import React, { useContext } from 'react'
import './style.css'
import { TableContainer, TableContainerProps } from '../table-container/table-container'
import { useNavigate } from 'react-router-dom'
import { MeContext } from '../../../contexts/me-context/me-context'
import { Tooltip } from 'antd'
import _ from 'lodash'

export const TableRegion = ({ dataSource, loading, loadData, pagination, ...props }: TableContainerProps<any, any>) => {
  const { role } = useContext(MeContext)
  const masterColumns = [
    {
      dataIndex: 'headquarter',
      key: 'headquarter',
      title: 'Sede',
      render: (val: any) => val?.name,
    },
  ]
  const navigate = useNavigate()
  return (
    <TableContainer
      {...props}
      loadData={loadData}
      pagination={pagination}
      id={'table_region'}
      loading={loading}
      onEdit={
        role?.role === 'MASTER'
          ? (record) => {
              navigate(`/regions/${record.id}`)
            }
          : undefined
      }
      columns={[
        {
          dataIndex: 'name',
          key: 'name',
          title: 'Nome',
          render: (val) => <div style={{ minWidth: 120 }}>{val}</div>,
        },
        {
          dataIndex: 'description',
          key: 'description',
          title: 'Descrição',
          render: (val) => {
            return (
              <Tooltip title={val}>
                {_.truncate(val, {
                  omission: ' [...]',
                  separator: ' ',
                  length: 150,
                })}
              </Tooltip>
            )
          },
        },
        ...(role.role === 'MASTER' ? masterColumns : []),
      ]}
      dataSource={dataSource}
    />
  )
}
