import React, { useContext, useEffect, useMemo, useState } from 'react'
import { PageIndexContainer, useGetAllApi } from 'rk-react-component'
import { api } from '../../../services/api'
import _ from 'lodash'
import { FormFilterRegion } from '../../../containers/form-filter/form-filter-region/form-filter-region'
import { TableRegion } from '../../../containers/tables/table-region/table-region'
import { MeContext } from '../../../contexts/me-context/me-context'

export const RegionIndexPage = () => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])
  const { role } = useContext(MeContext)

  const { dataList, loading, fetchDataList, setDataList, pagination, filter } = useGetAllApi<any, any>({
    url: '/regions',
    fixParams: { parent_id_is_null: true },
    api,
    initialRequest: false,
    filterKey: 'regionIndexPage',
  })

  const dataListFormatted: any[] = useMemo(() => {
    return dataList.map((i) => ({
      ...i,
      id: i?.id,
      children: i?.children?.length ? i?.children.map((c) => ({ ...c, id: c?.id })) : null,
    }))
  }, [dataList])

  const updateDataList = (record: any, dataResponse: any) => {
    const dataListKey = _.mapKeys(dataList, 'id')
    if (!record?.parent_id) {
      dataListKey[record?.id] = {
        ...dataListKey[record?.id],
        children: dataResponse?.map((i: any) => ({
          ...i,
          id: i?.id + '',
          children: i?.children?.length ? i?.children : null,
        })),
      }
    } else {
      const dataAux = _.mapKeys(dataListKey[record?.parent_id]?.children, 'id')
      dataAux[record?.id] = {
        ...dataAux[record?.id],
      }
      dataListKey[record?.parent_id].children = Object.values(dataAux)
    }
    setDataList(Object.values(dataListKey))
  }

  useEffect(() => {
    fetchDataList({ per_page: 10, page: 1, ...filter })
  }, [])
  return (
    <PageIndexContainer
      title={'Região'}
      btnTitle={role?.role === 'MASTER' ? 'Nova Região' : undefined}
      url={'/regions'}
      FormFilterComponent={<FormFilterRegion onFinish={fetchDataList} initialValues={filter} />}
      TableComponent={() => (
        <TableRegion
          pagination={pagination}
          loadData={(params) => fetchDataList({ ...filter, params })}
          expandable={{
            onExpand: (expanded, record) => {
              if (!expanded) {
                setExpandedRowKeys(expandedRowKeys.filter((i) => i !== record.id.toString()))
                return
              }
              api.get('/regions', { params: { parent_id: record.id } }).then(({ data: dataResponse }) => {
                updateDataList(record, dataResponse?.data)
                setExpandedRowKeys([...expandedRowKeys, record.id + ''])
              })
            },
            expandedRowKeys: expandedRowKeys,
          }}
          dataSource={dataListFormatted}
          loading={loading}
        />
      )}
    />
  )
}
