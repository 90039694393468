import React, { useEffect } from 'react'
import { useGetAllApi } from 'rk-react-component'
import { TableSupplier } from '../../../containers/tables/table-supplier/table-supplier'
import { PageIndexContainer } from '../../../containers/pages/page-index-container/page-index-container'
import { FormFilterSupplier } from '../../../containers/form-filter/form-filter-supplier/form-filter-supplier'
import { api } from '../../../services/api'

export const SupplierIndexPage = () => {
  const { dataList, fetchDataList, loading, pagination, filter } = useGetAllApi<any, any>({
    url: '/suppliers',
    api,
    filterKey: 'supplierIndexPage',
    initialRequest: false,
  })

  useEffect(() => {
    fetchDataList({ per_page: 10, page: 1, ...filter })
  }, [])
  return (
    <PageIndexContainer
      title={'Fornecedores'}
      btnTitle={'Novo Fornecedor'}
      url={'/suppliers'}
      TableComponent={() => (
        <TableSupplier
          pagination={pagination}
          loadData={(params) => fetchDataList({ ...filter, ...params })}
          dataSource={dataList}
          loading={loading}
        />
      )}
      FormFilterComponent={
        <FormFilterSupplier
          onFinish={(data) => {
            fetchDataList(data)
          }}
          initialValues={filter}
        />
      }
    />
  )
}
