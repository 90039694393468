import { Tabs, TabsProps } from 'antd'
import React from 'react'

interface TabComponentProps extends TabsProps {
  loading?: boolean
}

export const TabComponent = ({ ...props }: TabComponentProps) => {
  return <Tabs {...props} />
}
