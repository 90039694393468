import moment from 'moment'
import { InsertionModel } from '../../@types/insertion'

export const mockDataInsertion: InsertionModel[] = [
  {
    id: 1,
    customer_id: 1,
    supplier_id: 1,
    title: 'Carro de Som 6h/dia',
    description:
      'CAMPANHA INAUGURAÇÃO ASSAÍ ALCANTARA VIADUTO - ESTRADA RAUL VEIGA, 243 - ALCÂNTARA - SÃO GONÇALO-RJ - AGOSTO 2023',
    period: moment().startOf('month').format('YYYY-MM-DD'),
  },
]
