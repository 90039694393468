import {Switch} from "antd";
import React from "react";
import {api} from "../../services/api";

export const ColumnActiveRole = ({checked, id, loadData}: { checked: boolean, id: number, loadData:any }) => {
    const [loading, setLoading] = React.useState(false)
    const onChange = (val: boolean) => {
        setLoading(true)
        api.put('/roles/' + id, {active: val}).then(() => {
            loadData({})
        }).finally(() => {
            setLoading(false)
        })
    }
    return (
        <Switch checked={checked} onChange={onChange} loading={loading}/>
    )
}