import React, { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetOneApi } from 'rk-react-component'
import { PageFormContainer } from '../../../containers/pages/page-form-container/page-form-container'
import { FormDriver } from '../../../containers/forms/form-driver/form-driver'
import { api } from '../../../services/api'
import { MeContext } from '../../../contexts/me-context/me-context'

export const DriverFormPage = () => {
  const navigate = useNavigate()
  const { role } = useContext(MeContext)
  const { id } = useParams()
  const { data, loading, setData } = useGetOneApi<any>({ url: '/drivers', id, api })

  return (
    <PageFormContainer
      title={'Motorista'}
      FormComponent={() => (
        <FormDriver
          fixParams={{
            supplier_id: role?.allow_model_id,
          }}
          initialValues={data}
          onFinish={(dataApi) => {
            setData(dataApi)
            navigate(`/drivers`)
          }}
        />
      )}
      loading={loading}
    />
  )
}
