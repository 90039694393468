import moment from "moment/moment";
import {Moment} from "moment";

export const formatNumber = (
    number = 0,
    {decimal = 2, style = 'decimal', locale = 'pt-BR', currency = 'BRL'}: {
        decimal?: number,
        style?: string,
        locale?: string,
        currency?: string
    }
) => {
    return new Intl.NumberFormat(locale, {
        style: style,
        currency: currency,
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal
    }).format(number)
}

export const formatSecondToTimer = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}


export const formatDate = (date: string | Date | Moment, format = 'DD/MM/YYYY HH:mm', relativeDate = false, relativeSuffix = false) => {
    if (!date) return null

    const dateAux = moment(date)

    if (relativeDate) {
        return dateAux.fromNow(relativeSuffix)
    }
    return dateAux.format(format)
}

export const extractNameInitials = (fullName: string, firstAndLast = true): string => {
    if (!fullName) return '';

    const words = fullName.split(' ');

    const initials = [];

    for (let i = 0; i < words.length; i++) {
        const word = words[i];
        if (word.length > 0) {
            initials.push(word[0].toUpperCase());
        }
    }

    if (firstAndLast && words.length > 1) {
        return initials[0] + initials[initials.length - 1]
    }

    return initials.join('');
}

export const extractNamesFromUser = (fullName?: string): string => {
    if (!fullName) return '';

    const words = fullName.split(' ');

    if (words.length > 1) {
        return words[0] + ' ' + words[words.length - 1]
    }

    return words.join('');
}