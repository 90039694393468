import {Card, Divider, TabsProps} from 'antd'
import React, { useMemo } from 'react'
import { PageHeader } from '../../../components/page-header/page-header'
import { useNavigate } from 'react-router-dom'
import { TabComponent } from '../../../components/tab-component/tab-component'

interface ItemTabProps {
  label: string
  key: string
  FormComponent: any
  disabled?: boolean
  loading?: boolean
  title: string
}

interface PageFormContainerProps {
  items: ItemTabProps[]
  title: string
  tabProps?: TabsProps
}

export const PageFormTabContainer = ({ items, title, tabProps }: PageFormContainerProps) => {
  const navigate = useNavigate()
  const itemsTab = useMemo(() => {
    return items.map((i) => ({
      label: i.label,
      key: i.key,
      disabled: i.disabled,
      children: (
        <Card loading={i.loading} size={'small'}>
          {i.loading ? null : i.FormComponent}
        </Card>
      ),
    }))
  }, [items])
  return (
    <>
      <PageHeader title={title} onBack={() => navigate(-1)} />
      <Divider />
      <TabComponent tabPosition={'left'} items={itemsTab} {...tabProps}/>
    </>
  )
}
