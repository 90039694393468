import React from 'react'
import './style.css'
import { TableContainer, TableContainerProps } from '../table-container/table-container'
import moment from 'moment'
import { Tag, Typography } from 'antd'

export const TableImport = ({ dataSource, loading, loadData, pagination }: TableContainerProps<any, any>) => {
  return (
    <TableContainer
      loadData={loadData}
      pagination={pagination}
      id={'table_import'}
      loading={loading}
      columns={[
        { dataIndex: 'filename', key: 'filename', title: 'Nome' },
        {
          dataIndex: 'status',
          key: 'status',
          title: 'Status',
          render: (val) => {
            if (val === 'FINISHED') {
              return <Tag color={'green'}>Finalizado</Tag>
            }
            if (val === 'FAILED') {
              return <Tag color={'red'}>Erro</Tag>
            }
            if (val === 'NOT_STARTED') {
              return <Tag color={'gray'}>Não Iniciado</Tag>
            }
            return '-'
          },
        },
        {
          dataIndex: 'period',
          key: 'period',
          title: 'Periodo',
          render: (_val, record) => moment(record?.date).format('MM/YYYY'),
        },
        {
          dataIndex: 'created_by',
          key: 'created_by',
          title: 'Criado por',
          render: (val) => (
            <Typography.Paragraph strong style={{ minWidth: 150, marginBottom: 0 }}>
              {val?.name}
            </Typography.Paragraph>
          ),
        },
        {
          dataIndex: 'created_at',
          key: 'created_at',
          title: 'Data de Criação',
          render: (val) => moment(val).format('DD/MM/YYYY HH:mm'),
        },
      ]}
      dataSource={dataSource}
    />
  )
}
