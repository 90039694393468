import moment from 'moment'
import { ExecutionModel } from '../../@types/execution'

export const mockDataExecution: ExecutionModel[] = [
  {
    id: 1,
    date: moment().startOf('month').add(3, 'day').format('YYYY-MM-DD'),
    date_manager_signature: null,
    driver_id: 1,
    expected_hours: 4,
    final_route_media_id: 1,
    manager_signature: null,
    order_id: 1,
    reject_reason: null,
    status: 'PENDENTE',
  },
  {
    id: 2,
    date: moment().startOf('month').add(10, 'day').format('YYYY-MM-DD'),
    date_manager_signature: null,
    driver_id: 1,
    expected_hours: 8,
    final_route_media_id: 1,
    manager_signature: null,
    order_id: 1,
    reject_reason: null,
    status: 'PENDENTE',
  },
]
