import React, { ReactNode, useEffect } from 'react'
import { useGetAllApi } from 'rk-react-component'
import { PageIndexContainer } from '../../../containers/pages/page-index-container/page-index-container'
import { api } from '../../../services/api'
import { TableRoleUser } from '../../../containers/tables/table-role-user/table-role-user'
import { FormFilterRole } from '../../../containers/form-filter/form-filter-role/form-filter-role'

export const MasterIndexPage = (): ReactNode => {
  const { dataList, loading, fetchDataList, pagination, remove } = useGetAllApi<any, any>({
    url: '/roles',
    fixParams: { role: 'MASTER' },
    api,
    initialRequest: false,
  })
  useEffect(() => {
    fetchDataList({})
  }, [])
  return (
    <PageIndexContainer
      title={'Administradores'}
      btnTitle={'Novo Administrador'}
      url={'/masters'}
      FormFilterComponent={<FormFilterRole onFinish={(data) => fetchDataList(data)} />}
      TableComponent={() => (
        <TableRoleUser
          dataSource={dataList}
          loading={loading}
          pagination={pagination}
          loadData={fetchDataList}
          onDelete={remove}
        />
      )}
    />
  )
}
