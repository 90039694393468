import React, { useCallback } from 'react'
import './style.css'
import { TableContainer, TableContainerProps } from '../table-container/table-container'
import moment from 'moment'
import { Button, Tag } from 'antd'
import { DatePickerAction, formatDate, InputAudioAction, InputNumberAction } from 'rk-react-component'
import { SupplierSelectDriver } from '../../../components/supplier-select-driver/supplier-select-driver'
import { MasterSelectSupplier } from '../../../components/master-select-supplier/master-select-supplier'
import { FaEye, FaPlayCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { api } from '../../../services/api'

export const TableMasterExecution = ({
  dataSource,
  loading,
  loadData,
  pagination,
  setDataList,
  filter,
  onDelete,
}: TableContainerProps<any, any>) => {
  const navigate = useNavigate()
  const updateRecord = useCallback((data) => {
    setDataList((old) => old.map((i) => (i.id === data.id ? data : i)))
  }, [])

  return (
    <TableContainer
      onDelete={onDelete}
      filter={filter}
      loadData={loadData}
      pagination={pagination}
      id={'table_master_execution'}
      loading={loading}
      columns={[
        {
          dataIndex: 'region',
          key: 'region',
          title: 'HUB',
          render: (_val, record) => {
            return <div style={{ minWidth: 150 }}>{record?.insertion?.customer?.region?.name || '-'}</div>
          },
        },
        {
          dataIndex: 'customer',
          key: 'customer',
          title: 'Loja',
          render: (_val, record) => {
            return <div style={{ minWidth: 150 }}>{record?.insertion?.customer?.name || '-'}</div>
          },
        },
        {
          dataIndex: 'customer_code',
          key: 'customer_code',
          title: 'Cód.',
          render: (_val, record) => {
            return <div style={{ minWidth: 150 }}>{record?.insertion?.customer?.code || '-'}</div>
          },
        },
        {
          dataIndex: 'supplier',
          key: 'supplier',
          title: 'Fornecedor',
          render: (_val, record) => {
            return (
              <MasterSelectSupplier supplier={record?.supplier} executionId={record?.id} updateRecord={updateRecord} />
            )
          },
        },
        {
          dataIndex: 'driver',
          key: 'driver',
          title: 'Motorista',
          render: (_val, record) => {
            return (
              <SupplierSelectDriver
                fixParams={{ supplier_id: record?.supplier_id }}
                driver={record?.driver}
                executionId={record?.id}
                updateRecord={updateRecord}
                disabled={!record.supplier_id}
              />
            )
          },
        },
        {
          dataIndex: 'period',
          key: 'period',
          title: 'Período',
          render: (_val, record) => {
            return record?.insertion?.period ? formatDate(record?.insertion?.period, 'MMMM/YYYY') : '-'
          },
        },
        {
          dataIndex: 'status',
          key: 'status',
          title: 'Status',
          render: (val) => {
            if (val === 'FINISHED') {
              return <Tag color={'green'}>Finalizado</Tag>
            }
            if (val === 'SCHEDULED') {
              return <Tag color={'yellow'}>Agendado</Tag>
            }
            if (val === 'ONGOING') {
              return <Tag color={'blue'}>Em Andamento</Tag>
            }
            return '-'
          },
        },
        {
          dataIndex: 'expected_hours',
          key: 'expected_hours',
          title: 'Horas Previstas',
          render: (val, record) => (
            <InputNumberAction
              labelSuffix={'/h'}
              minWidth={60}
              style={{}}
              id={record?.id}
              url={'/executions'}
              api={api}
              fieldName={'expected_hours'}
              showLabel={true}
              value={val}
              onFinish={updateRecord}
            />
          ),
          // render: (val) => (val || '-') + ' / h',
        },
        {
          dataIndex: 'date',
          key: 'date',
          title: 'Data',
          render: (val, record) => (
            <DatePickerAction
              minWidth={100}
              style={{}}
              id={record?.id}
              url={'/executions'}
              api={api}
              format={'DD/MM/YYYY'}
              fieldName={'date'}
              showLabel={true}
              value={val ? moment(val) : null}
              onFinish={updateRecord}
            />
          ),
        },
        {
          dataIndex: 'media',
          key: 'media',
          title: 'Mídia',
          render: (val, record) => (
            <InputAudioAction
              style={{}}
              IconPlay={FaPlayCircle}
              fixParams={{ model_type: 'ExecutionModel', model_id: record?.id }}
              url={`${process.env.REACT_APP_API_URL}/api/medias`}
              value={record?.media?.path || null}
              minWidth={100}
              onFinish={() => {
                loadData(undefined)
              }}
            />
          ),
        },
        {
          dataIndex: 'actions',
          key: 'actions',
          title: '',
          render: (_val, record) => {
            return (
              <Button
                disabled={record.status !== 'FINISHED' && record.status !== 'ONGOING' && record.status !== 'SCHEDULED'}
                type={'text'}
                onClick={() => {
                  navigate('/executions/show/' + record.id)
                }}
              >
                <FaEye />
              </Button>
            )
          },
        },
      ]}
      dataSource={dataSource}
    />
  )
}
