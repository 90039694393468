import { Typography } from 'antd'
import React from 'react'
import { TableContainer, TableContainerProps } from '../table-container/table-container'
import { columnDiv } from 'rk-react-component'
import { columnBoolean } from '../../../utils/columns-base'

export const TableUserDevices = ({
  dataSource,
  loading,
  loadData,
  pagination,
  onEdit,
  onDelete,
}: TableContainerProps<any, any>) => {
  return (
    <TableContainer
      loadData={loadData}
      onEdit={onEdit}
      onDelete={onDelete}
      pagination={pagination}
      id={'table_user_device'}
      loading={loading}
      columns={[
        columnDiv({ title: 'Versão do App', field: 'app_version', minWidth: 120 }),
        columnBoolean({ title: 'Localização (App Aberto)', field: 'location_foreground' }),
        columnBoolean({ title: 'Localização (App Fechado)', field: 'location_background' }),
        columnDiv({ title: 'Token (PUSH)', field: 'token', minWidth: 400 }),
        columnDiv({ title: 'Provider (PUSH)', field: 'provider', minWidth: 120 }),
        columnDiv({ title: 'Aparelho', field: 'device', minWidth: 120 }),
        columnDiv({ title: 'Marca', field: 'brand', minWidth: 120 }),
        columnDiv({ title: 'Modelo', field: 'model', minWidth: 120 }),
        columnDiv({ title: 'Sistema Operacional', field: 'os', minWidth: 120 }),
        columnDiv({ title: 'Versão do Sistema Operacional', field: 'system_version', minWidth: 120 }),
        columnDiv({ title: 'ID Único', field: 'unique_id', minWidth: 400 }),
        {
          key: 'first_install_time',
          dataIndex: 'first_install_time',
          title: 'Primeira Instalação',
          render: (val) => (
            <div style={{ minWidth: 200 }}>
              <Typography.Text>{val ? new Date(val).toLocaleString() : '-'}</Typography.Text>
            </div>
          ),
        },
        {
          key: 'updated_at',
          dataIndex: 'updated_at',
          title: 'Atualizado em',
          render: (val) => (
            <div style={{ minWidth: 200 }}>
              <Typography.Text>{new Date(val).toLocaleString()}</Typography.Text>
            </div>
          ),
        },
      ]}
      dataSource={dataSource}
    />
  )
}
