import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetOneApi } from 'rk-react-component'
import { api } from '../../services/api'
import { PageFormTabContainer } from '../../containers/pages/page-form-tab-container/page-form-tab-container'
import { FormUser } from '../../containers/forms/form-user/form-user'

export const MyProfileFormPage = () => {
  const { id } = useParams()
  const { data: dataUser, loading: loadingUserGetOne } = useGetOneApi<any>({
    url: '/users',
    id,
    api,
  })

  return (
    <PageFormTabContainer
      title={'Meu Perfil'}
      items={[
        {
          disabled: false,
          key: 'tab_user',
          label: 'Info Geral',
          loading: loadingUserGetOne,
          title: 'Meu Perfil',
          FormComponent: <FormUser initialValues={dataUser} onFinish={() => {}} />,
        },
        // {
        //     disabled: !dataUser?.id,
        //     key: 'tab_address',
        //     label: 'Endereço',
        //     loading: loadingAddressGetOne,
        //     title: 'Novo Endereço',
        //     FormComponent: (
        //         <FormAddress
        //             initialValues={dataAddress}
        //             onFinish={() => {
        //             }}
        //             modelType={'UsersModel'}
        //             modelId={dataUser?.id}
        //         />
        //     ),
        // },
        // {
        //     disabled: !dataUser?.id,
        //     key: 'tab_password',
        //     label: 'Senha',
        //     loading: loadingAddressGetOne,
        //     title: 'Trocar Senha',
        //     FormComponent: (
        //         <FormPassword
        //             onFinish={() => {
        //
        //             }}
        //             userId={dataUser?.id}
        //         />
        //     ),
        // }
      ]}
    />
  )
}
