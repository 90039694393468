import { Button, Divider, Form, Space, Tooltip } from 'antd'
import React from 'react'
import { FaCogs } from 'react-icons/fa'
import {CompanyIcon, HeadquarterIcon, RegionIcon, SupplierIcon} from "../../main/icon-modules";

export const SignInFastLogin = () => {
  const form = Form.useFormInstance()
  return (
    <Space
      size={0}
      split={<Divider type={'vertical'} />}
      style={{ marginBottom: 24, width: '100%', justifyContent: 'center' }}
    >
      <Tooltip title={'Master'}>
        <Button
          type={'link'}
          icon={<FaCogs />}
          onClick={() => {
            form.setFieldsValue({
              email: 'master@rockapps.com.br',
              password: 'rockapps',
            })
          }}
        />
      </Tooltip>
      <Tooltip title={'Gerente Loja'}>
        <Button
          type={'link'}
          icon={<CompanyIcon />}
          onClick={() => {
            form.setFieldsValue({
              email: 'manager_loja@rockapps.com',
              password: 'rockapps',
            })
          }}
        />
      </Tooltip>
      <Tooltip title={'Gerente Fornecedor'}>
        <Button
          type={'link'}
          icon={<SupplierIcon />}
          onClick={() => {
            form.setFieldsValue({
              email: 'supplier@rockapps.com',
              password: 'rockapps',
            })
          }}
        />
      </Tooltip>
      <Tooltip title={'Gerente Regional'}>
        <Button
          type={'link'}
          icon={<RegionIcon />}
          onClick={() => {
            form.setFieldsValue({
              email: 'gerente_regional@rockapps.com',
              password: 'rockapps',
            })
          }}
        />
      </Tooltip>
      <Tooltip title={'Gerente Geral'}>
        <Button
          type={'link'}
          icon={<HeadquarterIcon />}
          onClick={() => {
            form.setFieldsValue({
              email: 'coordenador_sede@rockapps.com',
              password: 'rockapps',
            })
          }}
        />
      </Tooltip>
    </Space>
  )
}
