import { Avatar, Typography } from 'antd'
import React from 'react'
import { TableContainer, TableContainerProps } from '../table-container/table-container'
import { formatAvatarSlug, formatBrazilianPhoneNumber } from 'rk-react-component'
import { ColumnActiveRole } from '../../../components/column-active-role/column-active-role'

export const TableRoleUser = ({
  dataSource,
  loading,
  loadData,
  pagination,
  withoutCount,
  onDelete,
}: TableContainerProps<any, any> & { withoutCount?: boolean }) => {
  return (
    <TableContainer
      onDelete={onDelete}
      pagination={pagination}
      loadData={loadData}
      key={'id'}
      id={'table_role_user'}
      loading={loading}
      columns={[
        {
          dataIndex: 'avatar',
          key: 'avatar',
          title: 'Foto',
          render: (_val, record) => (
            <Avatar src={record?.user?.avatar?.path} style={{ border: '.5px solid black' }}>
              {formatAvatarSlug(record?.user?.name)}
            </Avatar>
          ),
        },
        {
          fixed: true,
          key: 'user.name',
          dataIndex: ['user', 'name'],
          title: 'Nome',
          width: '120px',
          render: (val, record) => <Typography.Text strong>{record?.user?.name}</Typography.Text>,
          ellipsis: { showTitle: true },
        },
        {
          key: 'user.email',
          dataIndex: ['user', 'email'],
          title: 'Email',
          width: '120px',
          render: (val, record) => <Typography.Text>{record?.user?.email}</Typography.Text>,
          ellipsis: { showTitle: true },
        },
        {
          key: 'user.mobile',
          dataIndex: ['user', 'mobile'],
          title: 'Celular',
          render: (val, record) => {
            return <Typography.Text>{formatBrazilianPhoneNumber(record?.user?.mobile)}</Typography.Text>
          },
        },
        {
          key: 'active',
          dataIndex: 'active',
          title: 'Ativo?',
          // render: (val, record) => val ? <Tag color={'green'}>Sim</Tag> : <Tag color={'gray'}>Não</Tag>,
          render: (val, record) => <ColumnActiveRole checked={val} id={record?.id} loadData={loadData} />,
        },
        ...(withoutCount
          ? [
              {
                key: 'voters_count',
                dataIndex: 'voters_count',
                title: 'Cadastros',
              },
            ]
          : []),
      ]}
      dataSource={dataSource}
    />
  )
}
