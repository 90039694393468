import React from 'react'
import './style.css'
import { TableContainer, TableContainerProps } from '../table-container/table-container'
import { useNavigate } from 'react-router-dom'

export const TableSupplier = ({ dataSource, loading, loadData, pagination }: TableContainerProps<any, any>) => {
  const navigate = useNavigate()
  return (
    <TableContainer
      loadData={loadData}
      pagination={pagination}
      id={'table_supplier'}
      loading={loading}
      columns={[{ dataIndex: 'name', key: 'name', title: 'Nome' }]}
      onEdit={(record) => navigate(`/suppliers/${record?.id}`)}
      dataSource={dataSource}
    />
  )
}
