import React, { useContext, useEffect } from 'react'
import { useGetAllApi } from 'rk-react-component'
import { TableCompany } from '../../../containers/tables/table-company/table-company'
import { PageIndexContainer } from '../../../containers/pages/page-index-container/page-index-container'
import { FormFilterCompany } from '../../../containers/form-filter/form-filter-company/form-filter-company'
import { api } from '../../../services/api'
import { MeContext } from '../../../contexts/me-context/me-context'

export const CompanyIndexPage = () => {
  const { role } = useContext(MeContext)
  const { dataList, fetchDataList, loading, pagination, filter } = useGetAllApi<any, any>({
    url: '/companies',
    api,
    filterKey: 'companyIndexPage',
    initialRequest: false,
  })

  useEffect(() => {
    fetchDataList({ per_page: 10, page: 1, ...filter })
  }, [])

  return (
    <PageIndexContainer
      url={'/companies'}
      title={'Empresas'}
      btnTitle={role?.role === 'MASTER' ? 'Nova Empresa' : null}
      FormFilterComponent={<FormFilterCompany onFinish={fetchDataList} initialValues={filter} />}
      TableComponent={() => (
        <TableCompany
          dataSource={dataList}
          loading={loading}
          pagination={pagination}
          loadData={(params) => fetchDataList({ ...filter, ...params })}
        />
      )}
    />
  )
}
