import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetOneApi } from 'rk-react-component'
import { api } from '../../../services/api'
import { PageFormTabContainer } from '../../../containers/pages/page-form-tab-container/page-form-tab-container'
import { FormImport } from '../../../containers/forms/form-import/form-import'

export const ImportFormPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data, loading } = useGetOneApi<any>({
    url: '/suppliers',
    id,
    api,
  })
  return (
    <PageFormTabContainer
      title={'Fornecedor'}
      items={[
        {
          FormComponent: <FormImport initialValues={data} onFinish={(dataApi) => navigate(`/imports`)} />,
          disabled: false,
          key: '1',
          label: 'Informações',
          loading,
          title: 'Informações',
        },
      ]}
    />
  )
}
