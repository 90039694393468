import React, { useEffect } from 'react'
import { useGetAllApi } from 'rk-react-component'
import { PageIndexContainer } from '../../../containers/pages/page-index-container/page-index-container'
import { api } from '../../../services/api'
import { TableSupplierExecution } from '../../../containers/tables/table-supplier-execution/table-supplier-execution'
import { FormFilterSupplierExecution } from '../../../containers/form-filter/form-filter-supplier-execution/form-filter-supplier-execution'

export const SupplierExecutionIndexPage = () => {
  const { dataList, fetchDataList, loading, pagination, remove, setDataList } = useGetAllApi<any, any>({
    url: '/executions-list',
    api,
    initialRequest: false,
  })

  useEffect(() => {
    fetchDataList({ per_page: 10, page: 1 })
  }, [])
  return (
    <PageIndexContainer
      title={'Execuções'}
      btnTitle={undefined}
      url={'/executions-list'}
      TableComponent={() => (
        <TableSupplierExecution
          pagination={pagination}
          loadData={fetchDataList}
          dataSource={dataList}
          loading={loading}
          onDelete={remove}
          setDataList={setDataList}
        />
      )}
      FormFilterComponent={
        <FormFilterSupplierExecution
          onFinish={(data) => {
            fetchDataList(data)
          }}
        />
      }
    />
  )
}
